import React, { Component } from "react";
import { Grid, Tab, Icon, Button, Modal, Input, Header } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { AddCategoryModal, AddItemModal, AddActivityModal, AddBundleModal, AddSeasonPassModal }
  from '../../../../shared/components';
import RentalCategoryGrid from "./category-grid";
import RentalItemList from './category-list';
import InventoryBreadcrumb from '../../breadcrumb'
import { connect, bindActionCreators, actions, commonFunctions, } from "../../../../shared/functional/global-import";

class CategoryView extends Component {
   get initialState() {
    return {
      categoryList: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        inStore: this.props.handleShowGrid !== undefined ? 1 : -1,
        StartDate: this.props.handleShowGrid !== undefined ? this.props.startDate : "",
        EndDate: this.props.handleShowGrid !== undefined ? this.props.endDate : "",
        startTime: this.props.handleShowGrid !== undefined ? this.props.startTime : null,
        endTime: this.props.handleShowGrid !== undefined ? this.props.endTime : null,
        retailTypeId: "",
        pageNo: 1,
        pageSize: 100,
        searchValue: "",
        isActive: true,
        sortOrder: "desc",
        sortColumn: "title",
        sortingArrow: "sort",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        IsOrderInventory: this.props.handleShowGrid !== undefined ? true : false
      },
      gridDataLoading: false
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState, traitDetails: [], productCategoryDetails: [],
      activateModalopen: false, open: false, parentId: this.props.global.inventoryDetail.categoryId,
      businessCategoryType: this.props.global.inventoryDetail.businessCategoryId, updateButton: false,
      isCommonDeleteUpdateModalOpen: false, hasMore: true, searchData: false, productId: "", breadcrumbData: [],
      dataSearch: "", globalSearch: this.props.location.state
    };
  }
  setStateOfOpenModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };
  AddCategoryModal = (addEdit, type, inStore) => {
    this.setState({
      addcateStatus: !this.state.addcateStatus,
      openModal: false,
      addOrEdit: addEdit,
      categoryOrSub: type,
      addNewCategorySub: true,
      inStore: inStore
    });
  };

  AddItemModal = (inventoryType) => {
    this.setState({
      itemStatus: !this.state.itemStatus,
      openModal: false,
      editItemModal: true,
      inventoryType: inventoryType
    });
  };

  AddActivityModal = (inventoryType) => {
    this.setState({
      activityStatus: !this.state.activityStatus,
      openModal: false,
      inventoryType: inventoryType,
    });
  };

  AddBundleModal = (inventoryType) => {
    this.setState({
      bundleStatus: !this.state.bundleStatus,
      openModal: false,
      inventoryType: inventoryType
    });
  };

  SeasonPassAddModal = (inventoryType) => {

    this.setState({
      seasonPassStatus: !this.state.seasonPassStatus,
      openModal: false,
      inventoryType: inventoryType
    });
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {
    const dataSearch = value
    var categoryList = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.categoryList);
    this.setState({ categoryList, dataSearch });

    if (value === "") {
      this.setState({ categoryList: { ...this.state.categoryList, searchValue: "" }, searchData: false, breadcrumbData: [] }, () => this.callCategoryDetail())
    }
  };

  onHandleCloseSearch = () => {
    this.setState({
      categoryList: { ...this.state.categoryList, searchValue: "", },
      searchData: false, breadcrumbData: []
    }, () => this.callCategoryDetail())
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.getProductCategoryDetail()}
  }
  getProductCategoryDetail = (click) => {

    if (click === true) {
      this.setState({ categoryList: { ...this.state.categoryList, searchValue: "", pageNo: 1 }, searchData: false }, () => this.callCategoryDetail())
    }
    else {
      this.callCategoryDetail()
    }
  };

  getProductCategoryDetailBySearchIcon = () => {
    this.setState({ categoryList: { ...this.state.categoryList, pageNo: 1 }, searchData: false }, () => this.callCategoryDetail())
  };

  callCategoryDetail = () => {
    const globalSearch = this.state.globalSearch
    this.setState({
      gridDataLoading: true, categoryList: {
        ...this.state.categoryList, parentId: globalSearch ?
          globalSearch.categoryId : this.props.global.inventoryDetail.categoryId,
        businessCategoryType: globalSearch ? globalSearch.businessCategoryId : this.props.global.inventoryDetail.businessCategoryId,
      }
    }, () => (this.props.actions.apiCall({
      urls: ["GETCATEGORYANDPRODUCT"], method: "GET",
       data: this.state.categoryList,
      onSuccess: (response) => {
 
        if (response.length === 0) {
          this.setState({ hasMore: false, responseStored: true });
          let responseData = this.state.categoryList.pageNo === 1 ? response :
           this.state.productCategoryDetails.concat(response);
          this.setState({ productCategoryDetails: responseData });
          this.resetGlobal()
        }
        else {
          let responseData = this.state.categoryList.pageNo === 1 ? response : this.state.productCategoryDetails.concat(response);
          this.setState({ productCategoryDetails: responseData });
          this.resetGlobal()
        }
      },
      onFinally: () => { this.setState({ gridDataLoading: false }) },
    })))
  };

  getBreadcrumb = () => {
    const globalSearch = this.state.globalSearch
    const searchData = true
    const productId = this.props.global.inventoryDetail.productId
    this.props.actions.apiCall({
      urls: ["GETBREADCRUMBS"], method: "GET", data: {
        "businessid": this.props.global.bussinessDetail.businessId,
        "businessLocationId": this.props.global.locationSelected.BusinessLocationId,
        "ProductId": globalSearch ? globalSearch.productId : this.props.global.inventoryDetail.productId,
        "BusinessCategoryType": globalSearch ? globalSearch.businessCategoryId : this.props.global.inventoryDetail.businessCategoryId,
        "CategoryId": globalSearch ? globalSearch.categoryId : this.props.global.inventoryDetail.categoryId
      },
      onSuccess: (response) => {
        this.setState({ breadcrumbData: response, searchData, productId });
      }
    })
  };

  onHandleSorting = (heading) => {
    let sortOrder = this.state.categoryList.sortOrder === "asc" ? "desc" : "asc";
    let sortArrow = this.state.categoryList.sortingArrow === "sort up" ? "sort down" : "sort up";
    this.setState({ categoryList: { ...this.state.categoryList, sortColumn: heading, sortOrder: sortOrder, sortingArrow: sortArrow, pageNo: 1, pageSize: 12 } }, () => { this.getProductCategoryDetail() });
  }

  fetchMoreData = () => {

    const countPageNo = this.state.categoryList.pageNo + 1;
    // 20 more records in 1.5 secs  
    setTimeout(() => {
      this.setState({
        categoryList: { ...this.state.categoryList, pageNo: countPageNo }
      }, () => { this.getProductCategoryDetail() });
    }, 500);
  };

  componentDidMount() {
    this.getProductCategoryDetail(0);
    this.getBreadcrumb();
  }

  openCategory = (a) => {

    this.setState({ open: true, categoryId: a.categoryId, typeCategory: a.type, name: a.name, productId: a.productId })
  }
  closeCategory = () => {
    this.setState({ open: false })
  }

  deleteCategoryAndProduct = (typeCategory, categoryId, productId, stateRef) => {
             
    if (typeCategory === "Category" || typeCategory === "Subcategory") {
      this.props.actions.apiCall({
        urls: ["DELETECATEGORY"], method: "DELETE", data: {
          categoryId: categoryId,
          ActionPerformedBy: this.props.auth.userDetail.emailId
        }, onSuccess: (response) => {
          stateRef.setState({ open: false }, () => this.getProductCategoryDetail())
        }, showNotification: true
      });
    }
    else {
      this.props.actions.apiCall({
        urls: ["DELETEPRODUCTBYBUSINESSTYPE"], method: "DELETE",
        data: {
          Id: productId, BusinessCategoryType: this.props.global.inventoryDetail.businessCategoryId,
          ActionPerformedBy: this.props.auth.userDetail.emailId
        }, onSuccess: (response) => {
          stateRef.setState({ open: false }, () => this.getProductCategoryDetail())
        }, showNotification: true
      });
    }
  }
  onUpdateBusinessByTypeToggle = (productId, categoryId, stateRef) => {
    if (productId) {
      this.props.actions.apiCall({
        urls: ["UPDATEBUSINESSBYTYPETOGGLE"], method: "PUT", data: { id: productId, businessId: this.props.global.bussinessDetail.businessId, BusinessCategoryType: this.props.global.inventoryDetail.businessCategoryId, isActive: stateRef.state.isActive, actionPerformedBy: this.props.auth.userDetail.emailId }, onSuccess: (response) => {
          stateRef.setState({ activateModalopen: false }, () => this.getProductCategoryDetail())
        },
        showNotification: true
      });
    }
    else if (categoryId) {
      this.props.actions.apiCall({
        urls: ["CATEGORYTOGGLE"], method: "PUT", data: { categoryId: categoryId, businessId: this.props.global.bussinessDetail.businessId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, isActive: stateRef.state.isActive, actionPerformedBy: this.props.auth.userDetail.emailId }, onSuccess: (response) => {
          stateRef.setState({ activateModalopen: false }, () => this.getProductCategoryDetail())
        },
        showNotification: true
      });
    }
  };

  resetGlobal = () => {
    this.setState({ globalSearch: undefined })
  }

  render() {
    const { addcateStatus, itemStatus, addNewCategorySub, inStore, responseStored, productCategoryDetails, activityStatus, categoryId, typeCategory,
      name, productId, categoryOrSub, addOrEdit, categoryList, searchData, breadcrumbData, dataSearch } = this.state;
    const { orderInventoryStateRef, handleShowGrid } = this.props
    const panes = [
      {
        menuItem: { key: "users grid", icon: "grid layout" },
        render: () => (
          <Tab.Pane>
            <RentalCategoryGrid
              orderInventoryStateRef={orderInventoryStateRef}
              searchData={searchData}
              breadcrumbData={breadcrumbData}
              getBreadcrumb={this.getBreadcrumb}
              responseStored={responseStored}
              fetchMoreData={this.fetchMoreData}
              hasMore={this.state.hasMore}
              gridDataLoading={this.state.gridDataLoading}
              productCategoryDetails={productCategoryDetails}
              getProductCategoryDetail={this.getProductCategoryDetail}
              openCategory={this.openCategory}
              closeCategory={this.closeCategory}
              categoryId={categoryId}
              name={name}
              resetGlobal={this.resetGlobal}
              getTraitProduct={this.getTraitProduct}
              traitDetails={this.state.traitDetails}
              productId={productId}
              handleShowGrid={this.props.handleShowGrid}
              onEditIconClick={this.onEditIconClick}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: "users list", icon: "list ul" },
        render: () => (
          <Tab.Pane>
            <RentalItemList
              searchData={searchData}
              breadcrumbData={breadcrumbData}
              getTraitProduct={this.getTraitProduct}
              getBreadcrumb={this.getBreadcrumb}
              handleShowGrid={this.props.handleShowGrid}
              deleteCategoryAndProduct={this.deleteCategoryAndProduct}
              productId={productId}
              openCategory={this.openCategory}
              activateModal={this.activateModal}
              onUpdateBusinessByTypeToggle={this.onUpdateBusinessByTypeToggle}
              getProductCategoryDetail={this.getProductCategoryDetail}
              productCategoryDetails={productCategoryDetails}
              categoryList={categoryList}
              businessCategoryId={this.props.global.inventoryDetail.businessCategoryId}
              businessCategoryName={businessCategoryName}
              onHandleSorting={this.onHandleSorting}
              gridDataLoading={this.state.gridDataLoading}
              hasMore={this.state.hasMore}
              fetchMoreData={this.fetchMoreData}

            />
          </Tab.Pane>
        ),
      },
    ];
    const businessCategoryName = this.props.global.inventoryDetail.businessCategoryName

    const rentalGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental"))?.globalCodeId

    const retailGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Retail"))?.globalCodeId

    const activityGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Activity"))?.globalCodeId

    const bundleGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle"))?.globalCodeId

    const seasonPassGlobalId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Season Pass"))?.globalCodeId

    const passes = businessCategoryName === undefined ? "" : businessCategoryName === "Season Pass" ? "Passes" : businessCategoryName;
      
    return (
      <>
        <Grid>
          <InventoryBreadcrumb
            getProductCategoryDetail={this.getProductCategoryDetail} onBytypeClick={this.props.onBytypeClick}
             globalSearch={this.state.globalSearch} resetGlobal={this.resetGlobal} />
          <Grid.Column width={"16"} className="add-icon-section">
            <div className="common-search-bar inventory-search-bar" >
              <Input fluid icon="search" iconPosition="left" value={categoryList.searchValue}
               data="searchValue" placeholder={`Search by Category, Subcategory and ${passes}`}
                onKeyPress={this.handleKeyPress} onChange={this.onHandleChange} />
              {dataSearch.length > 0 ? 
              <Button className="iconClose" icon="close" 
              onClick={this.onHandleCloseSearch}></Button> : false}

              <Button className="iconSearch"
               icon="search" onClick={this.getProductCategoryDetailBySearchIcon} ></Button>
            </div>
            {!handleShowGrid &&
              <Icon name="plus circle" className="orange-color add-icon" size="big"
                onClick={() => this.setStateOfOpenModal()}/>}
            <Modal size="mini" open={this.state.openModal} closeIcon onClose={() => this.setStateOfOpenModal()} >

              <Modal.Content image>
                
                <Modal.Description>
                  <Grid textAlign="center">
                    <Grid.Column width={16}>
                      {this.props.global.inventoryDetail.categoryId === null && 
                      <Button className="orange-btn" 
                      onClick={() => this.AddCategoryModal("Add", "Category")}>
                        <Icon name="plus" /> Category </Button>}
                      {this.props.global.inventoryDetail.categoryId > 0 && <Button className="orange-btn"
                       onClick={() => this.AddCategoryModal("Add", "Sub Category")}><Icon name="plus"/> Subcategory </Button>
                        }
                    </Grid.Column>
                    <Grid.Column width={16}>
                      {this.props.global.inventoryDetail.businessCategoryId === retailGlobalId || this.props.global.inventoryDetail.businessCategoryId === rentalGlobalId ?
                       <Button className="blue-btn" onClick={() =>this.AddItemModal(this.props.global.inventoryDetail.businessCategoryId)}>
                          <Icon name="plus"/> Item </Button> : false}
                      {this.props.global.inventoryDetail.businessCategoryId === activityGlobalId &&
                        <Button className="blue-btn" onClick={() => this.AddActivityModal(this.props.global.inventoryDetail.businessCategoryId)}>
                          <Icon name="plus" /> Activity </Button>}
                      {this.props.global.inventoryDetail.businessCategoryId === bundleGlobalId && <Button className="blue-btn"  onClick={() => this.AddBundleModal(this.props.global.inventoryDetail.businessCategoryId)}><Icon name="plus" /> Bundle </Button>}
                      {this.props.global.inventoryDetail.businessCategoryId === seasonPassGlobalId && <Button className="blue-btn"
                        onClick={() => this.SeasonPassAddModal(this.props.global.inventoryDetail.businessCategoryId)}><Icon name="plus" /> Passes </Button>}
                    </Grid.Column>
                  </Grid>
                </Modal.Description>
              </Modal.Content>
            </Modal>

            <Tab panes={panes} menu={{ secondary: true }} className="common-tabs" />
          </Grid.Column>
        </Grid>

        <Modal
          open={this.state.open}
          closeIcon
          onClose={this.closeCategory}
          size={"mini"}>
          <Modal.Content>
            <Grid>
              <Grid.Column width={16} textAlign="center">
                <Header as="h4" className="orange-color">
                  Are you sure you want to delete this "{name}" {typeCategory === "Season Pass" ? "Passes" : typeCategory}?
                </Header>
                {(typeCategory === "Category") &&
                  <p>
                All the {businessCategoryName} and subcategories under this section will be deleted.
                  </p>
                }
                {(typeCategory === "Subcategory") && <p>All the {businessCategoryName} under this section will be deleted.</p>}
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <Button className="blue-btn" onClick={this.closeCategory}>Cancel</Button>
                <Button className="orange-btn" onClick={() => this.deleteCategoryAndProduct(typeCategory, categoryId, productId, this)}>Delete</Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
        <>
          {addNewCategorySub && 
            <AddCategoryModal
              addNewCategorySub={addNewCategorySub}
              getProductCategoryDetail={this.getProductCategoryDetail}
              openModal={addcateStatus}
              allFunction={this}
              closeModal={this.AddCategoryModal}
              addOrEdit={addOrEdit}
              categoryOrSub={categoryOrSub}
              inStore={inStore} />}
          {itemStatus &&
            <AddItemModal
              openModal={itemStatus}
              closeModal={this.AddItemModal}
              getProductCategoryDetail={this.getProductCategoryDetail}
              inventoryType={this.state.inventoryType} />}
          {activityStatus &&
            <AddActivityModal
              openModal={activityStatus}
              inventoryType={this.state.inventoryType}
              closeModal={this.AddActivityModal}
              getProductCategoryDetail={this.getProductCategoryDetail} />}
          {this.state.bundleStatus &&
            <AddBundleModal
              inventoryType={this.state.inventoryType}
              openModal={this.state.bundleStatus}
              closeModal={this.AddBundleModal}
              getProductCategoryDetail={this.getProductCategoryDetail} />}
          {this.state.seasonPassStatus &&
            <AddSeasonPassModal
              inventoryType={this.state.inventoryType}
              openModal={this.state.seasonPassStatus}
              closeModal={this.SeasonPassAddModal}
              getProductCategoryDetail={this.getProductCategoryDetail} />
          }
        </>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeInventoryDetail: bindActionCreators(actions.storeInventoryDetail, dispatch),
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryView));