import React, { Component } from "react";
import {
  Modal,
  Step,
  Icon,
  Grid,
  Confirm,
  Dimmer,
  Loader,
  Image,
} from "semantic-ui-react";
import UploadImages from "./upload-images";
import AddItemType from "./add-item-type";
import AddItemGeneral from "./add-item-general";
import AddItemPricing from "./add-item-pricing";
import UploadRetailImages from "./upload-retail-img";
import AddRetailGeneral from "./add-retail-general";
import AddRetailPricing from "./add-retail-pricing";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
  env,
} from "../../../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../../components";

class AddItemModal extends Component {
  get initialState() {
    return {
      addItemGeneral: {
        // productId:"", need to comment it for  condionally  untypable in case of edit
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Rental"
        ).globalCodeId,
        categoryId: null,
        webStoreType: 46,
        bookingConfirmation: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BookingConfirmation",
          "Auto Confirm"
        ).globalCodeId,
        productName: "",
        productQuantity: "",
        productPrefix: "",
        inConfirmationEmail: true,
        pickUpInstruction: "",
        dropOffInstruction: "",
        whattoKnow: "",
        whatIsIncluded: "",
        itemDescription: "",
        color: "",
        maxDays: null,
        maxHours: null,
        webWidget: true,
        cancellationPolicyDescription: null,
        cancellationPolicyInStore: true,
        cancellationPolicyInConfirmationEmail: true,
        cancellationPolicyWebsiteWidget: true,
        documents: [],
        requirements: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
        addOns: [],
        requirementInStore: true,
        requirementsInConfirmationEmail: true,
        requirementWebsiteWidget: true,
        deliveryOption: false,
        allowCustomerToEnterDeliveryAddress: false,
        productTags: [],
        beforeTime: 1,
        afterTime: 1,
        beforeTimeType: null,
        afterTimeType: null,
        beforeTimeDisable: false,
        afterTimeDisable: false,
        editItemDetail: [],
        inStore: true,
        websiteWidget: true,
        valid: false,
        customField: [],
        traits: [],
        productCustomQuestion: [],
        productBlackOutDates: [],
        productAvailability: [],
        deliveryCustomField: [],
        selectedLocation: [],
        minTimePeriod: null,
        minTimePeriodType: null,
        maxTimePeriod: null,
        maxTimePeriodType: null,
        minItemPerOrder: null,
        maxItemPerOrder: null,
        isEmailBooked: false,
        roleId: null,
        toEmail: [],
      },
      uploadImage: {
        images: [],
        productId: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        imageId: [],
        deleted: [],
        DefaultImage: [],
        isDefaultImage: false,
        DefaultImageId: null,
        responseImages: [],
      },
      addItemPricing: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        productId: "",
        rentalPricingType: 54,
        rentalPricingTitle: "",
        quantity: "",
        duration: "",
        timeFrameId: "",
        itemPrice: "",
        deposit: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        rentalPricingId: "",
        sourceTypeId: 0,
        valid: false,
      },
      addItemType: {
        trackbulk: false,
        rentalretail: true,
        firstStep: false,
      },
      addRetailGeneral: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Retail"
        ).globalCodeId,
        categoryId: null,
        retailType: "",
        bookingConfirmation: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BookingConfirmation",
          "Auto Confirm"
        ).globalCodeId,
        itemName: "",
        itemQuantity: "",
        stockIdPrefix: "",
        itemDescription: "",
        deliveryOption: false,
        allowCustomerToEnterDeliveryAddress: false,
        color: "",
        webWidget: true,
        inConfirmationEmail: true,
        inStore: true,
        cancellationPolicyDescription: null,
        websiteWidget: true,
        cancellationPolicyInStore: true,
        cancellationPolicyInConfirmationEmail: false,
        cancellationPolicyWebsiteWidget: false,
        productTags: [],
        addOns: [],
        customField: [],
        traits: [],
        retailCustomQuestion: [],
        actionPerformedBy: this.props.auth.userDetail.emailId,
        deliveryCustomField: [],
        selectedLocation: [],
        minTimePeriod: null,
        minTimePeriodType: null,
        maxTimePeriod: null,
        maxTimePeriodType: null,
        minItemPerOrder: null,
        maxItemPerOrder: null,
      },
      uploadRetailImage: {
        images: [],
        retailId: "",
        actionPerformedBy: this.props.auth.userDetail.emailId,
        imageId: [],
        deleted: [],
        DefaultImageId: null,
        responseImages: [],
        DefaultImage: [],
        isDefaultImage: false,
      },
      addRetailPrice: {
        retailId: "",
        costPrice: null,
        salePrice: "",
        taxIncludedPrice: true,
        generalTaxes: [],
        taxForOrders: true,
        actionPerformedBy: this.props.auth.userDetail.emailId,
        addRetailpricing: true,
        retailPriceId: null,
        isRetailPriceChange: false,
        isEmailBooked: false,
        roleId: null,
        toEmail: [],
      },
      isRetailImageAdded: false,
      editRetailPrice: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      allCategoryList: [{ value: -1, title: "Add at root level" }],
      allCategoryLists: [{ value: -1, title: "Add at root level" }],
      addcustomStatus: false,
      activeStep: 0,
      type: "",
      documentfield: true,
      trackable: false,
      pricingField: false,
      bulkfied: false,
      // costPrice: false,
      editItemDetail: [],
      generalTaxList: [],
      booking: [],
      applyAll: "",
      timeFrameList: [],
      rentalPriceList: [],
      updateButton: false,
      open: false,
      addOnId: "",
      AddOnTitle: "",
      Price: "",
      Image: [],
      addOnsSelected: "",
      addOnInStore: true,
      addOnWebsiteWidget: true,
      customQuesies: "",
      updatebuttonAddOns: false,
      updatebutton: false,
      createUI: [],
      firstStepCompleted: false,
      thirdStepCompleted: false,
      CreatedProductId: "",
      rentalProductDurationId: "",
      secondStepCompleted: false,
      customerQuestions: "",
      customQuesitonType: "",
      customQuestion: "",
      updateQuestion: false,
      updateQuestions: false,
      questionSelected: "",
      updateRentalGeneral: false,
      isImageEdit: false,
      // updateRentalGeneral: false,
      updateRetailPrice: "",
      addRetailpricing: true,
      blackOutRows: { blackoutDates: [] },
      TaxRow: { TaxNew: [] },
      availability: { availabilityNew: [] },
      customfield: {
        customFieldRows: [{ customField: "", customDescriptions: "" }],
        deliveryCustomField: [{ customField: "", customDescriptions: "" }],
      },
      retailCustomField: [{ customField: "", customDescriptions: "" }],
      customTraits: {
        traits: [
          {
            traitId: null,
            traitName: "",
            traitDescription: "",
            isUpdate: true,
            isDeleted: false,
            disabled: true,
          },
        ],
      },
      productCustomQuestion: [
        {
          customQuestionId: 0,
          required: true,
          questionInStore: true,
          questionWebsiteWidget: true,
          customQuestionOptionId: 210,
          associateAddOn: [
            {
              addOnId: 0,
              price: 0,
            },
          ],
        },
      ],
      timeFrameList: [],
      a: "",
      expandthisnode: [],
      rentalPricingType: "",
      restockTimeValidaion: false,
      isCommonDeleteUpdateModalOpen: false,
      loadingApi: false,
      click: false,
      rentalItemTimeSelect: [],
      rentalItemTimeSelectedValue: [],
      TimeDurationMin: [],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
    this.validatorTraits = commonFunctions.initializeSimpleValidator();
  }

  getCategories = () => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORYFORPRODUCT"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        BusinessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response) {
          const { addItemGeneral } = this.state;
          if (
            addItemGeneral.categoryId === undefined ||
            addItemGeneral.categoryId === null ||
            response[0].productCategories === null
          ) {
            this.setState({
              addItemGeneral: { ...this.state.addItemGeneral, categoryId: -1 },
            });
          }
          if (response[0].productCategories) {
            let productCategoriesArray = JSON.parse(
              response[0].productCategories
            );
            this.setState({
              allCategoryList: [
                ...this.state.allCategoryList,
                ...productCategoriesArray,
              ],
            });
          }
        }
      },
    });
  };
  onDropDownTreeChangeRental = (value) => {
    this.setState({
      addItemGeneral: { ...this.state.addItemGeneral, categoryId: value },
    });
    commonFunctions.onFormChange(this);
  };
  handleDocumentFeilds = (status) => {
    this.setState({ documentfield: status });
  };

  handlepricingField = (status) => {
    this.setState({ pricingField: status });
  };

  handletrackableField = (status) => {
    this.setState({ trackable: status });
  };

  //==== Rental  Pricing ==== //
  getTimeFrame = () => {
    this.props.actions.apiCall({
      urls: ["GETTIMEFRAMEDETAIL"],
      method: "GET",
      data: {
        timeFrameId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const timeFrame = response.map((singleTime) => {
          return {
            value: singleTime.timeFrameId,
            text: singleTime.timeFrameTitle,
            range: singleTime.range,
          };
        });
        this.setState({ timeFrameList: timeFrame });
      },
    });
  };
  getProductDuration = () => {
    this.props.actions.apiCall({
      urls: ["GETPRODUCTPriceDURATION"],
      method: "GET",
      data: { productId: this.state.CreatedProductId },
      onSuccess: (response) => {
        if (response.length > 0) {
          this.setState({
            rentalProductDurationId: response[0].rentalProductDurationId,
          });
        }
      },
    });
  };

  //== renntal == //
  getProductPrice = () => {
    if (this.props.moreSettingPricing !== undefined) {
      this.props.actions.apiCall({
        urls: ["GETRENTALPRODUCTPrice"],
        method: "GET",
        data: {
          productId: -1,
          rentalPricingId: -1,
          businessId: this.props.global.bussinessDetail.businessId,
          businessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          this.setState({ rentalPriceList: response });
        },
      });
    }
    if (this.props.moreSettingPricing === undefined) {
      this.props.actions.apiCall({
        urls: ["GETRENTALPRODUCTPrice"],
        method: "GET",
        data: {
          productId: this.state.addItemPricing.productId,
          rentalPricingId: -1,
          businessId: this.props.global.bussinessDetail.businessId,
          businessLocationId:
            this.props.global.locationSelected.BusinessLocationId,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          this.setState({ rentalPriceList: response });
        },
      });
    }
  };
  /// ========EDIT RENTAL PART ALL GET API=========== ///
  getProductRental = (productId) => {
    this.setState({ loadingApi: true, click: true });
    this.props.actions.apiCall({
      urls: ["GETPRODUCT"],
      method: "GET",
      data: {
        productId: productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response.length > 0) {
          this.setState({
            addItemGeneral: {
              ...this.state.addItemGeneral,
              inStore: response[0].inStore,
              productId: response[0].productId,
              productName: response[0].productName,
              productQuantity: response[0].productQuantity,
              categoryId: response[0].categoryId,
              productPrefix: response[0].productPrefix,
              productTags: JSON.parse(response[0].productTags),
              pickUpInstruction: response[0].pickUpInstruction,
              dropOffInstruction: response[0].dropOffInstruction,
              whattoKnow: response[0].whattoKnow,
              whatIsIncluded: response[0].whatIsIncluded,
              itemDescription: response[0].itemDescription,
              documents: JSON.parse(response[0].documents),
              requirements: JSON.parse(response[0].requirements),
              customField: JSON.parse(response[0].customFields),
              productCustomQuestion: JSON.parse(
                response[0].productCustomQuestion),
              webWidget: response[0].webWidget,
              inConfirmationEmail: response[0].inConfirmationEmail,
              color: response[0].color,
              productBlackOutDates: JSON.parse(
                response[0].productBlackOutDates
              ),
              traits: JSON.parse(response[0].traits),
              productAvailability: JSON.parse(response[0].productAvailability),
              cancellationPolicyDescription:
                response[0].cancellationPolicyDescription,
              cancellationPolicyInStore: response[0].cancellationPolicyInStore,
              cancellationPolicyInConfirmationEmail:
                response[0].cancellationPolicyInConfirmationEmail,
              cancellationPolicyWebsiteWidget:
                response[0].cancellationPolicyWebsiteWidget,
              deliveryOption: response[0].deliveryOption,
              allowCustomerToEnterDeliveryAddress:
                response[0].allowCustomerToEnterDeliveryAddress,
              addOns: JSON.parse(response[0].addOn),
              websiteWidget: response[0].websiteWidget,
              requirementInStore: response[0].requirementInStore,
              requirementsInConfirmationEmail:
                response[0].requirementsInConfirmationEmail,
              beforeTimeDisable: response[0].beforeTimeDisable,
              afterTimeDisable: response[0].afterTimeDisable,
              beforeTime: response[0].beforeTime,
              afterTime: response[0].afterTime,
              beforeTimeType: response[0].beforeTimeType,
              afterTimeType: response[0].afterTimeType,
              requirementWebsiteWidget: response[0].requirementWebsiteWidget,
              maxDays: response[0].maxDays === 0 ? null : response[0].maxDays,
              maxHours:
                response[0].maxHours === 0 ? null : response[0].maxHours,
              bookingConfirmation: response[0].bookingConfirmation,
              deliveryCustomField: JSON.parse(response[0].deliveryCustomFields),
              minTimePeriod: response[0].minTimePeriod,
              minTimePeriodType: response[0].minTimePeriodType,
              maxTimePeriod: response[0].maxTimePeriod,
              maxTimePeriodType: response[0].maxTimePeriodType,
              minItemPerOrder: response[0].minItemPerOrder,
              maxItemPerOrder: response[0].maxItemPerOrder,

            },
          });

          if (response[0].traits !== "[]") {
            this.setState({
              customTraits: {
                ...this.state.customTraits,
                traits: JSON.parse(response[0].traits),
              },
            });
          } else {
            this.setState({
              customTraits: {
                ...this.state.customTraits,
                traits: [
                  {
                    traitId: null,
                    traitName: "",
                    traitDescription: "",
                    isUpdate: true,
                    isDeleted: false,
                    disabled: true,
                  },
                ],
              },
            });
          }
          this.setState({
            customfield: {
              ...this.state.customfield,
              customFieldRows: JSON.parse(response[0].customFields),
            },
          });
          this.setState({
            blackOutRows: {
              ...this.state.blackOutRows,
              blackoutDates: JSON.parse(response[0].blackOutDates),
            },
          });
          this.setState({
            uploadImage: { ...this.state.uploadImage, productId: productId },
          });
          this.setState({
            availability: {
              ...this.state.availability,
              availabilityNew: JSON.parse(response[0].availability),
            },
          });
          this.setState({
            addItemPricing: {
              ...this.state.addItemPricing,
              productId: productId,
            },
            CreatedProductId: productId,
          });
          this.setState({
            rentalPricing: {
              ...this.state.rentalPricing,
              productId: productId,
            },
            CreatedProductId: productId,
          });
          this.state.expandthisnode.push(response[0].categoryId);
          this.setState({
            customfield: {
              ...this.state.customfield,
              deliveryCustomField: JSON.parse(response[0].deliveryCustomFields),
            },
          });
          this.MinDurationChange(response[0].minTimePeriodType);
        }
      },
      onFinally: () => {
        this.setState({ loadingApi: false });
      },
    });
    ///////// -GET RENTAL IMAGE- /////////
    this.props.actions.apiCall({
      urls: ["GETPRODUCTIMAGE"],
      method: "GET",
      data: {
        productId: productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        const { uploadImage } = this.state;
        this.setState((uploadImage.responseImages = response));
        const images = response.map((data) => {
          uploadImage.imageId.push(data.imageId);
          uploadImage.images.push(
            commonFunctions.concatenateImageWithAPIUrl(data.image)
          );
        });
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true);
        if (isDefaultImage) {
          uploadImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
          this.setState(
            (uploadImage.DefaultImageId = isDefaultImage[0].imageId)
          );
        }

        // let filterDefaultImage = response.filter((code) => code.defaultImage === true) ? true : false
        // this.setState({ ...uploadImage, defaultImage: filterDefaultImage })
      },
    });
    ///////////// -GET RENTAL PRODUCT DURATION- ////////////
    this.props.actions.apiCall({
      urls: ["GETPRODUCTPriceDURATION"],
      method: "GET",
      data: { productId: productId },
      onSuccess: (response) => {
        if (response.length > 0) {
          this.setState(() => ({
            rentalPricing: {
              ...this.state.rentalPricing,
              minutePrice: response[0].minutePrice,
              minuteDeposit: response[0].minuteDeposit,
              hourPrice: response[0].hourPrice,
              hourDeposit: response[0].hourDeposit,
              dayPrice: response[0].dayPrice,
              dayDeposit: response[0].dayDeposit,
              weekPrice: response[0].weekPrice,
              weekDeposit: response[0].weekDeposit,
              productId: productId,
              rentalProductDurationId: response[0].rentalProductDurationId,
            },
            rentalPricingType: commonFunctions.getGlobalCodeDetails(
              this.props.global.codes,
              "RentalPricingType",
              "Item with Saved Rate Price"
            ).globalCodeId,
          }));
        }
      },
    });
    // GET RENTAL PRODUCT Price
    this.props.actions.apiCall({
      urls: ["GETRENTALPRODUCTPrice"],
      method: "GET",
      data: {
        productId: productId,
        rentalPricingId: -1,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response.length > 0) {
          this.setState({
            rentalPriceList: response,
            rentalPricingType: response[0].rentalPricingType,
          });
        } else {
          this.setState({ rentalPriceList: response });
        }
      },
    });
  };

  // function to update image in rental images
  getProductImageOnUpdate = (productId) => {
    this.setState((this.state.uploadImage.images = []));
    this.setState((this.state.uploadImage.DefaultImage = []));
    this.props.actions.apiCall({
      urls: ["GETPRODUCTIMAGE"],
      method: "GET",
      data: {
        productId: productId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        this.setState({
          isImageEdit: response && response.length > 0 ? true : false,
        });
        const { uploadImage } = this.state;
        this.setState((uploadImage.responseImages = response));
        const images = response.map((data) => {
          uploadImage.imageId.push(data.imageId);
          uploadImage.images.push(
            commonFunctions.concatenateImageWithAPIUrl(data.image)
          );
        });
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true);
        if (isDefaultImage) {
          uploadImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
          this.setState(
            (uploadImage.DefaultImageId = isDefaultImage[0].imageId),
            (uploadImage.responseImages = response)
          );
        }
        // let filterDefaultImage = response.filter((code) => code.defaultImage === true) ? true : false
        // this.setState({ ...uploadImage, defaultImage: filterDefaultImage })
      },
    });
  };
  componentDidMount() {
    this.getCategory();
    this.getCategories();
    this.getGobalCodeDurationTime();
    this.getProductPrice();
    this.getProductDuration();
    this.getGlobalCodeMin();

    if (this.props.itemType === "Retail-Trackable") {
      this.getRetailGeneralInfo(this.props.retailId);
    }
  }
  //===========Retail Inventory Catergory ============== //
  getCategory = () => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORYFORPRODUCT"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        BusinessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response) {
          const { addRetailGeneral } = this.state;
          if (
            addRetailGeneral.categoryId === undefined ||
            addRetailGeneral.categoryId === null ||
            response[0].productCategories === null
          ) {
            this.setState({
              addRetailGeneral: {
                ...this.state.addRetailGeneral,
                categoryId: -1,
              },
            });
          }
          if (response[0].productCategories) {
            let productCategoriesArray = JSON.parse(
              response[0].productCategories
            );
            this.setState({
              allCategoryLists: [
                ...this.state.allCategoryLists,
                ...productCategoriesArray,
              ],
            });
          }
        }
      },
    });
  };
  onDropDownTreeChange = (value) => {
    this.setState({
      addRetailGeneral: { ...this.state.addRetailGeneral, categoryId: value },
    });
  };
  incrementDecrement = (e, { data, name, value }) => {
    if (name === "minus" && this.state.addItemGeneral[data] > 1) {
      var decrement = value - 1;
      this.setState(() => ({
        addItemGeneral: { ...this.state.addItemGeneral, [data]: decrement },
      }));
    }
    if (name === "plus") {
      var increment = value + 1;
      this.setState(() => ({
        addItemGeneral: { ...this.state.addItemGeneral, [data]: increment },
      }));
    }
  };
  onHandleChangeGeneral = (e, { name, value, type, checked, data }) => {
    var addItemGeneral = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addItemGeneral,
      this
    );
    this.setState({ addItemGeneral });
  };

  onHandleChangeMax = (e, { name, value, type, checked, data }) => {
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    if (ex.test(value) === true) {
      var addItemGeneral = commonFunctions.onHandleChange(
        e,
        { name, value, type, checked, data },
        this.state.addItemGeneral,
        this
      );
      this.setState({ addItemGeneral });
    }
    if (ex.test(value) === false) {
      this.setState({
        addItemGeneral: { ...this.state.addItemGeneral, [data]: "" },
      });
    }
  };

  ///=====This function is used to set Form Submit with POST api====== ///
  postRetailPricing = (e) => {
    if (this.validatorGeneral.allValid() === false) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        addRetailPrice: { ...this.state.addRetailPrice, valid: false },
      });
      return false;
    }
    const { addRetailPrice, activeStep } = this.state;
    if (addRetailPrice["retailId"]) {
      addRetailPrice["retailId"] = addRetailPrice["retailId"];
    } else {
      addRetailPrice["retailId"] = this.props.retailId;
    }
    //if (this.state.addRetailPrice.addRetailpricing){
    const body = this.state.addRetailPrice;
    if (body.costPrice === "") {
      body.costPrice = null;
    } else {
      this.setState({ addRetailPrice: { ...this.state.addRetailPrice } });
    }
    this.props.actions.apiCall({
      urls: ["ADDRETAILPRICE"],
      method: "POST",
      data: body,
      onSuccess: (response) => {
        if (this.state.addRetailPrice.isRetailPriceChange) {
          const { addRetailPrice } = this.state;
          addRetailPrice.isRetailPriceChange = false;
          this.setState({ addRetailPrice });
        }

        if (
          !this.state.addRetailPrice.isRetailPriceChange &&
          activeStep === 3
        ) {
          this.setState({ activeStep: 1 });
        }
        if (response.responseCode == 200) {
          commonFunctions.onApiSucessFormChangedFalse(this);
          if (this.props.getProductCategoryDetail) {
            this.props.getProductCategoryDetail();
          }
          this.setState({ updateRetailGeneral: true });
          e === "continue" && this.props.closeModal();
          this.props.onNext(this.props.next);
        }
      },
      showNotification: true,
    });
  };

  //==== Retail upload image =====//
  changedNext = () => {
    const { uploadRetailImage, isRetailImageAdded } = this.state;
    if (uploadRetailImage.DefaultImage.length > 0) {
      const tempArray = uploadRetailImage.images;
      var remove = tempArray.filter(
        (temp) => temp.data_url !== uploadRetailImage.DefaultImage[0].data_url
      );
      this.setState((uploadRetailImage.images = remove));
    }
    if (
      (!isRetailImageAdded || !this.props.editRetailDetail) &&
      uploadRetailImage.retailId === ""
    ) {
      // if ((!isRetailImageAdded && this.state.uploadRetailImage.images.length <= 10 ||
      //   !this.props.editRetailDetail) && (this.state.uploadRetailImage.images.length <= 10 ||
      //     this.state.uploadRetailImage.images.length <= 10) && uploadRetailImage.retailId === "") {

      var formData = commonFunctions.getFormData(this.state.uploadRetailImage);
      this.props.actions.apiCall({
        urls: ["ADDRETAILIMAGE"],
        method: "POST",
        data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
          }
        },
        isFormData: true,
        showNotification: true,
      });
    }
    if (isRetailImageAdded) {
      var formdata = commonFunctions.getFormData(this.state.uploadRetailImage);
      this.props.actions.apiCall({
        urls: ["UPDATERETAILIMAGE"],
        method: "PUT",
        data: formdata,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this);
          }
          this.getUploadImageDetail(this.state.uploadRetailImage.retailId);
        },
        isFormData: true,
        showNotification: true,
      });
    } else if (this.state.activeStep === 2) {
      this.onNext(3, "Retail");
      const { whichRetailTypeId, addRetailGeneral } = this.state;
      addRetailGeneral.retailType = whichRetailTypeId;
      this.setState({ addRetailGeneral });
    }
  };

  changeStepToPricing = () => {
    const { type, activeStep } = this.state;
    if (this.state.uploadImage.productId) {
      if (
        (this.state.uploadImage.images[0] !== undefined &&
          this.state.uploadImage.DefaultImage[0] !== undefined) ||
        (this.state.uploadImage.images[0] === undefined &&
          this.state.uploadImage.DefaultImage[0] === undefined)
      ) {
        this.changeStep(3);
      } else if (
        this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined
      ) {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select default image.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else if (
      type === "Rental" &&
      activeStep === 1 &&
      this.state.uploadImage.productId === ""
    ) {
      this.changeNext(3);
    } else if (
      type === "Retail" &&
      activeStep === 1 &&
      this.state.uploadRetailImage.retailId === ""
    ) {
      this.changeNexted(3);
    } else if (this.state.uploadRetailImage.retailId) {
      if (
        (this.state.uploadRetailImage.images[0] !== undefined &&
          this.state.uploadRetailImage.DefaultImage[0] !== undefined) ||
        (this.state.uploadRetailImage.images[0] === undefined &&
          this.state.uploadRetailImage.DefaultImage[0] === undefined)
      ) {
        this.getRetailPrincingInfo(this.state.uploadRetailImage.retailId);

        this.changeStep(3);
      } else if (
        this.state.uploadRetailImage.images[0] !== undefined &&
        this.state.uploadRetailImage.DefaultImage[0] === undefined
      ) {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select default image.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };

  // changeStepToPricing = () => {
  //
  //   const {type,activeStep} = this.state;
  //   // 'Rental' 1
  //   if (this.state.uploadImage.productId && this.state.uploadImage.images.length <= 10) {
  //     if ((this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] !== undefined) ||
  //     (this.state.uploadImage.images[0] === undefined && this.state.uploadImage.DefaultImage[0] === undefined)) {
  //       this.changeStep(3)
  //     }
  //     else if (this.state.uploadImage.images[0] !== undefined && this.state.uploadImage.DefaultImage[0] === undefined) {
  //       this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
  //     }
  //   }

  //   else if (type=== "Rental" && activeStep === 1 && this.state.uploadImage.productId=== ""){
  //     this.handleGetImage()
  //   }

  //   else if (this.state.uploadRetailImage.retailId && this.state.uploadImage.images.length <= 10) {
  //     if ((this.state.uploadRetailImage.images[0] !== undefined && this.state.uploadRetailImage.DefaultImage[0] !== undefined) || (this.state.uploadRetailImage.images[0] === undefined && this.state.uploadRetailImage.DefaultImage[0] === undefined)) {
  //       this.getRetailPrincingInfo(this.state.uploadRetailImage.retailId)
  //       this.changeStep(3)
  //     }
  //     else if (this.state.uploadRetailImage.images[0] !== undefined && this.state.uploadRetailImage.DefaultImage[0] === undefined) {
  //       this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
  //     }
  //   }

  //   else {
  //     this.props.actions.showNotification({ title: "Error", message: 'Maximum 10 images allowed to upload.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
  //   }
  // }

  changeStepToGeneral = (step) => {
    if (this.state.uploadImage.productId) {
      if (
        (this.state.uploadImage.images[0] !== undefined &&
          this.state.uploadImage.DefaultImage[0] !== undefined) ||
        (this.state.uploadImage.images[0] === undefined &&
          this.state.uploadImage.DefaultImage[0] === undefined)
      ) {
        this.changeStep(1);
      } else if (
        this.state.uploadImage.images[0] !== undefined &&
        this.state.uploadImage.DefaultImage[0] === undefined
      ) {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select default image.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else if (this.state.uploadRetailImage.retailId && step === 3) {
      if (
        (this.state.uploadRetailImage.images[0] !== undefined &&
          this.state.uploadRetailImage.DefaultImage[0] !== undefined) ||
        (this.state.uploadRetailImage.images[0] === undefined &&
          this.state.uploadRetailImage.DefaultImage[0] === undefined)
      ) {
        this.changeStep(1);
      } else if (
        this.state.uploadRetailImage.images[0] !== undefined &&
        this.state.uploadRetailImage.DefaultImage[0] === undefined
      ) {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select default image.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
    //
    else if (
      this.state.uploadRetailImage.images[0] !== undefined &&
      this.state.uploadRetailImage.DefaultImage[0] === undefined
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select default image.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }

    // if (this.state.uploadRetailImage.images[0] !== undefined && this.state.uploadRetailImage.DefaultImage[0] === undefined) {
    //   this.props.actions.showNotification({ title: "Error", message: 'Please select default image.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    // }

    //
    else if (step === 1) {
      if (this.state.addRetailPrice.isRetailPriceChange) {
        this.state.addRetailPrice.isRetailPriceChange &&
          this.postRetailPricing();
      } else {
        this.changeStep(1);
      }
    }
  };
  // stepper //
  changeStep = (stepNumber) => {
    var forEdit =
      this.props.warnUserState.isFormChanged && this.state.updateRentalGeneral
        ? true
        : false;
    var forAdd =
      !this.props.warnUserState.isFormChanged && !this.state.updateRentalGeneral
        ? true
        : false;
    var step = forAdd
      ? true
      : this.props.warnUserState.isFormChanged &&
        !this.state.updateRentalGeneral
        ? true
        : false;
    if (forEdit || step) {
      if (stepNumber !== this.state.activeStep) {
        if (
          this.state.activeStep === 0 &&
          stepNumber > 0 &&
          !this.state.addItemType.firstStep
        ) {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: "Please select any Add Item type",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        } else if (this.state.activeStep === 1) {
          if (this.state.type === "Rental") {
            this.changeNext(stepNumber);
          } else {
            this.changeNexted(stepNumber);
          }
        } else {
          // if (this.state.activeStep === 2 && !this.state.addItemType.firstStep) {
          //   if (this.state.activeStep === 2 && stepNumber > this.state.activeStep) {
          //     if (this.state.type === "Rental") {
          //       this.changeNextImage();
          //       this.onNext(3, "Rental");
          //     } else {
          //       this.changedNext();
          //     }
          //   }
          //   this.setState({ activeStep: this.state.activeStep + 1 });
          // }
          if (
            this.state.activeStep === 2 &&
            !this.state.addItemType.firstStep
          ) {
            if (
              this.state.activeStep === 2 &&
              stepNumber > this.state.activeStep
            ) {
              if (this.state.type === "Rental") {
                this.changeNextImage();
                this.onNext(3, "Rental");
              } else {
                this.changedNext();
              }
            }
            this.setState({ activeStep: this.state.activeStep + 1 });
          }
          if (stepNumber < this.state.activeStep) {
            if (this.state.activeStep === 2) {
              if (this.state.type === "Rental") {
                this.changeNextImage();
                this.onNext(2, "Rental");
              } else {
                this.changedNext();
              }
            } else if (
              stepNumber === 1 &&
              this.state.addRetailPrice.isRetailPriceChange
            ) {
              {
                // this.postRetailPricing();
                // if (!this.state.addRetailPrice.isRetailPriceChange) {
                //   this.setState({ activeStep: 1 })
                // }
              }
            }
            this.setState({ activeStep: this.state.activeStep - 1 });
          }
        }
      }
    } else {
      if (
        this.state.activeStep === 3 &&
        this.state.editRetailPrice &&
        (this.state.addRetailPrice.costPrice ||
          this.state.addRetailPrice.salePrice)
      ) {
        // this.postRetailPricing();
      }
      this.setState({ activeStep: stepNumber });
    }
  };

  changeNext = (stepClick) => {
    debugger
    const { api } = this.props;
    if (this.validatorGeneral.allValid() === false) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        addItemGeneral: { ...this.state.addItemGeneral, valid: false },
      });
      return false;
    } else {
      if (
        this.state.activeStep === 1 &&
        this.validatorGeneral.allValid() === true
      ) {
        this.checkRestockTime(stepClick);
      } else if (
        this.state.updateRentalGeneral &&
        this.state.activeStep === 2
      ) {
        api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        );

        var formData = commonFunctions.getFormData(this.state.uploadImage);
        this.props.actions.apiCall({
          urls: ["UPDATEPRODUCTIMAGE"],
          method: "PUT",
          data: formData,
          onSuccess: (response) => {
            commonFunctions.onApiSucessFormChangedFalse(this);
            if (
              this.state.uploadImage.DefaultImageId === "" ||
              this.state.uploadImage.DefaultImageId === null
            ) {
              this.onNext(3, "Rental");
              this.props.getProductCategaoryDetail();
              this.props.getProductDetailList();
            }
            this.getProductImageOnUpdate(this.state.uploadImage.productId);
          },
          showNotification: true,
        });
      }
      // }
      else {
        this.setState({ activeStep: 2 });
      }
    }
  };

  //== Rental Image Function ==//
  changeNextImage = (e, data) => {
    const { api } = this.props;
    const { uploadImage } = this.state;
    if (uploadImage.DefaultImage.length > 0) {
      const tempArray = uploadImage.images;
      var remove = tempArray.filter(
        (temp) => temp.data_url !== uploadImage.DefaultImage[0].data_url
      );
      this.setState((uploadImage.images = remove));
    }
    if (
      this.state.isImageEdit === false &&
      (this.props.editItemModal === undefined ||
        this.state.uploadImage.DefaultImage.length > 0)
    ) {
      if (this.state.uploadImage.DefaultImage[0] !== undefined) {
        // api.isApiLoading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>;
        // )
        var formData = commonFunctions.getFormData(this.state.uploadImage);
        this.props.actions.apiCall({
          urls: ["UPLOADITEMIMAGE"],
          method: "POST",
          data: formData,
          onSuccess: (response) => {
            this.setState((this.state.uploadImage.images = []));
            this.setState((this.state.uploadImage.DefaultImage = []));
            this.setState((this.state.uploadImage.imageId = []));
            this.setState((this.state.uploadImage.deleted = []));
            this.setState((this.state.isImageEdit = true));
            if (response.responseCode == 200) {
              commonFunctions.onApiSucessFormChangedFalse(this);
            }
          },
          isFormData: true,
          showNotification: true,
        });
      } else {
        this.props.actions.showNotification(
          {
            title: "Error",
            message: "Please select default image.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else if (this.props.editItemModal || this.state.isImageEdit) {
      // this.state.uploadImage.images.filter((img) => img)
      api.isApiLoading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      );
      var formData = commonFunctions.getFormData(this.state.uploadImage);
      this.props.actions.apiCall({
        urls: ["UPDATEPRODUCTIMAGE"],
        method: "PUT",
        data: formData,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            commonFunctions.onApiSucessFormChangedFalse(this)
          }
        },
        isFormData: true,
        showNotification: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Maximum 10 images allowed to upload.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  handletrackableField = (status) => {
    this.setState({ trackable: status });
  };

  onImageChange = (imageList, index) => {
    this.setState(() => ({
      uploadImage: { ...this.state.uploadImage, images: imageList },
    }));
    commonFunctions.onFormChange(this);
  };

  handleChangeDefaultImage = (image) => {
    const { uploadImage } = this.state;
    if (!image.data_url && image !== undefined) {
      const removeUrl = image.split(env.IMG_URL);
      const setDefaultImageId = this.state.uploadImage.responseImages.filter(
        (img) => img.image === removeUrl[1]
      );
      uploadImage.DefaultImageId = setDefaultImageId[0].imageId;
      uploadImage.DefaultImage = [];
      uploadImage.images = [];
      // this.setState(uploadImage);
      this.setState({ ...this.state.uploadImage });
      this.changeNext();
    } else {
      this.setState(() => ({
        uploadImage: {
          ...this.state.uploadImage,
          DefaultImage: [image],
          DefaultImageId: "",
        },
      }));
    }
    // commonFunctions.onFormChange(this);
  };

  handleChangeDefaultRetailImage = (image) => {
    const { uploadRetailImage } = this.state;
    if (!image.data_url && image !== undefined) {
      const removeUrl = image.split(env.IMG_URL);
      const setDefaultImageId =
        this.state.uploadRetailImage.responseImages.filter(
          (img) => img.image === removeUrl[1]
        );
      uploadRetailImage.DefaultImageId = setDefaultImageId[0].imageId;
      uploadRetailImage.DefaultImage = [];
      uploadRetailImage.images = [];
      this.setState(uploadRetailImage);
      this.changedNext();
    } else {
      this.setState(() => ({
        uploadRetailImage: {
          ...this.state.uploadRetailImage,
          DefaultImage: [image],
          DefaultImageId: "",
        },
      }));
    }
    // commonFunctions.onFormChange(this);
  };
  // -FOR EDIT IMAGE REMOVE FUNCTION FOR RENTAL- //
  onImageRemove = (index) => {
    const allImages = this.state.uploadImage.images;
    if (
      this.state.uploadImage.DefaultImage[0] ===
      this.state.uploadImage.images[index]
    ) {
      this.setState((this.state.uploadImage.DefaultImage = []));
    }
    allImages.splice(index, 1);
    this.setState({
      uploadImage: { ...this.state.uploadImage, images: allImages },
    });

    if (
      this.state.uploadImage.imageId[index] &&
      this.state.uploadImage.imageId[index] !== null
    ) {
      this.state.uploadImage.deleted.push(
        this.state.uploadImage.imageId[index]
      );

      const allImageId = this.state.uploadImage.imageId;
      allImageId.splice(index, 1);
      this.setState({
        uploadImage: { ...this.state.uploadImage, imageId: allImageId },
      });
      commonFunctions.onFormChange(this);
    }
  };
  //==  Retail Image ==//
  onImageRemoved = (index) => {
    const allImages = this.state.uploadRetailImage.images;
    if (
      this.state.uploadRetailImage.DefaultImage[0] ===
      this.state.uploadRetailImage.images[index]
    ) {
      this.setState((this.state.uploadRetailImage.DefaultImage = []));
    }
    allImages.splice(index, 1);
    this.setState({
      uploadRetailImage: { ...this.state.uploadRetailImage, images: allImages },
    });

    if (
      this.state.uploadRetailImage.imageId[index] &&
      this.state.uploadRetailImage.imageId[index] !== null
    ) {
      this.state.uploadRetailImage.deleted.push(
        this.state.uploadRetailImage.imageId[index]
      );

      const allImageId = this.state.uploadRetailImage.imageId;
      allImageId.splice(index, 1);
      this.setState({
        uploadRetailImage: {
          ...this.state.uploadRetailImage,
          imageId: allImageId,
        },
      });
    }
    commonFunctions.onFormChange(this);
  };

  onImageChangeRetail = (imageList) => {
    this.setState(() => ({
      uploadRetailImage: { ...this.state.uploadRetailImage, images: imageList },
    }));
    commonFunctions.onFormChange(this);
  };

  //===== Retail  function post  ====== //
  changeNexted = (stepClick) => {
    if (this.validator.allValid() === false) {
      this.validator.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please fill all required fields.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      this.setState({
        addRetailGeneral: { ...this.state.addRetailGeneral, valid: false },
      });
      return false;
    } else if (!this.state.updateRentalGeneral) {
      const { addRetailGeneral } = this.state;
      const { minItemPerOrder, maxItemPerOrder } = this.state.addRetailGeneral;
      if (
        minItemPerOrder || maxItemPerOrder
          ? minItemPerOrder >= maxItemPerOrder
          : null
      ) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `Max Per Order item can not less then min per order.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
      this.props.actions.apiCall({
        urls: ["ADDRETAIL"],
        method: "Post",
        data: addRetailGeneral,
        onSuccess: (response) => {
          if (response.responseCode == 200) {
            this.setState({
              addRetailGeneral: {
                ...this.state.addRetailGeneral,
                retailId: response.keyId,
              },
            });
            this.setState({
              uploadRetailImage: {
                ...this.state.uploadRetailImage,
                retailId: response.keyId,
              },
            });
            this.setState({
              addRetailPrice: {
                ...this.state.addRetailPrice,
                retailId: response.keyId,
              },
            });
            this.setState({
              updateRentalGeneral: true,
              whichRetailTypeId: addRetailGeneral.retailType,
            });
            this.onNext(stepClick, "Retail", addRetailGeneral.retailType);
            commonFunctions.onApiSucessFormChangedFalse(this);
          }
        },
        showNotification: true,
      });
    } else if (this.state.updateRentalGeneral && this.state.activeStep === 1) {
      const { addRetailGeneral } = this.state;
      const { minItemPerOrder, maxItemPerOrder } = this.state.addRetailGeneral;
      if (
        minItemPerOrder || maxItemPerOrder
          ? minItemPerOrder >= maxItemPerOrder
          : null
      ) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `Max Per Order item can not less then min per order.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
      this.props.actions.apiCall({
        urls: ["UPDATERETAIL"],
        method: "PUT",
        data: addRetailGeneral,
        onSuccess: (response) => {
          commonFunctions.onApiSucessFormChangedFalse(this);
          this.onNext(stepClick, "Retail", addRetailGeneral.retailType);
          this.props.getProductCategoryDetail();
          this.props.getProductDetailList && this.props.getProductDetailList();
        },
        showNotification: true,
      });
    }

    // }
    else {
      this.setState({ activeStep: 2 });
    }
  };

  checkRestockTime = (stepClick) => {
    const { minItemPerOrder, maxItemPerOrder } = this.state.addItemGeneral;
    const a =
      this.state.addItemGeneral.beforeTimeDisable &&
        this.state.addItemGeneral.beforeTimeType === null
        ? true
        : false;
    const b =
      this.state.addItemGeneral.afterTimeDisable &&
        this.state.addItemGeneral.afterTimeType === null
        ? true
        : false;
    if (a || b) {
      this.validatorGeneral.showMessages();
      this.forceUpdate();
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Fill duration field of active restock time.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (
      minItemPerOrder || maxItemPerOrder
        ? minItemPerOrder >= maxItemPerOrder
        : null
    ) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Max Per Order item can not less then min per order.`,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    } else {
      if (!this.state.updateRentalGeneral) {
        this.props.actions.apiCall({
          urls: ["ADDPRODUCT"],
          method: "POST",
          data: this.state.addItemGeneral,
          onSuccess: (response) => {
            if (response.responseCode == 200) {
              commonFunctions.onApiSucessFormChangedFalse(this);
              this.setState({
                CreatedProductId: response.keyId,
                activeStep: stepClick,
              });
              this.setState({
                addItemGeneral: {
                  ...this.state.addItemGeneral,
                  productId: response.keyId,
                },
              });
              this.setState({
                uploadImage: {
                  ...this.state.uploadImage,
                  productId: response.keyId,
                },
              });
              this.setState({
                rentalPricing: {
                  ...this.state.rentalPricing,
                  productId: response.keyId,
                },
              });
              this.setState({
                addItemPricing: {
                  ...this.state.addItemPricing,
                  productId: response.keyId,
                },
              });
              this.setState({ updateRentalGeneral: true });
              this.props.actions.apiCall({
                urls: ["GETRENTALPRODUCTPrice"],
                method: "GET",
                data: {
                  productId: response.keyId,
                  rentalPricingId: -1,
                  businessId: this.props.global.bussinessDetail.businessId,
                  businessLocationId:
                    this.props.global.locationSelected.BusinessLocationId,
                  PageNo: 1,
                  PageSize: 100,
                },
                onSuccess: (response) => {
                  this.setState({ rentalPriceList: response });
                  this.props.getProductCategoryDetail &&
                    this.props.getProductCategoryDetail();
                  this.props.getProductDetailList &&
                    this.props.getProductDetailList();
                },
              });
            }
          },
          showNotification: true,
        });
      }
      if (this.state.updateRentalGeneral && this.state.activeStep === 1) {
        this.props.actions.apiCall({
          urls: ["UPDATEPRODUCT"],
          method: "PUT",
          data: this.state.addItemGeneral,
          onSuccess: (response) => {
            commonFunctions.onApiSucessFormChangedFalse(this);
            this.onNext(stepClick, "Rental");
            this.getProductImageOnUpdate(this.state.uploadImage.productId);
            this.props.getProductCategoryDetail();
            this.props.getProductDetailList &&
              this.props.getProductDetailList();
          },
          showNotification: true,
        });
      }
    }
  };

  showtrackbulk = () => {
    this.setState({
      addItemType: {
        ...this.state.addItemType,
        rentalretail: false,
        trackbulk: true,
      },
    });
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  };

  // === RETAIL ON HANDLECHANGE=== //
  onHandleChangeretail = (e, { name, value, type, checked, data }) => {
    var addRetailGeneral = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addRetailGeneral,
      this
    );
    this.setState({ addRetailGeneral });
  };

  onHandleChangePrice = (e, { name, value, type, checked, data }) => {
    var addRetailPrice = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addRetailPrice,
      this
    );
    addRetailPrice.isRetailPriceChange = true;
    this.setState({ addRetailPrice });
  };

  allStateBlank = () => {
    this.setState(this.initialState);
  };

  // === EDIT DID UPDATE === //
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.editItemModal &&
      this.state.uploadImage.productId !== prevProps.productId
    ) {
      this.getProductRental(this.props.productId);
      this.setState({
        uploadImage: {
          ...this.state.uploadImage,
          productId: this.props.productId,
        },
      });
      this.setState({
        activeStep: 1,
        type: "Rental",
        updateRentalGeneral: this.props.editItemModal,
      });
    }
    if (
      this.props.inventoryType ===
      this.props.global.inventoryDetail.businessCategoryId &&
      this.props.global.inventoryDetail.businessCategoryName === "Rental" &&
      prevState.addItemGeneral.categoryId === null
    ) {
      // this.getCategories();
      this.setState({
        activeStep: 1,
        type: "Rental",
        updateRentalGeneral: this.props.editItemModal,
      });

      let newCategoryId =
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
          this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1
          ? this.props.global.inventoryDetail.inventoryBreadcrumb[
            this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
          ].parentId
          : undefined;
      let newCategoryid = newCategoryId === null ? undefined : newCategoryId;

      this.setState(() => ({
        addItemGeneral: {
          ...this.state.addItemGeneral,
          categoryId: newCategoryid,
        },
      }));

      this.state.expandthisnode.push(
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 &&
        this.props.global.inventoryDetail.inventoryBreadcrumb[
          this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
        ].parentId
      );
    }
    /// for retail ///
    if (
      this.props.editRetailDetail &&
      !this.props.editItemModal &&
      this.state.uploadRetailImage.retailId !== prevProps.retailId
    ) {
      this.onNext(1, "Retail", this.props.retailTypeId);
      this.getRetailGeneralInfo(this.props.retailId);
      this.getUploadImageDetail(this.props.retailId);
      this.getRetailPrincingInfo(this.props.retailId);
      this.setState({
        uploadRetailImage: {
          ...this.state.uploadRetailImage,
          retailId: this.props.retailId,
        },
        thirdStepCompleted: true,
      });
      this.setState({
        activeStep: 1,
        type: "Retail",
        updateRentalGeneral: this.props.editRetailDetail,
        updateRetailPrice: this.props.editRetailDetail,
      });
    }
    if (
      this.props.inventoryType ===
      this.props.global.inventoryDetail.businessCategoryId &&
      this.props.global.inventoryDetail.businessCategoryName === "Retail" &&
      prevState.addRetailGeneral.categoryId === null
    ) {
      this.setState({
        addItemType: {
          ...this.state.addItemType,
          rentalretail: false,
          trackbulk: true,
        },
      });
      this.setState({
        activeStep: 0,
      });

      let newCategoryId =
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
          this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1
          ? this.props.global.inventoryDetail.inventoryBreadcrumb[
            this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
          ].parentId
          : undefined;
      let newCategoryid = newCategoryId === null ? undefined : newCategoryId;

      this.setState({
        addRetailGeneral: {
          ...this.state.addRetailGeneral,
          categoryId: newCategoryid,
        },
      });
      this.getCategories();
      this.state.expandthisnode.push(
        this.props.global.inventoryDetail.inventoryBreadcrumb &&
        this.props.global.inventoryDetail.inventoryBreadcrumb.length > 1 &&
        this.props.global.inventoryDetail.inventoryBreadcrumb[
          this.props.global.inventoryDetail.inventoryBreadcrumb.length - 1
        ].parentId
      );
    }
  }

  //====== Get retail Details =======//
  getRetailGeneralInfo = (retailId) => {
    this.props.actions.apiCall({
      urls: ["GETRETAIL"],
      method: "GET",
      data: {
        retailId: retailId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        businessCategoryType: commonFunctions.getGlobalCodeDetails(
          this.props.global.codes,
          "BusinessCategoryType",
          "Retail"
        ).globalCodeId,
        categoryId: this.props.global.inventoryDetail.categoryId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        const retailTypeId = response[0].retailType;
        if (response[0].retailTypeName === "Trackable") {
          this.handletrackableField(true);
        } else {
          this.handletrackableField(false);
        }
        this.onNext(1, "Retail", retailTypeId);
        if (response.length > 0) {
          this.setState({
            addRetailGeneral: {
              ...this.state.addRetailGeneral,
              retailId: response[0].retailId,
              itemName: response[0].itemName,
              itemQuantity: response[0].itemQuantity,
              productTags: JSON.parse(response[0].productTags),
              itemDescription: response[0].itemDescription,
              stockIdPrefix: response[0].stockIdPrefix,
              categoryId: response[0].categoryId,
              color: response[0].color,
              inConfirmationEmail: response[0].inConfirmationEmail,
              webWidget: response[0].webWidget,
              bookingConfirmation: response[0].bookingConfirmation,
              inStore: response[0].inStore,
              websiteWidget: response[0].websiteWidget,
              addOns: JSON.parse(response[0].addOn),
              cancellationPolicyDescription:
                response[0].cancellationPolicyDescription,
              cancellationPolicyInStore: response[0].cancellationPolicyInStore,
              cancellationPolicyWebsiteWidget:
                response[0].cancelletionPolicyWebsiteWidget,
              cancellationPolicyInConfirmationEmail:
                response[0].cancelletionPolicyInConfirmationEmail,
              customField: JSON.parse(response[0].customFields),
              retailCustomQuestion: JSON.parse(response[0].customQuestions),
              deliveryOption: response[0].deliveryOption,
              allowCustomerToEnterDeliveryAddress:
                response[0].allowCustomerToEnterDeliveryAddress,
              retailCustomQuestion: JSON.parse(
                response[0].retailCustomQuestion),
              traits: JSON.parse(response[0].traits),
              deliveryCustomField: JSON.parse(response[0].deliveryCustomFields),
              minTimePeriod: response[0].minTimePeriod,
              minTimePeriodType: response[0].minTimePeriodType,
              maxTimePeriod: response[0].maxTimePeriod,
              maxTimePeriodType: response[0].maxTimePeriodType,
              minItemPerOrder: response[0].minItemPerOrder,
              maxItemPerOrder: response[0].maxItemPerOrder,
              isEmailBooked: response[0].isEmailBooked,
              roleId: response[0].roleId,
              toEmail: JSON.parse(response[0].toEmail ?? "[]"),

            },
          });
          this.setState({
            customTraits: {
              ...this.state.customTraits,
              traits: JSON.parse(response[0].traits),
            },
          });
          this.setState({
            productCustomQuestion: {
              ...this.state.productCustomQuestion,
              customerQues: JSON.parse(response[0].retailCustomQuestion),
            },
          });

          this.setState({
            customfield: {
              ...this.state.customfield,
              customFieldRows: JSON.parse(response[0].customFields),
            },
          });
          this.setState({
            customfield: {
              ...this.state.customfield,
              deliveryCustomField: JSON.parse(response[0].deliveryCustomFields),
            },
          });
          this.MinDurationChange(response[0].minTimePeriodType);
        }
      },
    });
  };
  // ======= Retail edit uploadImage  =======  //
  getUploadImageDetail = (retailId) => {
    this.setState((this.state.uploadRetailImage.images = []));
    this.setState((this.state.uploadRetailImage.DefaultImage = []));
    this.props.actions.apiCall({
      urls: ["GETRETAILIMAGE"],
      method: "GET",
      data: {
        retailId: retailId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        let isimageExist = response.length > 0;
        this.setState({ isRetailImageAdded: true });
        const { uploadRetailImage } = this.state;
        // this.setState({ isRetailImageAdded: isimageExist })
        this.setState((uploadRetailImage.responseImages = response));
        response.map((data) => {
          uploadRetailImage.imageId.push(data.imageId);
          uploadRetailImage.images.push(
            commonFunctions.concatenateImageWithAPIUrl(data.image)
          );
        });
        var isDefaultImage = response.filter((x) => x.isDefaultImage === true);
        if (isDefaultImage) {
          uploadRetailImage.DefaultImage.push(
            commonFunctions.concatenateImageWithAPIUrl(isDefaultImage[0].image)
          );
          this.setState(
            (uploadRetailImage.DefaultImageId = isDefaultImage[0].imageId),
            (uploadRetailImage.responseImages = response)
          );
        }

        this.setState({ uploadRetailImage, isRetailImageAdded: true });
      },
    });
  };

  /// ======  Retail  pricing  get  ========= ///
  getRetailPrincingInfo = (retailId) => {
    this.props.actions.apiCall({
      urls: ["GETRETAILPRICE"],
      method: "GET",
      data: {
        retailId: retailId,
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        if (response.length > 0) {
          const { addRetailPrice, editRetailPrice } = this.state;
          addRetailPrice.costPrice =
            response[0].costPrice === 0 ? null : response[0].costPrice;
          addRetailPrice.salePrice = response[0].salePrice;
          addRetailPrice.isEmailBooked = response[0].isEmailBooked;
          addRetailPrice.roleId = response[0].roleId;
          addRetailPrice.toEmail = JSON.parse(response[0].toEmail ?? "[]");
          // addRetailPrice.taxIncludedPrice = response[0].taxIncludedPrice;
          // addRetailPrice.generalTaxes = response[0].generalTaxes ?  JSON.parse(response[0].generalTaxes).map(item =>{return (item.GeneralTaxId)}) : [];
          // addRetailPrice.taxForOrders =response[0].taxForOrders;
          addRetailPrice.retailPriceId = response[0].retailPriceId;
          addRetailPrice.isRetailPriceChange = false;
          this.setState({ addRetailPrice });
          // this.setState({ editRetailPrice: true });

          //  let {TaxRow} = this.state;
          //  TaxRow['TaxNew'] = TaxRow.TaxNew.concat(JSON.parse(response[0].generalTaxes));
          //  this.setState({TaxRow});
        }
      },
    });
  };

  // ***********************MinMaxTimeDuration*************************** //
  MinDurationChange = (value) => {
    const { TimeDurationMin, rentalItemTimeSelect } = this.state;
    const TimeDurationValue = TimeDurationMin.find((a) => a.value === value);
    if (TimeDurationValue.text === "Select TimePeriod") {
      this.setState({ rentalItemTimeSelectedValue: [] });
    } else if (TimeDurationValue.key === "Day") {
      const rentalItemTimeSelected = rentalItemTimeSelect.filter(
        (durationTime) =>
          durationTime.key === "Monthly" ||
          durationTime.key === "Week (7Days per week)" ||
          durationTime.key === "Day" ||
          durationTime.key === "Yearly" ||
          durationTime.text === "Select TimePeriod"
      );
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeSelected });
    } else if (TimeDurationValue.key === "Hour") {
      const rentalItemTimeHour = rentalItemTimeSelect.filter(
        (durationTime) =>
          durationTime.key === "Monthly" ||
          durationTime.key === "Week (7Days per week)" ||
          durationTime.key === "Day" ||
          durationTime.key === "Yearly" ||
          durationTime.key === "Hour" ||
          durationTime.text === "Select TimePeriod"
      );
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeHour });
    } else if (TimeDurationValue.key === "Week (7Days per week)") {
      const rentalItemTimeWeek = rentalItemTimeSelect.filter(
        (durationTime) =>
          durationTime.key === "Monthly" ||
          durationTime.key === "Week (7Days per week)" ||
          durationTime.key === "Yearly" ||
          durationTime.text === "Select TimePeriod"
      );
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeWeek });
    } else if (TimeDurationValue.key === "Monthly") {
      const rentalItemTimeMonth = rentalItemTimeSelect.filter(
        (durationTime) =>
          durationTime.key === "Monthly" ||
          durationTime.key === "Yearly" ||
          durationTime.text === "Select TimePeriod"
      );
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeMonth });
    } else if (TimeDurationValue.key === "Yearly") {
      const rentalItemTimeYearly = rentalItemTimeSelect.filter(
        (durationTime) =>
          durationTime.key === "Yearly" ||
          durationTime.text === "Select TimePeriod"
      );
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeYearly });
    } else {
      this.setState({ rentalItemTimeSelectedValue: rentalItemTimeSelect });
    }
  };
  getGlobalCodeMin = () => {
    const TimePeriod = [
      { text: "Select TimePeriod", value: null, disable: true },
    ];
    this.props.global.codes
      .filter((code) => code.categoryName === "RentalItemTimePeriod")
      .map((filtercode) => {
        TimePeriod.push({
          key: filtercode.codeName,
          value: filtercode.globalCodeId,
          text: filtercode.codeName,
        });
        return { ...TimePeriod };
      });
    this.setState({ rentalItemTimeSelect: TimePeriod });
  };
  getGobalCodeDurationTime = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: { CategoryName: "RentalItemTimePeriod", GlobalCodeId: -1 },
      onSuccess: (response) => {
        const TimePeriod = [
          { text: "Select TimePeriod", value: null, disable: true },
        ];
        response.map((singleTime) => {
          TimePeriod.push({
            value: singleTime.globalCodeId,
            text: singleTime.codeName,
            key: singleTime.codeName,
            id: singleTime.durationTime,
          });
          return { ...TimePeriod };
        });
        this.setState({ TimeDurationMin: TimePeriod });
      },
    });
  };

  getStepContent = (stepIndex) => {
    const { generalTaxList, retailId } = this.state;
    const { api } = this.props;
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            <AddItemType
              NextClick={this.onNext}
              handleDocumentFeilds={this.handleDocumentFeilds}
              handletrackableField={this.handletrackableField}
              handlepricingField={this.handlepricingField}
              addItemType={this.state.addItemType}
              showtrackbulk={this.showtrackbulk}
            />
            <Grid>
              <Grid.Column width={16} textAlign="right"></Grid.Column>
            </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            {(this.state.loadingApi || api.isApiLoading) && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            {this.state.type === "Rental" ? (
              <AddItemGeneral
                isEdited={this.props.editItemModal}
                fullState={this.state}
                allFunction={this}
              />
            ) : (
              <AddRetailGeneral
                onNext={this.onNext}
                onPrev={this.onPrevious}
                trackable={this.state.trackable}
                next={3}
                back={0}
                fullState={this.state}
                allFunction={this}
              />
            )}
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            {api.isApiLoading && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            {this.state.type === "Rental" ? (
              <UploadImages
                editItemDetail={this.state.editItemDetail}
                dataSet={this.state.dataSet}
                onNext={this.onNext}
                next={3}
                back={1}
                onPrev={this.onPrevious}
                backStep={this.backStep}
                uploadImage={this.state.uploadImage}
                fullState={this.state}
                allFunction={this}
                onImageChange={this.onImageChange}
                handleChangeDefaultImage={this.handleChangeDefaultImage}
                editItemModal={this.props.editItemModal}
                onImageRemove={this.onImageRemove}
              />
            ) : (

              <UploadRetailImages
                editItemDetail={this.state.editItemDetail}
                dataSet={this.state.dataSet}
                next={3}
                back={1}
                onNext={this.onNext}
                onPrev={this.onPrevious}
                backStep={this.backStep}
                uploadRetailImage={this.state.uploadRetailImage}
                onImageChange={this.onImageChangeRetail}
                changedNext={this.changedNext}
                editRetailDetail={this.props.editRetailDetail}
                handleChangeDefaultImage={this.handleChangeDefaultRetailImage}
                changeEditNext={this.changeEditNext}
                onImageRemoved={this.onImageRemoved}
                fullState={this.state}
                allFunction={this}
              />
            )}
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            {api.isApiLoading && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            {this.state.type === "Rental" ? (
              <AddItemPricing
                fullState={this.state}
                allFunction={this}
                getProductDetailList={this.props.getProductDetailList}
                getProductCategoryDetail={this.props.getProductCategoryDetail}
              />
            ) : (
              <AddRetailPricing
                getGeneralTax={this.getGeneralTax}
                onNext={this.onNext}
                next={0}
                back={2}
                onPrev={this.onPrevious}
                getProductCategoryDetail={this.props.getProductCategoryDetail}
                closeModal={this.props.closeModal}
                generalTaxList={generalTaxList}
                retailId={retailId}
                validator={this.validatorGeneral}
                fullState={this.state}
                allFunction={this}
              />
            )}
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  onNext = (step, name, id) => {
    const { addRetailGeneral, whichRetailTypeId } = this.state;
    addRetailGeneral.retailType = id;
    this.setState({ activeStep: step, type: name });
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
    if (this.state.CreatedProductId !== undefined && name === "lastStep") {
      this.props.actions.showNotification(
        {
          title: "Success",
          message: this.props.editItemModal
            ? "Item updated successfully"
            : "Item added successfully",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "success"
      );
    }
    if (step === 2 && name === "Retail") {
      this.setState((this.state.uploadRetailImage.images = []));
      this.setState((this.state.uploadRetailImage.DefaultImage = []));
      this.getUploadImageDetail(this.state.uploadRetailImage.retailId);
    }
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };

  backStep = (e) => {
    e.preventDefault();
    this.props.onPrev(this.back);
  };

  ///////////// -for prompt if any changes in form- ///////////
  onCloseModalStepOne = () => {
    if (this.props.warnUserState.isFormChanged) {
      this.setState({ isCommonDeleteUpdateModalOpen: true });
      this.validator = commonFunctions.initializeSimpleValidator();
    } else {
      this.onModalCloseFormFalse();
    }
  };

  onModalCloseFormFalse = () => {
    this.props.closeModal();
    if (this.props.getProductCategoryDetail) {
      this.props.getProductCategoryDetail();
    }
    this.setState(this.initialState);
    this.setState({
      activeStep: 0,
      imageData: false,
      firstStepCompleted: false,
      secondStepCompleted: false,
      thirdStepCompleted: false,
    });
  };

  onConfirmWarnUser = () => {
    this.setState({ isCommonDeleteUpdateModalOpen: false });
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.onModalCloseFormFalse();
  };
  handleGetImage = () => {
    const { addRetailPrice } = this.state;
    if (this.state.uploadImage.productId || this.state.type === "Rental") {
      this.getProductImageOnUpdate(this.state.uploadImage.productId);
      this.changeStep(2);
    } else if (
      this.state.uploadRetailImage.retailId ||
      this.state.type === "Retail"
    ) {
      this.getUploadImageDetail(this.state.uploadRetailImage.retailId);
      this.changeStep(2);
    }
    if (addRetailPrice.isRetailPriceChange) {
      this.postRetailPricing();
    }
  };

  render() {
    const { activeStep, isCommonDeleteUpdateModalOpen, updateRentalGeneral } =
      this.state;
    return (
      <>
        <Confirm
          open={this.state.open}
          onCancel={this.close}
          onConfirm={this.closeBothModal}
          content="This is a custom message"
          size="mini"
        />
        <Modal
          className="ItemModal"
          open={this.props.openModal}
          closeIcon
          onClose={this.onCloseModalStepOne}
          closeOnDimmerClick={false}
          size="large"
        >
          {this.props.editRetailDetail || this.props.editItemModal ? (
            <Modal.Header className="ora">Edit Item </Modal.Header>
          ) : (
            <Modal.Header>Add Item</Modal.Header>
          )}
          <Modal.Content scrolling>
            <Modal.Description>
              <Step.Group
                stackable="tablet"
                className="custom-stepper item-stepper"
              >
                <Step
                  active={activeStep === 0 ? true : false}
                  disabled={
                    this.props.editItemModal ||
                    this.props.editRetailDetail ||
                    updateRentalGeneral
                  }
                  completed={activeStep > 0 ? true : false}
                  onClick={() => this.changeStep(0)}
                >
                  <Step.Content>
                    <Step.Title>
                      Type <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>1</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 1 ? true : false}
                  completed={activeStep > 1 ? true : false}
                  onClick={() => this.changeStepToGeneral(1)}
                >
                  <Step.Content>
                    <Step.Title>
                      General{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>2</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 2 ? true : false}
                  completed={activeStep > 2}
                  onClick={() => this.handleGetImage(2)}
                >
                  <Step.Content>
                    <Step.Title>
                      Photo <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>3</Step.Description>
                  </Step.Content>
                </Step>
                <Step
                  active={activeStep === 3 ? true : false}
                  completed={activeStep > 3}
                  onClick={() => this.changeStepToPricing(3)}
                >
                  <Step.Content>
                    <Step.Title>
                      Pricing{" "}
                      <Icon name="check circle" className="green-check" />
                    </Step.Title>
                    <Step.Description>4</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              <div>{this.getStepContent(activeStep)}</div>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {isCommonDeleteUpdateModalOpen && (
          <CommonDeleteUpdateModal
            isModalOpen={isCommonDeleteUpdateModalOpen}
            onConfirm={this.onConfirmWarnUser}
            onClose={() =>
              this.setState({ isCommonDeleteUpdateModalOpen: false })
            }
            confirmationButtonText={"Yes"}
            header={"Are you sure you want to discard your changes ?"}
            cancelButtonText={"Cancel"}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddItemModal);
