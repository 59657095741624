import React, { Component } from "react";
import {
  Button,
  Header,
  Modal,
  Grid,
  Form,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import CommonCropImage from "../../../atoms/common-crop-image";
import {
  connect,
  bindActionCreators,
  actions,
  commonFunctions,
  Notifications,
  env,
} from "../../../../functional/global-import";
import { CommonChooseTags } from "../../../../components";
import { withRouter } from "react-router-dom";

class AddCategoryModal extends Component {
  get initialState() {
    return {
      addCategory: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        parentId: "",
        categoryId: "",
        businessCategoryType: "",
        categoryName: "",
        isActive: this.props.addOrEdit === "Edit" ? false : true,
        inStore: true,
        image: [],
        showInWebsiteWidgetMenu: true,
        link: "",
        categoryTag: [],
        ActionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isImageUpdated: false,
      addOrEdit: this.props.addOrEdit,
      defaultcategoryId: "",
      fileList: [],
      imageData: [],
      categoryImageId: "",
      masterCatergoryImage: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addCategory = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addCategory
    );
    this.setState({ addCategory });
  };

  onHandleSubmit = (e) => {
    const { addCategory } = this.state;
    addCategory.parentId = this.props.global.inventoryDetail.categoryId;
    addCategory.businessCategoryType =
      this.props.global.inventoryDetail.businessCategoryId;
    this.setState({ addCategory });
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      var FormData = commonFunctions.getFormData(this.state.addCategory);
      this.props.actions.apiCall({
        urls: ["ADDCATEGORY"],
        method: "POST",
        data: FormData,
        onSuccess: (response) => {
          this.props.closeModal();
          this.props.getProductCategoryDetail();
          this.setState(this.initialState);
        },
        isFormData: true,
        showNotification: true,
      });
    }
  };

  onHandleSave = (e) => {
    const onSave = this.props.onSave;
    if (onSave === true) {
      this.props.history.push(`${env.PUBLIC_URL}/inventory/category-view`);
      this.onHandleSubmit(e);
    } else {
      this.onHandleSubmit(e);
    }
  };

  getCategoryDetail = (categoryId) => {
    const { addCategory } = { ...this.state };
    this.props.actions.apiCall({
      urls: ["GETCATEGORY"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        userId: this.props.auth.userDetail.keyId,
        businessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        categoryId: categoryId,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: ([response]) => {
        let addCategoryDetail = response;
        addCategory.categoryId = addCategoryDetail.categoryId;
        addCategory.categoryName = addCategoryDetail.categoryName;
        addCategory.inStore = addCategoryDetail.inStore;
        addCategory.isActive = addCategoryDetail.isActive;
        addCategory.link = addCategoryDetail.link;
        addCategory.showInWebsiteWidgetMenu =
          addCategoryDetail.showInWebsiteWidgetMenu;
        addCategory.categoryTag = JSON.parse(addCategoryDetail.categoryTag);
        const imageData = JSON.parse(addCategoryDetail.image);
        const imageDetail = [
          {
            uid: "2",
            status: "done",
            url:
              imageData === null
                ? commonFunctions.concatenateImageWithAPIUrl(null)
                : commonFunctions.concatenateImageWithAPIUrl(
                    imageData[0].ImageFile
                  ),
          },
        ];
        this.setState(() => ({
          addCategory,
          fileList: imageDetail,
          imageData,
          categoryImageId: addCategoryDetail.categoryId,
          ImageId: imageData === null ? null : imageData[0].ImageId,
        }));
      },
    });
  };

  onRemoveImage = () => {
             
    if (this.state.fileList.length > 0) {
      this.props.actions.apiCall({
        urls: ["DELETECROPIMAGE"],
        method: "DELETE",
        data: {
          ImageId: this.state.ImageId,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: (response) => {
                   
          // this.closeModal();
          this.setState({ fileList: [] });
          // this.props.getProductCategoryDetail();
        },
        // showNotification: true,
        isFormData: true,
      });
    }
  };


  updateCategory = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { addCategory } = this.state;
      var formData = commonFunctions.getFormData({
        businessId: this.props.global.bussinessDetail.businessId,
        businessCategoryType:
          this.props.global.inventoryDetail.businessCategoryId,
        categoryName: addCategory.categoryName,
        categoryTag: addCategory.categoryTag,
        categoryId: addCategory.categoryId,
        link: addCategory.link,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        isActive: addCategory.isActive,
        inStore: addCategory.inStore,
        image: addCategory.image,
        parentId: this.props.global.inventoryDetail.categoryId,
        isImageUpdated: this.state.isImageUpdated,
        showInWebsiteWidgetMenu: addCategory.showInWebsiteWidgetMenu,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      });
                 
      this.props.actions.apiCall({
        urls: ["UPDATECATEGORY"],
        method: "PUT",
        data: formData,
        onSuccess: (response) => {
          this.props.closeModal();
          this.props.getProductCategoryDetail();
          this.setState({ defaultcategoryId: null });
          this.setState(this.initialState);
        },
        showNotification: true,
      });
    }
  };

  componentDidMount() {
               
    if (this.props.editCategoryModal === true) {
      this.getCategoryDetail(this.props.categoryId);
    }
  }
  // componentDidUpdate() {
  //   this.getCategoryDetail(this.props.categoryId);
  // }

  onImageChange = (imageList) => {
    this.setState(() => ({
      addCategory: { ...this.state.addCategory, image: imageList },
      isImageUpdated: true,
    }));
  };

  onImageRemove = (imageList) => {
    this.setState(() => ({
      addCategory: { ...this.state.addCategory, image: [] },
      isImageUpdated: true,
    }));
  };

  onCloseModal = () => {
            
    !this.props.categoryId && this.onRemoveImage();
    this.props.closeModal();
    this.props.getProductCategoryDetail();
    this.setState({ defaultcategoryId: null });
    this.setState(this.initialState);
  };

  render() {
    const { api } = this.props;
    const { addCategory } = this.state;
    const fullState = this.state;
    const allFunction = this;
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.onCloseModal}
        closeOnDimmerClick={false}
        size={"tiny"}
      >
        <Header
          content={`${this.props.addOrEdit} ${this.props.categoryOrSub}`}
        />
        <Modal.Content>
          {this.props.editCategoryModal && api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Grid>
            <Grid.Column mobile={12} tablet={7} computer={6} textAlign="center">
              <CommonCropImage
                StateRef={this}
                fullState={this.state}
                ImageType="CategoryImage"
                cropId={this.state.categoryImageId}
                stateForm={this.state.addCategory}
                isDefaultImage={false}
                businessLocationId={
                  this.props.global.locationSelected.BusinessLocationId
                }
              />
            </Grid.Column>
            <Grid.Column mobile={12} tablet={9} computer={10}>
              <Form>
                <Form.Input
                  fluid
                  id="form-subcomponent-shorthand-input-first-name"
                  placeholder="Category Name"
                  value={addCategory.categoryName}
                  name="txtcategoryName"
                  data="categoryName"
                  error={this.validator.message(
                    "categoryName",
                    addCategory.categoryName,
                    "required||max:100"
                  )}
                  onChange={this.onHandleChange}
                />
                <CommonChooseTags
                  fullState={fullState}
                  allFunction={allFunction}
                  stateForm="addCategory"
                  variableName="categoryTag"
                />
              </Form>
              <br />
              <Form>
                <Form.Group widths="equal">
                  <Form.Checkbox
                    className="custome-checkbox"
                    value={addCategory.inStore}
                    checked={addCategory.inStore}
                    onChange={this.onHandleChange}
                    data="inStore"
                    label="In store"
                  />
                  <Form.Checkbox
                    label="Active"
                    data="isActive"
                    checked={addCategory.isActive}
                    value={addCategory.isActive}
                    onChange={this.onHandleChange}
                    className="custom-toggle"
                  />
                </Form.Group>
                <Form.Checkbox
                  className="custome-checkbox"
                  value={addCategory.showInWebsiteWidgetMenu}
                  checked={addCategory.showInWebsiteWidgetMenu}
                  onChange={this.onHandleChange}
                  data="showInWebsiteWidgetMenu"
                  label="Show on Website"
                />
              </Form>
            </Grid.Column>
            <Grid.Column width={16}>
              {this.props.categoryId ? (
                <Button
                  className="orange-btn"
                  floated="right"
                  onClick={this.updateCategory}
                  loading={api.isApiLoading}
                >
                  {" "}
                  Update
                </Button>
              ) : (
                <Button
                  className="orange-btn"
                  floated="right"
                  onClick={this.onHandleSave}
                  loading={api.isApiLoading}
                >
                  {" "}
                  Save{" "}
                </Button>
              )}
              <Button
                className="blue-btn"
                floated="right"
                onClick={this.onCloseModal}
              >
                {" "}
                Cancel{" "}
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCategoryModal));
