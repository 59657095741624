import React, { Component } from "react";
import { Link } from "react-router-dom";
import { loadStripeTerminal } from "@stripe/terminal-js";
import StripePaymentModal from "../../../stripe-payment-modal";
import ManualCardModal from "../../../../modal/manual-card-modal";
import CashSignatureModal from "../../../cash-signature-modal";
import { orderCommonFunctions } from "../../order-common-function";
import {Button,Grid,Modal,Label,Form,Select,Dimmer,Loader} from "semantic-ui-react";
import {commonFunctions,connect,bindActionCreators,actions,env,Notifications,} from "../../../../../../functional/global-import";
class DepositPaymentType extends Component {
  constructor(props) {
               
    super(props);
    this.state = {
      counterOptions: [],
      creditCardPayment: {
        orderDetailId: null,
        paymentTypeId: null,
        counterId: null,
        amountTypes: null,
        amountPaid: 0,
        isTipAdded: false,
        tipAmount: 0,
        stripeTransactionId: null,
        paymentMethod: "SinglePayment",
      },
      isLoading: true,
      terminal: null,
      discoveredReaders: null,
      connectResult: null,
      chooseReader: null,
      messageToShow: "",
      onlineReader: [],
      isManualCardModalOpen: false,
      splitSignatureModal: false,
      transactionDetails: null,
      newTotalAmountPaid: null,
      selectPaymentType: null,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount() {
      
    this.onGetStripeDetail();
    const defaultPaymentType =
      this.props.paymentSelect?.length > 0
        ? this.props.paymentSelect?.find((a) => a.key === "Stripe") ||
          this.props.paymentSelect?.find((a) => a.key === "EdgePay")
        : null;
    this.setState({
      selectPaymentType:
        defaultPaymentType === null ? null : defaultPaymentType.value,
    });
  }

  onGetStripeDetail = () => {
    this.props.actions.apiCall({
      urls: ["GETSTRIPEDETAIL"],
      method: "GET",
      data: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        BusinessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
      },
      onSuccess: (response) => {
        this.setState({ stripelocationDetails: response });
        this.onLoadStripeTirminal();
        this.getCounterDetails();
        return response;
      },
    });
  };

  getCounterDetails = () => {
    const { creditCardPayment } = this.state;
    this.props.actions.apiCall({
      urls: ["GETCOUNTERNAME"],
      method: "GET",
      data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const { defaultUserDetails } = this.props;
        const getCounter = response.map((singleCounter) => {
          return {
            value: singleCounter.counterId,
            key: singleCounter.counterId,
            text: singleCounter.counterName,
          };
        });

        const defaultCounterId = defaultUserDetails.defaultCounterId;
        const defaultCounter =
          getCounter.length > 0
            ? orderCommonFunctions.onSelectingDefaultDetail(
                getCounter,
                defaultCounterId,
                "value"
              )
            : null;
        if (getCounter.length > 0) {
          // creditCardPayment["counterId"] = getCounter[0].value;
          creditCardPayment["counterId"] = defaultCounter;
        }

        creditCardPayment["amountPaid"] = this.props.amountSlected;

        this.setState({ creditCardPayment, counterOptions: getCounter });
      },
    });
  };

  // ----------------Stripe Code -----------------------------------------

  onLoadStripeTirminal = async () => {
    const StripeTerminal = await loadStripeTerminal();
    let token = await this.fetchConnectionToken();
    const onFetchConnectionToken = () => {
      return token;
    };

    var terminal = StripeTerminal.create({
      onFetchConnectionToken: onFetchConnectionToken,
      onUnexpectedReaderDisconnect: (e) => {},
    });
    this.setState({ terminal }, () => this.discoverReaderHandler());
  };

  // 2c. Disconnect from the reader, in case the user wants to switch readers.
  disconnectReader = async () => {
    const { terminal } = this.state;
    await terminal.disconnectReader();
  };

  onNextApiCall = (response) => {
    setTimeout(
      () =>
        this.setState(
          {
            creditCardPayment: {
              ...this.state.creditCardPayment,
              counterId: "",
              paymentMethod: "SinglePayment",
            },
            isStripeModal: false,
          },
          () => this.getOrderItemAndCloseModal(response)
        ),
      3000
    );
  };

  getOrderItemAndCloseModal = (response) => {
    this.props.actions.getOrderItems(
      this.props.orderState.currentOrder.orderId
    );
    this.onHoldPaymentCardsuccess(response);
  };

  onHoldPaymentCardsuccess = (transactionDetails) => {
    let newTotalAmountPaid = this.props.depositAmount;
    this.setState({
      transactionDetails: transactionDetails,
      splitSignatureModal: !this.state.splitSignatureModal,
      newTotalAmountPaid,
    });
  };

  async fetchConnectionToken() {
    const bodyContent = {
      businessId: this.props.global.bussinessDetail.businessId,
      businessLocationId: parseInt(
        this.props.global.locationSelected.BusinessLocationId
      ),
    };
    const queryString = JSON.stringify(bodyContent);
    return fetch(`${env.API_URL}/Payment/ConnectionToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: queryString,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data[0].secret;
      });
  }

  discoverReaderHandler = async () => {
    this.setState({ isLoading: true });
    const { terminal } = this.state;
    const { stripelocationDetails } = this.state;

    if (terminal === null || stripelocationDetails === null) {
      return;
    }

    var config = {
      simulated: false,
      location: stripelocationDetails[0].stripeLocationId,
    };

    let terminalData = await terminal
      .discoverReaders(config)
      .then(function (discoverResult) {
        if (discoverResult.error) {
        } else if (discoverResult.discoveredReaders.length === 0) {
        } else {
          const discoveredReaders = discoverResult.discoveredReaders;

          return discoveredReaders;
        }
      });

    const onlineReaderDetail = [];
    terminalData &&
      terminalData.map((singlerreaderObj) => {
        if (singlerreaderObj.status === "online") {
          return onlineReaderDetail.push({
            value: singlerreaderObj.label,
            text: singlerreaderObj.label,
          });
        }
      });

    this.setState({
      discoveredReaders: terminalData,
      onlineReader: onlineReaderDetail,
      isLoading: false,
      chooseReader:
        onlineReaderDetail.length > 0 ? onlineReaderDetail[0].value : null,
    });
  };

  connectReaderHandler = async () => {
    const { discoveredReaders, terminal, chooseReader } = this.state;
    let selecReader = discoveredReaders.filter((selectedOne) => {
      return selectedOne.label === chooseReader;
    });
    const selectedReader = selecReader[0];

    let connectReaderData = await terminal
      .connectReader(selectedReader)
      .then(function (connectResult) {
        if (connectResult.error) {
        } else {
          console.log("Connected to reader: ", connectResult.reader.label);
        }
        return connectResult;
      });

    const newdetails = await connectReaderData;

    if (connectReaderData.error) {
      this.setState(
        {
          isStripeApiRunning: false,
          secondMessage: "Thank You !",
          messageToShow: "Make sure reader and POS is on same network (Wi-Fi)",
        },
        () => this.onCloseStripeModal()
      );
    } else {
      this.setState(
        {
          connectResult: connectReaderData,
          messageToShow: "Connected To Card-Reader collecting Payment !",
        },
        () => this.collectPayment()
      );
    }
  };

  onCloseStripeModal = () => {
    setTimeout(() => this.setState({ isStripeModal: false }), 3000);
  };

  fetchPaymentIntentClientSecretForHold(amount) {
    const { auth, global } = this.props;
    const { counterId, amountPaid } = this.state.creditCardPayment;
    const bodyContent = JSON.stringify({
      actionPerformedBy: auth.userDetail.emailId,
      amount: amount,
      businessId: global.bussinessDetail.businessId,
      businessLocationId: global.locationSelected.BusinessLocationId,
      orderId: this.props.orderState.currentOrder.orderId,
      IsTipAdded: false,
      TipAmount: null,
      counterId: null,
    });

    return fetch(`${env.API_URL}/Payment/HoldPaymentNotManuallyForPopulate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: bodyContent,
    })
      .then(function (response) {
        // return response.json();
        return response.status === 200 && response.json();
      })
      .then(function (data) {
        return data[0] && data[0].clientSecret;
        // return data && data.stripeobject && JSON.parse(data.stripeobject).client_secret

        // return data.client_secret;
      });
  }

  collectPayment = async () => {
    const { creditCardPayment, terminal } = this.state;
    const { depositAmount } = this.props;
    // const amount = creditCardPayment.totalPayment * 100
    let intentClientSecret = await this.fetchPaymentIntentClientSecretForHold(
      depositAmount
    );
    terminal.setSimulatorConfiguration({
      testPaymentMethod: "",
      testCardNumber: "",
    });

    const paymentMethodPromise = await terminal.collectPaymentMethod(
      intentClientSecret
    );

    const result = await paymentMethodPromise;
    if (result.error) {
      console.log("Collect payment method failed:", result.error.message);
    } else {
      // console.log("processPayment  = ", result.paymentIntent);
      const confirmResult = await terminal.processPayment(result.paymentIntent);
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      if (confirmResult.error) {
        console.log("error  = ", confirmResult);
        // alert(`Confirm failed: ${confirmResult.error.message}`);
      } else if (confirmResult.paymentIntent) {
        if (confirmResult.paymentIntent.status === "requires_capture") {
          // console.log("Payment HOLD! and payment id is  = ", confirmResult.paymentIntent.id);

          this.onHoldPaymentNotManually(confirmResult.paymentIntent.id);
          // this.setState({ creditCardPayment: { ...this.state.creditCardPayment, stripeTransactionId: confirmResult.paymentIntent.id }, isStripeApiRunning: false, messageToShow: "Payment Hold Successful!", secondMessage: "Thank You !", }, () => this.onNextApiCall())
          return confirmResult;
        }
      }
    }
  };

  onHoldPaymentNotManually = (paymentIntentId) => {
    const { orderState, auth, global, depositAmount } = this.props;
    let totalItemsSelectedDetails = [];
    let paymentDoneAlready =
      orderState.currentOrder.productItemPaid &&
      JSON.parse(orderState.currentOrder.productItemPaid);
    paymentDoneAlready = paymentDoneAlready === null ? [] : paymentDoneAlready;
    let selectedDepositDetails =
      orderCommonFunctions.getJsonOfSelectedDepositCheckboxes(orderState);
    totalItemsSelectedDetails = JSON.stringify([
      ...paymentDoneAlready,
      ...selectedDepositDetails,
    ]);

    this.props.actions.apiCall({
      urls: ["HOLDPAYMENTNOTMANUALLY"],
      method: "POST",
      data: {
        orderId: orderState.currentOrder.orderId,
        actionPerformedBy: auth.userDetail.emailId,
        amount: depositAmount,
        businessId: global.bussinessDetail.businessId,
        businessLocationId: global.locationSelected.BusinessLocationId,
        productItemPaid: totalItemsSelectedDetails,
        preAuthorizeItemDeposit: JSON.stringify(selectedDepositDetails),
        counterId: this.state.creditCardPayment.counterId,
        paymentIntentId: paymentIntentId,
        paymentMethodType: this.state.selectPaymentType,
      },
      onSuccess: (response) => {
        this.setState(
          {
            creditCardPayment: {
              ...this.state.creditCardPayment,
              stripeTransactionId: paymentIntentId,
            },
            isStripeApiRunning: false,
            messageToShow: "Payment Hold Successful!",
            secondMessage: "Thank You !",
          },
          () => this.onNextApiCall(response)
        );
        return response;
      },
      showNotification: true,
    });
  };

  capture = async (paymentIntentId) => {
    const { creditCardPayment } = this.state;
    creditCardPayment.id = paymentIntentId.paymentIntentId;
    creditCardPayment.businessId =
      this.props.allFunction.props.global.bussinessDetail.businessId;
    creditCardPayment.businessLocationId =
      this.props.global.locationSelected.BusinessLocationId;
    creditCardPayment.orderId = this.props.orderState.currentOrder.orderId;
    creditCardPayment.actionPerformedBy = this.props.auth.userDetail.emailId;
    creditCardPayment.paymentMethod = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "PaymentMethod",
      "Card"
    ).globalCodeId;
    // const data = JSON.stringify(creditCardPayment)

    return fetch(`${env.API_URL}/Payment/capture_payment_intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      body: JSON.stringify(creditCardPayment),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        // console.log('server.capture', data);
        return data;
      });
  };

  // ----------------------------------  stripe code ------------------

  onReaderChange = (e, { name, value }) => {
    this.setState({ chooseReader: value });
  };
  handleSubmit = (e) => {
    this.setState(
      {
        isStripeModal: true,
        isStripeApiRunning: true,
        messageToShow: "Connecting to Card-Reader ",
        secondMessage: "Please wait ....",
      },
      () => this.connectReaderHandler()
    );
  };

  manualCardModal = () => {
    const { selectPaymentType } = this.state;
    const stripepayment = selectPaymentType || null;
    if (stripepayment === null) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please select anyone payment option",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (this.state.counterOptions.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please add counter first.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState({
        isManualCardModalOpen: !this.state.isManualCardModalOpen,
      });
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      creditCardPayment: { ...this.state.creditCardPayment, [name]: value },
    });
  };
  handleChangeSelection = (e, { value }) => {
    this.setState({ selectPaymentType: value });
  };
  render() {
    const { depositAmount, api, paymentSelect } = this.props;
    const {
      creditCardPayment,
      counterOptions,
      onlineReader,
      chooseReader,
      isStripeModal,
      isStripeApiRunning,
      secondMessage,
      messageToShow,
      isManualCardModalOpen,
      splitSignatureModal,
      newTotalAmountPaid,
      transactionDetails,
      selectPaymentType,
    } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"small"}
      >
        <Modal.Content>
          <Grid>
            {api.isApiLoading && (
              <Dimmer active inverted>
                {" "}
                <Loader size="small">Loading</Loader>{" "}
              </Dimmer>
            )}

            <Grid.Column width={4} verticalAlign="middle">
              <span>
                {" "}
                <b>Counter</b>
              </span>
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              <Select
                placeholder="Select Cash Drawer"
                value={creditCardPayment.counterId}
                name="counterId"
                className="custom-select"
                options={counterOptions}
                fluid
                onChange={this.handleChange}
              />
              {this.validator.message(
                "Counter",
                creditCardPayment.counterId,
                "required"
              )}
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              {onlineReader.length > 0 ? (
                <Select
                  placeholder="Select Cash Drawer"
                  value={chooseReader}
                  name="counterId"
                  className="custom-select"
                  options={onlineReader}
                  fluid
                  onChange={this.onReaderChange}
                />
              ) : (
                <div className="orange-color">No reader is online </div>
              )}
              {this.validator.message("chooseReader", chooseReader, "required")}
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <span> Select the Payment</span>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Select
                placeholder="Select the payment "
                selection
                type="select"
                value={selectPaymentType}
                options={paymentSelect}
                onChange={this.handleChangeSelection}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={6} verticalAlign="middle">
              <Link onClick={this.manualCardModal} className="deepviolet-color">
                Manual Card Entry
              </Link>
            </Grid.Column>

            {/*  */}
            <Grid.Column width={4} verticalAlign="middle">
              <Link className="orange-color">Amount to Charge</Link>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Input
                name="totalPayment"
                labelPosition="left"
                type="text"
                className="amountInput"
                placeholder="Amount"
                value={depositAmount?.toFixed(2)}
                disabled
              >
                <Label basic>$</Label>
                <input />
              </Form.Input>
            </Grid.Column>

            <Grid.Column width={16} textAlign="right">
              <Button className="blue-btn" onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button
                className="blue-btn"
                disabled={chooseReader === null ? true : false}
                onClick={this.handleSubmit}
              >
                Pre-Authorize
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>

        {splitSignatureModal && (
          <CashSignatureModal
            transactionDetails={transactionDetails}
            onSignSubmitModal={this.props.closeModal}
            splitAmount={newTotalAmountPaid}
            isModalOpen={splitSignatureModal}
            onClose={() => this.setState({ splitSignatureModal: false })}
            isPreAuthorize={true}
          />
        )}

        {isManualCardModalOpen && (
          <ManualCardModal
            selectPaymentType={selectPaymentType}
            paymentSelect={paymentSelect}
            holdpaymnetModal={this.props.closeModal}
            counterId={creditCardPayment.counterId}
            onSignSubmitModal={() => this.props.closeModal()}
            totalPayment={depositAmount}
            isModalOpen={isManualCardModalOpen}
            holdDeposit={true}
            onClose={() => this.setState({ isManualCardModalOpen: false })}
            amount={depositAmount}
          />
        )}

        {isStripeModal && (
          <StripePaymentModal
            isStripeApiRunning={isStripeApiRunning}
            secondMessage={secondMessage}
            messageToShow={messageToShow}
            isApiLoading={true}
            isModalOpen={isStripeModal}
            onClose={() => this.setState({ isStripeModal: false })}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositPaymentType);
