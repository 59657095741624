import React, { Component } from "react";
import { Grid, Dimmer, Loader, Image, Icon } from "semantic-ui-react";
import BusinessByTypeCard from "../../shared/components/organisms/business-by-type-card";
import {
  connect,
  bindActionCreators,
  actions,
  env,
} from "../../shared/functional/global-import";
import {
  AddCategoryModal,
  AddCategoryInventoryModal,
} from "../../shared/components";
import { withRouter } from "react-router-dom";
import InventoryBreadcrumb from "./breadcrumb";
import { emptyImg } from "../../shared/functional/global-image-import";

class BusinessByType extends Component {
  constructor(props) {
    super(props);
                                   

    this.state = {
      addcateStatus: false,
      addBusinessDetails: [],
      addCardStatus: false,
      addBusinessDetail: null,
    };
  }
  componentWillReceiveProps(newProps) {
    if (
      this.props.global?.locationSelected.value !==
      newProps.global?.locationSelected.value
    ) {
      this.getBusinessType(
        newProps.global?.locationSelected.BusinessLocationId
      );
    }
  }

  componentDidMount = () => {
    this.getBusinessType(this.props.global.locationSelected.BusinessLocationId);
    this.props.actions.storeInventoryDetail({});
  };

  getBusinessType = (locationSelected) => {
    this.setState({ addBusinessDetails: [] });
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYTYPE"],
      method: "GET",
      data: { BusinessLocationId: locationSelected },
      onSuccess: (response) => {
        if (response.length === 0) {
          this.setState({ responseStored: true });
        }
        this.setState({ addBusinessDetails: response });
      },
    });
  };

  AddCategoryModal = (addEdit, type, onSave, businessCategoryId) => {
    this.setState({
      openModal: false,
      addcateStatus: !this.state.addcateStatus,
      addOrEdit: addEdit,
      categoryOrSub: type,
      onSave: onSave,
      businessCategoryId: businessCategoryId,
    });
  };

  addCatergoryInventory = (addBusinessDetail) => {
    this.setState({
      addCardStatus: !this.state.addCardStatus,
      addBusinessDetail: addBusinessDetail,
    });
  };
  onBusinessClick = (addBusinessDetail) => {
    if (this.props.handleBusinessByTypeClick) {
      this.props.handleBusinessByTypeClick(addBusinessDetail);
    } else {
      if (
        window.location.pathname === `${env.PUBLIC_URL}/add-category/inventory`
      ) {
        // this.props.history.push(`${env.PUBLIC_URL}/inventory/category-view`)
        this.AddCategoryModal(
          "Add",
          "Category",
          true,
          addBusinessDetail.businessCategoryId
        );
      } else {
        this.props.history.push(`${env.PUBLIC_URL}/inventory/category-view`);
      }
    }
    const inventoryBreadcrumb = [
      {
        level: 0,
        breadcrumbName: addBusinessDetail.description,
        id: addBusinessDetail.businessCategoryId,
        type: "BusinessCategoryType",
        parentId: null,
        productId: 0,
      },
    ];
    this.props.actions.storeInventoryDetail({
      categoryId: null,
      businessCategoryId: addBusinessDetail.businessCategoryId,
      inventoryBreadcrumb,
      businessCategoryName: addBusinessDetail.businessCategoryName,
    });
  };

  render() {
    const {
      addBusinessDetails,
      addcateStatus,
      categoryOrSub,
      addOrEdit,
      onSave,
      responseStored,
      businessCategoryId,
      addCardStatus,
      addBusinessDetail,
    } = this.state;
    const { api, newOrder } = this.props;
    return (
      <>
        <Grid columns="equal">
          <InventoryBreadcrumb />
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          {responseStored && addBusinessDetails.length === 0 && (
            <Grid.Column width={16} className="categoryErrorOuter">
              <div className="categoryError">
                <h1>Please add inventory categories of the business</h1>
                <Image className="emptyError" src={emptyImg}></Image>{" "}
              </div>
            </Grid.Column>
          )}
          {addBusinessDetails.map((addBusinessDetail, index) => {
                  
            const masterCatergory = JSON.parse(addBusinessDetail.image)
            return (
              <Grid.Column textAlign="center" key={index}>
                <div className="byTypeBox">
                {newOrder ? (
                  <Icon
                    name="pencil alternate"
                    size="large"
                    className="deepviolet-color EditImage"
                    title="Edit"
                    onClick={() =>
                      this.addCatergoryInventory(addBusinessDetail)
                    }
                  />
                ) : null}
                <BusinessByTypeCard
                  businessCategoryName={addBusinessDetail.description}
                  imageUrl={masterCatergory[0].ImageFile}
                  wrapped
                  ui={false}
                  // as={Link}cmn-shad buisness-by-type
                  CategoryId={addBusinessDetail.businessCategoryId}
                  showRouteTo={
                    this.props.handleBusinessByTypeClick ? false : true
                  }
                  onBusinessClick={() =>
                    this.onBusinessClick(addBusinessDetail)
                  }
                  //routeTo={`${process.env.REACT_APP_PUBLIC_URL}/inventory/category-view`}
                ></BusinessByTypeCard>
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
        <AddCategoryModal
          openModal={addcateStatus}
          categoryOrSub={categoryOrSub}
          addOrEdit={addOrEdit}
          onSave={onSave}
          businessCategoryId={businessCategoryId}
          closeModal={this.AddCategoryModal}
        />
        {addCardStatus && (
          <AddCategoryInventoryModal
            openModal={addCardStatus}
            addBusinessDetail={addBusinessDetail}
            getBusinessType={this.getBusinessType}
            closeModal={this.addCatergoryInventory}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeInventoryDetail: bindActionCreators(
        actions.storeInventoryDetail,
        dispatch
      ),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessByType));
