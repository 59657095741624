import React, { Component, useRef, Fragment } from "react";
import {
  connect,
  bindActionCreators,
  customMessage,
  actions,
  env,
  Notifications,
  commonFunctions,
} from "../../../../functional/global-import";
import {
  Button,
  Modal,
  Divider,
  Grid,
  Dropdown,
  Card,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./new-order-modal.css";
import NewCustomer from "./customer/new-customer"; // add new customer in first step
import OrderInventoryType from "./inventory-type";
import DocumentType from "./documentation/index";
import PaymentBoxes from "./payment/index"; // payment different option's available
import PaymentTable from "./payment/all-transaction/index.jsx"; // this is all transaction table from new order dropdown
import PaymentStatusTable from "./order-grid/payment-status-table"; // payment box right side
import ItemDetailsGrid from "./order-grid/item-details-grid"; // added items details
import StatusTable from "./status/index";
import UndoModal from "./common/undo-modal";
import { OrderHistoryTable } from "../../../molecules"; // order history
import {
  createOrder,
  removeOrder,
  addOrderDetails,
} from "../../../../../store/actions/order.actions";
import { ExistingCustomer } from "../../index"; // existing customer in first step
import AppStepper from "../../../steper";
import TableCurrentOrder from "./order-grid/table-current-order";
import { withRouter } from "react-router";
import CommonDeleteUpdateModal from "../../../organisms/modal/common-delete-update-modal/index";
import CommonEmailUpdateModal from "../../../organisms/modal/common-email-update-modal";
import EmptyCartGrid from "./order-grid/empty-cart-grid";
import AddCommentModal from "../../../organisms/modal/add-comment-modal";
import moment from "moment";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import PrintableComponent from "./printableComponent/PrintableComponent";
import "./new-order-modal.css";
import { orderCommonFunctions } from "./order-common-function";
// import SeasonPass from "./inventory-type/season";

class NewOrderModal extends Component {
  child = React.createRef();

  constructor(props) {
    super();
    this.state = {
      open: false,
      activeStep: 0,
      commentStatus: false,
      orderCancelEmail: true,
      selectedBusinessCategory: {
        businessCategoryId: "",
        businessCategoryName: "",
      },

      deletecartStatusPaid: false,
      getOrderProductDetails: [],
      itemDetailsGridState: {
        checkAllItemDetails: false,
        orderDetailId: "",
      },
      orderDetailId: null,
      selectedItemId: null,
      deletecartStatus: false,
      onDepositDeleteClick: false,
      onOrderDepositDeleteClick: false,

      isConfirmationModalOpen: false,
      customerTypePanel: true,
      customerTablePanel: true,
      createCustomerPanel: false,
      paymentboxes: true,
      transactionTable: false,
      orderhistoryTable: false,
      ViewCustomerPanel: false,
      
      sLatest: false,
      flatcustomfee: false,

      rentalDatePanel: false,
      rentalCategoryPanel: false,
      rentalSubCategoryPanel: false,
      rentalSubCategoryItemTabPanel: false,
      rentalItemsPanel: false,
      rentalChooseDatesPanel: false,

      //Retail
      retailCategoryPanel: false,
      retailSubCategoryPanel: false,
      retailSubCategoryItemTabPanel: false,
      retailSelectItemsPanel: false,

      //Activity
      activityCategoryPanel: false,
      activitySubCategoryPanel: false,
      activitySubCategoryItemTabPanel: false,
      activityChooseDatesPanel: false,
      activitySelectTicketPanel: false,
      activityQuestionRequirementPanel: false,

      //Bundle
      bundleItemTabPanel: false,

      //Documentaion
      signaturePanel: false,

      // edit Customer details
      customer: "",
      onSaveNewCustomer: null,
      onSaveNewCustomerId: null,
      orderDetails: {},

      orderState: {
        orderStatus: "",
        orderId: "",
        createdTime: "",
      },
      //  for second step show item grids accordingly  and edit cart items params
      showItemGrid: false,
      hideRentalDate: false,
      customQuestionEdit: {
        customQuestionEdit: false,
      },
      rentalEdit: {
        rentalEdit: false,
      },
      seasonPassEdit: {
        seasonPassEdit: false,
      },
      retailEdit: {
        retailEdit: false,
      },
      bundleEdit: {
        bundleEdit: false,
      },
      activityEdit: {
        activityEdit: false,
      },
      loadingApi: false,
      showRentalTable: true,
    };
  }

  showCreateCusomerPanel = () => {
    this.setState({
      customerTypePanel: false,
      customerTablePanel: false,
      createCustomerPanel: true,
      searchValue: false,
    });
  };

  onSaveCustomer = (customerId, name) => {
    this.setState({
      onSaveNewCustomer: name,
      onSaveNewCustomerId: customerId,
      customerTypePanel: true,
      customerTablePanel: true,
      createCustomerPanel: false,
      ViewCustomerPanel: false,
      customer: "",
    });
  };
  showCustmerTablePanel = () => {
    this.setState({
      onSaveNewCustomer: null,
      onSaveNewCustomerId: null,
      customerTypePanel: true,
      customerTablePanel: true,
      createCustomerPanel: false,
      ViewCustomerPanel: false,
      customer: "",
    });
  };

  // Order history function
  showOrderHistoryTable = () => {
    this.setState({
      transactionTable: false,
      orderhistoryTable: true,
      paymentboxes: false,
    });
  };
  showEditCustomer = (customer) => {
    this.setState({
      customerTablePanel: false,
      customerTypePanel: false,
      searchValue: false,
      createCustomerPanel: true,
      customer: customer,
    });
  };

  searchChangeHandler = (e) => {
    // const { searchValue } = this.state;
    this.setState({ searchValue: e.target.value });
  };

  // step 1 customer radio btn fumction
  onRadioClick = (orderStatus) => {
    const { orderState } = this.props;
    let orderStatusdata = orderState.currentOrder.orderStatus;
    let orderId = orderState.currentOrder.orderId;
    let createdTime = orderState.currentOrder.createdTime;

    this.setState(
      {
        orderState: {
          ...this.state.orderState,
          orderStatus: orderStatusdata,
          orderId: orderId,
          createdTime: createdTime,
        },
      },
      () => this.onNext(1)
    );
  };

  // business by type
  handleBusinessByType = () => {
    const { selectedBusinessCategory, rentalEdit, customQuestionEdit } =
      this.state;
    return (
      <OrderInventoryType
        rentalEdit={rentalEdit}
        customQuestionEdit={customQuestionEdit}
        allFunction={this}
        selectedBusinessCategory={selectedBusinessCategory}
        handleBusinessByTypeClick={this.handleBusinessByTypeClick}
        closeModalRemoveOrder={this.closeModalRemoveOrder}
        next={this.onNext}
        handleSelectedBusinessCategoryEmpty={
          this.handleSelectedBusinessCategoryEmpty
        }
      />
    );
  };

  // function to set all business type to initial in step 2 (initial state of step 2 )
  handleSelectedBusinessCategoryEmpty = () => {
    this.setState({
      activityEdit: { activityEdit: false },
      rentalEdit: { rentalEdit: false },
      seasonPassEdit: { seasonPassEdit: false },
      retailEdit: { retailEdit: false },
      bundleEdit: { bundleEdit: false },
      customQuestionEdit: { customQuestionEdit: false },
      hideRentalDate: false,
      showItemGrid: false,
      selectedBusinessCategory: {
        businessCategoryId: "",
        businessCategoryName: "",
      },
    });
  };

  handleBusinessByTypeClick = (addBusinessDetail) => {
    this.setState({
      selectedBusinessCategory: {
        ...this.state.selectedBusinessCategory,
        businessCategoryId: addBusinessDetail.businessCategoryId,
        businessCategoryName: addBusinessDetail.businessCategoryName,
      },
    });
    this.props.actions.addOrderDetails(addBusinessDetail);
  };

  // Bundle inventory flow
  handleRedirect = () => {
    this.props.history.push({ pathname: `${env.PUBLIC_URL}/order` });
    this.closeModalRemoveOrder();
    if (this.props.handlegetOrderList) {
      this.props.handlegetOrderList();
    }
  };

  // Change stepper step
  changeStep = (stepNumber) => {
    const { orderState } = this.props;
    if (this.props.orderState.currentOrder.orderId !== null) {
      if (stepNumber === 0) {
        // this.props.actions.removeOrder();
        this.setState({ isConfirmationModalOpen: true });
      } else if (stepNumber === 1) {
        this.handleSelectedBusinessCategoryEmpty();
        this.setState({ activeStep: stepNumber });
      } else if (stepNumber === 2) {
        // for document get on newxt function calls
        this.onNext(2, true);
      } else if (stepNumber === 3) {
        if (orderState.currentOrder.pricings) {
          this.setState({
            activeStep: stepNumber,
            transactionTable: false,
            paymentboxes: true,
            orderhistoryTable: false,
          });
        } else {
          this.props.actions.showNotification(
            {
              title: "Error",
              message: "Please add Item to cart first !",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      } else {
        this.setState({ activeStep: stepNumber });
      }
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please choose customer first !",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  // Function of confirmation modal
  removeOrder = () => {
    this.onCancelOrderBtnClick()
  };
  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  renderConfirmationModal = () => {
    const { isConfirmationModalOpen } = this.state;
    return (
      <CommonDeleteUpdateModal
        onConfirm={this.removeOrder}
        confirmationButtonText="OK"
        header={customMessage.getExceptionMessage(
          "DELETE_ORDER_CONFIRATION_BUTTON_TEXT"
        )}
        isModalOpen={isConfirmationModalOpen}
        onClose={this.closeConfirmationModal}
        cancelButtonText="Cancel"
      />
    );
  };

  // cases component to render on left side of modal according to steper
  getStepContent = (stepIndex) => {
    const {
      itemDetailsGridState,
      createCustomerPanel,
      paymentboxes,
      transactionTable,
      orderhistoryTable,
      onSaveNewCustomer,
      onSaveNewCustomerId,
    } = this.state;
    const currentCustomerId =
      this.props.orderState.currentOrder.customerDetail.fullDetail.customerId;
    const orderID = this.props.orderState.currentOrder.orderId;
    switch (stepIndex) {
      case 0:
        return (
          // <SeasonPass />
          <>
            {createCustomerPanel && (
              <NewCustomer
                onClickButton={this.showCustmerTablePanel}
                onSaveCustomer={this.onSaveCustomer}
                customer={this.state.customer}
                onEditClickButton={this.showEditCustomer}
                onRadioClick={() => {
                  this.onNext(1);
                }}
                onOrderClick={() => {
                  this.changeStep(3);
                  this.showOrderHistoryTable();
                }}
              />
            )}
            {!createCustomerPanel && (
              <ExistingCustomer
                onSaveNewCustomer={onSaveNewCustomer}
                onSaveNewCustomerId={onSaveNewCustomerId}
                onClickButton={this.showCreateCusomerPanel}
                customerTypePanel={this.state.customerTypePanel}
                searchChangeHandler={this.searchChangeHandler}
                onEditClickButton={this.showEditCustomer}
                onRadioClick={() => {
                  this.onNext(1);
                }}
                onOrderClick={() => {
                  this.changeStep(3);
                  this.showOrderHistoryTable();
                }}
                customerAutoPopulate={this.props.customerPopulated}
                selectedCustomerId={this.props.selectedCustomerId}
                customerIdFromQuery={this.props.customerIdFromQuery}
                customerFirstNameQuary={this.props.customerFirstNameQuary}
                onAssignButton={false}
                closeModal={this.props.closeModal}
              />
            )}
            <Divider hidden />
          </>
        );

      case 1:
        return <>{this.handleBusinessByType()}</>;
      case 2:
        return (
          <>
            <DocumentType
              onPrevious={this.onPrevious}
              onNext={this.onNext}
              getOrderProductDetails={this.state.getOrderProductDetails}
              onRadioClick={() => {
                this.onNext(1);
              }}
              onClickButton={this.showCreateCusomerPanel}
              onEditClickButton={this.showEditCustomer}
              onSaveNewCustomerId={onSaveNewCustomerId}
            />
          </>
        );

      // case for payment boxes component to render
      case 3:
        return (
          <>
            {paymentboxes && itemDetailsGridState && (
              <PaymentBoxes
                itemDetailsGridState={itemDetailsGridState}
                handleRedirect={this.handleRedirect}
              />
            )}

            {transactionTable && <PaymentTable />}

            {orderhistoryTable && (
              <OrderHistoryTable
                currentCustomerId={currentCustomerId}
                orderId={orderID}
              />
            )}

            <Grid>
              <Grid.Column width={16} textAlign="right">
                {/* <Button className="blue-btn" onClick={() => this.onPrevious(2)}>Back</Button> */}
                <Button
                  className="blue-btn"
                  onClick={() => this.onNext(2, true)}
                >
                  Back
                </Button>
                <Button className="orange-btn" onClick={() => this.onNext(4)}>
                  Continue
                </Button>
              </Grid.Column>
            </Grid>
          </>
        );

      // case for the stattus of check in or check out section
      case 4:
        return (
          <StatusTable
            ref={this.child}
            onPrevious={this.onPrevious}
            statusModal={this.statusModal}
            handleRedirect={this.handleRedirect}
          />
        );
      default:
        return null;
    }
  };

  onDocumentStepGet = (addedItemToCart, isDocumentStepClick) => {
    if (addedItemToCart.length > 0) {
      const dataObj = { Products: JSON.stringify(addedItemToCart) };
      this.props.actions.apiCall({
        urls: ["GETORDERPRODUCTDOCUMENT"],
        method: "GET",
        data: dataObj,
        onSuccess: (response) => {
          if (response.length === 0) {
            if (isDocumentStepClick) {
              this.setState({ activeStep: 2 });
            } else {
              this.setState({ activeStep: 3 });
            }
          } else {
            this.setState({ activeStep: 2, getOrderProductDetails: response });
          }
          return response;
        },
      });
    } else if (isDocumentStepClick) {
      this.setState({ activeStep: 2 });
    } else {
      this.setState({ activeStep: 3 });
    }
  };

  // function for next  button click
  onNext = (step, isDocumentStepClick = false, newItemAddedDetail) => {
    if (step === 0) {
      this.props.actions.removeOrder();
    } else if (step === 2) {
      const { orderState } = this.props;
      const addedItemToCart = [];
      newItemAddedDetail && addedItemToCart.push(newItemAddedDetail);
      orderState.currentOrder.selectedItemsWithAmount.length > 0 &&
        orderState.currentOrder.selectedItemsWithAmount.map(
          (singleRentalObj) => {
            addedItemToCart.push({
              BusinessCategoryType: singleRentalObj.BusinessCategoryType,
              ProductId: singleRentalObj.ProductId,
            });
            return singleRentalObj;
          }
        );
      orderState.currentOrder.selectedActivityItems.length > 0 &&
        orderState.currentOrder.selectedActivityItems.map(
          (singleActivityObj) => {
            addedItemToCart.push({
              BusinessCategoryType: singleActivityObj.BusinessCategoryType,
              ProductId: singleActivityObj.ActivityId,
            });
            return singleActivityObj;
          }
        );
      this.onDocumentStepGet(addedItemToCart, isDocumentStepClick);
    } else {
      this.setState({
        customQuestionEdit: { customQuestionEdit: false },
        activeStep: step,
      });
    }
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
    if (step === 0) {
      this.props.actions.removeOrder();
    } else if (step === 1) {
      this.handleSelectedBusinessCategoryEmpty();
      this.setState({ showActive: true });
    }
  };

  statusModal = (selectedCheckInCheckOutList) => {
    this.setState({
      changeStatus: !this.state.changeStatus,
      selectedCheckInCheckOutList: selectedCheckInCheckOutList,
    });
  };
  undoModal = () => {
    this.setState({ undoStatus: !this.state.undoStatus });
  };

  //  New order dropdown on save order clicked
  onSavePrintAndEmail = () => {
    const { orderState, auth } = this.props;

    if (orderState.currentOrder.pricings !== null) {
      this.setState({ loadingApi: true });
      this.props.actions.apiCall({
        urls: ["ORDERSUMMARYPRINTANDEMAIL"],
        method: "GET",
        data: {
          applicationId: env.APPLICATION_ID,
          BusinessId: auth.userDetail.businessId,
          emailId: orderState.currentOrder.customerDetail.fullDetail.emailId,
          isViewOrPrint: false,
          orderId: orderState.currentOrder.orderId,
        },
        onSuccess: (response) => {
          return response;
        },
        onFinally: () => {
          this.setState({ loadingApi: false });
        },
        showNotification: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Your Cart is empty",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  onCancelOrdereEmail = () => {
    const { orderState, auth } = this.props;
    this.props.actions.apiCall({
      urls: ["UPDATEORDERCANCELLATION"],
      method: "PUT",
      data: {
        orderId: orderState.currentOrder.orderId,
        actionPerformedby: auth.userDetail.emailId,
        orderCancelEmail: this.state.orderCancelEmail,
      },
      onSuccess: (response) => {
        this.closeModalRemoveOrder();
        // this.onCancelOrderBtnClick()
        // this.props.actions.getOrderItems(orderState.currentOrder.orderId);
        // this.closeConfirmationModal()
      },
      showNotification: true,
    });
  };
  onConfirmEmail = () => {
    this.setState({ orderCancelEmail:false}, () => this.onCancelOrdereEmail())
  }
  onCancelOrder = () => {
    this.setState({ orderCancelEmail:true}, () => this.onCancelOrdereEmail())
  }
  
  onCloseOrderUpdate = () => {
    const { orderState, auth } = this.props;
    this.props.actions.apiCall({
      urls: ["UPDATEORDERCLOSESTATUS"],
      method: "PUT",
      data: {
        orderId: orderState.currentOrder.orderId,
        actionPerformedby: auth.userDetail.emailId,
      },
      onSuccess: (response) => {
        this.closeModalRemoveOrder();
      },
      showNotification: true,
    });
  };

  onCancelOrderBtnClick = () => {
    this.setState({ isCancelOrderBtnModal: !this.state.isCancelOrderBtnModal });
  };

  onCloseOrderBtnClick = () => {
    this.setState({ isCloseOrderBtnModal: !this.state.isCloseOrderBtnModal });
  };

  getAlltransactionDetails = (stepNumber) => {
    const { orderState } = this.props;
    if (orderState.currentOrder.pricings) {
      this.setState({
        activeStep: stepNumber,
        transactionTable: true,
        paymentboxes: false,
        orderhistoryTable: false,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please add Item to cart first !",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  getOrderHistoryDetail = (stepNumber) => {
    const { orderState } = this.props;
    if (orderState.currentOrder.pricings) {
      this.setState({
        activeStep: stepNumber,
        transactionTable: false,
        paymentboxes: false,
        orderhistoryTable: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please add Item to cart first !",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  renderModalHeader = () => {
    const { orderState } = this.props;
    const fullName =
      orderState.currentOrder.customerDetail.firstName +
      " " +
      (orderState.currentOrder.customerDetail.lastName === null
        ? " "
        : orderState.currentOrder.customerDetail.lastName);
    return (
      <Grid>
        <Grid.Column width={5}>
          <Dropdown
            text="New Order"
            className="item-list-dropdown new-order-header-list"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="user"
                text="Send Details"
                onClick={() => this.onSavePrintAndEmail()}
              />
              <Dropdown.Item
                onClick={this.onCancelOrderBtnClick}
                icon="ban"
                text="Cancel Order"
              />
              <Dropdown.Item
                icon="credit card"
                text="All Transactions"
                onClick={() => {
                  this.getAlltransactionDetails(3);
                }}
              />
              <Dropdown.Item
                icon="history"
                text="Order History"
                onClick={() => {
                  this.getOrderHistoryDetail(3);
                }}
              />
              <Dropdown.Item
                onClick={this.onCloseOrderBtnClick}
                icon="trash"
                text="Close Order"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
        {orderState.currentOrder && (
          <Grid.Column width={11}>
            <h2
              class="ui header"
              style={{ color: "#f54952", paddingLeft: "30px" }}
            >
              {fullName}
            </h2>
          </Grid.Column>
        )}
      </Grid>
    );
  };

  renderTable = () => {
    return (
      <TableCurrentOrder currentOrder={this.props.orderState.currentOrder} />
    );
  };

  closeModalRemoveOrder = () => {
    this.props.actions.removeOrder();
    this.props.closeModal();
    this.setState({
      activeStep: 0,
      selectedBusinessCategory: {
        businessCategoryId: "",
        businessCategoryName: "",
      },
    });
  };

  addCommentModal = () => {
    this.setState({ commentStatus: !this.state.commentStatus });
  };

  onDepositBtndeleteModalCLick = (item) => {
    const messagetoShowInModal = `Are you sure you want to delete deposit of "${item.SelectedProduct}" ?`;
    this.setState({
      onDepositDeleteClick: !this.state.onDepositDeleteClick,
      messageToShow: messagetoShowInModal,
      rentalDepositOrderDetailId: item.OrderDetailId,
    });
  };

  onOpenCancelationComponent = (newQuestiondetails) => {
    this.onNext(1);
    const addBusinessDetail = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      this.state.questionBusinessTypeName
    );
    this.setState({
      selectedBusinessCategory: {
        ...this.state.selectedBusinessCategory,
        businessCategoryId: addBusinessDetail.globalCodeId,
        businessCategoryName: addBusinessDetail.codeName,
      },
      hideRentalDate: true,
      showItemGrid: true,
      customQuestionEdit: {
        customQuestionEdit: true,
        businessCategoryId: addBusinessDetail.globalCodeId,
        productCustomQuestion: newQuestiondetails,
        questionOrderDetailId: this.state.questionOrderDetailId,
        questionProductId: this.state.questionProductId,
        questionBusinessTypeName: this.state.questionBusinessTypeName,
      },
    });
  };

  onActivityEditQuestionClick = (ItemObjDetails, questionBusinessTypeName) => {
    this.setState(
      {
        questionOrderDetailId: ItemObjDetails.OrderDetailId,
        questionProductId: ItemObjDetails.ActivityId,
        questionBusinessTypeName: questionBusinessTypeName,
      },
      () =>
        this.onNextEditQuestionClick({
          OrderId: ItemObjDetails.OrderId,
          OrderDetailId: ItemObjDetails.OrderDetailId,
          ProductId: ItemObjDetails.ActivityId,
        })
    );
  };

  onRetailEditQuestionClick = (ItemObjDetails, questionBusinessTypeName) => {
    this.setState(
      {
        questionOrderDetailId: ItemObjDetails.OrderDetailId,
        questionProductId: ItemObjDetails.RetailId,
        questionBusinessTypeName: questionBusinessTypeName,
      },
      () =>
        this.onNextEditQuestionClick({
          OrderId: ItemObjDetails.OrderId,
          OrderDetailId: ItemObjDetails.OrderDetailId,
          ProductId: ItemObjDetails.RetailId,
        })
    );
  };
  onSeasonPassEditQuestionClick = (
    ItemObjDetails,
    questionBusinessTypeName
  ) => {
    this.setState(
      {
        questionOrderDetailId: ItemObjDetails.OrderDetailId,
        questionProductId: ItemObjDetails.SeasonPassId,
        questionBusinessTypeName: questionBusinessTypeName,
      },
      () =>
        this.onNextEditQuestionClick({
          OrderId: ItemObjDetails.OrderId,
          OrderDetailId: ItemObjDetails.OrderDetailId,
          ProductId: ItemObjDetails.SeasonPassId,
        })
    );
  };

  onRentalEditQuestionClick = (ItemObjDetails, questionBusinessTypeName) => {
    this.setState(
      {
        questionOrderDetailId: ItemObjDetails.OrderDetailId,
        questionProductId: ItemObjDetails.ProductId,
        questionBusinessTypeName: questionBusinessTypeName,
      },
      () => this.onNextEditQuestionClick(ItemObjDetails)
    );
  };

  onNextEditQuestionClick = (ItemObjDetails) => {
    this.props.actions.apiCall({
      urls: ["GETCUSTOMQUESTIONRESPONSE"],
      method: "GET",
      data: {
        OrderId: ItemObjDetails.OrderId,
        OrderDetailId: ItemObjDetails.OrderDetailId,
        ProductId: ItemObjDetails.ProductId,
      },
      onSuccess: (response) => {
        const editQuestionsDetails =
          orderCommonFunctions.onEditCustomQuestions(response);
        this.setState({ productCustomQuestion: editQuestionsDetails }, () =>
          this.onOpenCancelationComponent(editQuestionsDetails)
        );
        return response;
      },
      showNotification: true,
    });
  };

  onOrderDepositBtndeleteModalCLick = () => {
    this.setState({
      onOrderDepositDeleteClick: !this.state.onOrderDepositDeleteClick,
    });
  };

  onClickOrderDepositDelete = () => {
    this.props.actions.apiCall({
      urls: ["DELETEORDERDEPOSIT"],
      method: "DELETE",
      data: {
        OrderId: this.props.orderState.currentOrder.orderId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      showNotification: true,
      onSuccess: (response) => {
        this.setState({ onOrderDepositDeleteClick: false });
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        return response;
      },
    });
  };

  onDepositDelete = () => {
    this.props.actions.apiCall({
      urls: ["DELETEITEMDEPOSIT"],
      method: "DELETE",
      data: {
        orderDetailId: this.state.rentalDepositOrderDetailId,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      showNotification: true,
      onSuccess: (response) => {
        this.setState({
          onDepositDeleteClick: false,
          messageToShow: "",
          rentalDepositOrderDetailId: "",
        });
        this.props.actions.getOrderItems(
          this.props.orderState.currentOrder.orderId
        );
        return response;
      },
    });
  };

  // /  edit icon click in right side of cart
  onEditIconClick = () => {
    const { orderState } = this.props;
    orderState.currentOrder.cartEditIcon =
      !orderState.currentOrder.cartEditIcon;
    this.props.actions.updateOrderState(orderState);
  };
  backToActivityTicketSelect = (activityItem) => {
    const { activeStep } = this.state;
    if (activeStep === 1) {
      this.toShowErrorMsg();
    } else {
      this.onNext(1);
      const addBusinessDetail = commonFunctions.getGlobalCodeDetails(
        this.props.global.codes,
        "BusinessCategoryType",
        "Activity"
      );
      this.setState({
        selectedBusinessCategory: {
          ...this.state.selectedBusinessCategory,
          businessCategoryId: addBusinessDetail.globalCodeId,
          businessCategoryName: addBusinessDetail.codeName,
        },
        hideRentalDate: true,
        showItemGrid: true,
        activityEdit: {
          businessCategoryId: addBusinessDetail.globalCodeId,
          activityItem: activityItem,
          activityEdit: true,
        },
      });
    }
  };

  backTobundleItemSelect = (bundleItem) => {
    this.onNext(1);
    const addBusinessDetail = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      "Bundle"
    );
    this.setState({
      selectedBusinessCategory: {
        ...this.state.selectedBusinessCategory,
        businessCategoryId: addBusinessDetail.globalCodeId,
        businessCategoryName: addBusinessDetail.codeName,
      },
      hideRentalDate: true,
      showItemGrid: true,
      bundleEdit: {
        businessCategoryId: addBusinessDetail.globalCodeId,
        bundleItem: bundleItem,
        bundleEdit: true,
      },
    });
  };
  backToSeasonPassSelect = (seasonPass) => {
    this.props.actions.showNotification(
      {
        title: "Error",
        message:
          "Season-Pass edit option is under progress, Please add new Season-Pass",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      },
      "error"
    );
  };

  backToretailItemSelect = (retailItem) => {
    this.onNext(1);
    const addBusinessDetail = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      "Retail"
    );
    this.setState({
      selectedBusinessCategory: {
        ...this.state.selectedBusinessCategory,
        businessCategoryId: addBusinessDetail.globalCodeId,
        businessCategoryName: addBusinessDetail.codeName,
      },
      hideRentalDate: true,
      showItemGrid: true,
      retailEdit: {
        businessCategoryId: addBusinessDetail.globalCodeId,
        retailItem: retailItem,
        retailEdit: true,
      },
    });
  };

  backTorentalitemSelect = (rentalItem) => {
    this.onNext(1);

    const rentalStartDate = moment(
      rentalItem.ProductItemDetails[0].StartDate,
      "MMM DD YYYY, hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    const rentalEndDate = moment(
      rentalItem.ProductItemDetails[0].EndDate,
      "MMM DD YYYY, hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const addBusinessDetail = commonFunctions.getGlobalCodeDetails(
      this.props.global.codes,
      "BusinessCategoryType",
      "Rental"
    );
    this.setState({
      selectedBusinessCategory: {
        ...this.state.selectedBusinessCategory,
        businessCategoryId: addBusinessDetail.globalCodeId,
        businessCategoryName: addBusinessDetail.codeName,
      },
      hideRentalDate: true,
      showItemGrid: true,
      rentalEdit: {
        businessCategoryId: addBusinessDetail.globalCodeId,
        rentalItem: rentalItem,
        ProductId: rentalItem.ProductId,
        StartDate: rentalStartDate,
        EndDate: rentalEndDate,
        rentalEdit: true,
      },
    });
    //    this.props.actions.addOrderDetails(addBusinessDetail);
  };

  onOrderDepositChange = (e, { checked }, selectedOrderDepositAmount) => {
    const { orderState } = this.props;
    if (checked) {
      let amountDetails = {
        amount: selectedOrderDepositAmount,
        id: orderState.currentOrder.orderId,
      };
      orderState.currentOrder.selectedDepositAmount.push(amountDetails);
    } else {
      let newSelectedDeposit =
        orderState.currentOrder.selectedDepositAmount.filter((depositObj) => {
          return depositObj.id !== orderState.currentOrder.orderId;
        });
      orderState.currentOrder.selectedDepositAmount = newSelectedDeposit;
    }
    orderState.currentOrder.checkAllItemDetails = false;
    orderState.currentOrder.orderDepositCheckbox = checked;
    this.props.actions.updateOrderState(orderState);
  };

  onHandleChangeOfOrderAddon = (e, { checked }, selectedAmount, addOnIndex) => {
    const { orderState } = this.props;
    let orderDetailAddonId =
      orderState.currentOrder.pricings.Addons[addOnIndex].OrderAddOnId;

    selectedAmount &&
      this.onCheckBoxClick(checked, selectedAmount, orderDetailAddonId);
    orderState.currentOrder.checkAllItemDetails = false;
    // orderState.currentOrder.orderAddonCheckbox = checked
    orderState.currentOrder.pricings.Addons[addOnIndex].orderAddonCheckbox =
      checked;
    this.props.actions.updateOrderState(orderState);
  };

  onHandleChangeOfDepositCheckbox = (
    e,
    { name, checked, businessType },
    selectedItemDetail,
    index,
    depositAmount
  ) => {
    const { orderState } = this.props;
    if (checked) {
      let amountDetails = {
        amount: depositAmount,
        id: selectedItemDetail.OrderDetailId,
      };
      orderState.currentOrder.selectedDepositAmount.push(amountDetails);
    } else {
      let newSelectedDeposit =
        orderState.currentOrder.selectedDepositAmount.filter((depositObj) => {
          return depositObj.id !== selectedItemDetail.OrderDetailId;
        });
      orderState.currentOrder.selectedDepositAmount = newSelectedDeposit;
    }
    orderState.currentOrder.checkAllItemDetails = false;
    orderState.currentOrder[businessType][index][name] = checked;
    this.props.actions.updateOrderState(orderState);
  };

  onHandleChangeOfAddOnCheckbox = (
    e,
    { name, checked, businessType, businessTypeAddon, businessTypeName },
    index,
    addOnIndex,
    clickedAmount
  ) => {
    const { orderState } = this.props;
    let orderDetailAddonId =
      orderState.currentOrder[businessType][index][businessTypeAddon][
        addOnIndex
      ].OrderDetailAddonId;

    clickedAmount &&
      this.onCheckBoxClick(checked, clickedAmount, orderDetailAddonId);
    !checked &&
      this.onAutoUnselectItem(businessType, index, checked, businessTypeName);
    orderState.currentOrder.checkAllItemDetails = false;
    orderState.currentOrder[businessType][index][businessTypeAddon][addOnIndex][
      name
    ] = checked;
    this.props.actions.updateOrderState(orderState);
  };

  onCheckBoxClick = (checked, checkedAmount, selectedId) => {
    const { orderState } = this.props;
    if (checked) {
      const amountDetails = {
        amount: checkedAmount,
        id: selectedId,
      };
      orderState.currentOrder.selectedAmountItem.push(amountDetails);
    } else {
      const newSelectedAmount =
        orderState.currentOrder.selectedAmountItem.filter((clickedObj) => {
          return clickedObj.id !== selectedId;
        });
      orderState.currentOrder.selectedAmountItem = newSelectedAmount;
    }
    this.props.actions.updateOrderState(orderState);
  };

  onAutoUnselectItem = (businessType, index, checked, businessTypeName) => {
    const { orderState } = this.props;
    orderState.currentOrder.checkAllItemDetails = false;
    orderState.currentOrder[businessType][index][businessTypeName] = checked;
    const businessAmount =
      orderState.currentOrder[businessType][index].ItemAmount;
    const itemDetailId =
      orderState.currentOrder[businessType][index].OrderDetailId;
    this.onCheckBoxClick(checked, businessAmount, itemDetailId);
    this.props.actions.updateOrderState(orderState);
  };

  // for auto select addon if parent is selected
  onAutoSelectAddon = (
    businessType,
    index,
    businessTypeAddon,
    addOnName,
    checked
  ) => {
    const { orderState } = this.props;

    orderState.currentOrder.checkAllItemDetails = false;
    orderState.currentOrder[businessType][index][businessTypeAddon] !== null &&
      orderState.currentOrder[businessType][index][businessTypeAddon].map(
        (singleAddOnObj) => {
          //
          !singleAddOnObj.paidAmount &&
            !singleAddOnObj[addOnName] &&
            this.onCheckBoxClick(
              checked,
              singleAddOnObj.TotalAmount,
              singleAddOnObj.OrderDetailAddonId
            );
          !singleAddOnObj.paidAmount &&
            !checked &&
            this.onCheckBoxClick(
              checked,
              singleAddOnObj.TotalAmount,
              singleAddOnObj.OrderDetailAddonId
            );
          singleAddOnObj[addOnName] = checked;
          return singleAddOnObj;
        }
      );
    this.props.actions.updateOrderState(orderState);
  };

  handleShowHideField = (businessArray, index) => {
    const { orderState } = this.props;

    orderState.currentOrder[businessArray][index].showIcon =
      !orderState.currentOrder[businessArray][index].showIcon;
    this.props.actions.updateOrderState(orderState);
  };

  // new function  checkbox change function in cart
  onHandleChangeOfCheckbox = (
    e,
    { name, checked, businessType, businessTypeAddon, addOnName },
    selectedItemDetail,
    index,
    clickedAmount
  ) => {
    clickedAmount !== undefined &&
      this.onCheckBoxClick(
        checked,
        clickedAmount,
        selectedItemDetail.OrderDetailId
      );
    const { orderState } = this.props;

    if (name === "checkAllItemDetails") {
      orderCommonFunctions.onAllCheckCheckboxClick(orderState, checked, this);
    } else {
      businessTypeAddon &&
        addOnName &&
        this.onAutoSelectAddon(
          businessType,
          index,
          businessTypeAddon,
          addOnName,
          checked
        );
      orderState.currentOrder.checkAllItemDetails = false;
      orderState.currentOrder[businessType][index][name] = checked;
    }

    this.onsaveOrderDetailId(selectedItemDetail);
    this.props.actions.updateOrderState(orderState);
  };

  onsaveOrderDetailId = (selectedItemDetail) => {
    let selectedOrderDetailId = null;
    let selectedItemId = null;
    if (selectedItemDetail) {
      selectedOrderDetailId = selectedItemDetail.OrderDetailId;
      selectedItemId = selectedItemDetail.ProductId;
    }

    this.setState({ orderDetailId: selectedOrderDetailId, selectedItemId });
  };

  //  not used now
  handleChangeItemDetailsGrid = (
    e,
    { name, value, checked },
    selectedItemDetail
  ) => {
    const { itemDetailsGridState } = this.state;
    const { orderState } = this.props;
    if (name === "checkAllItemDetails") {
    }
    itemDetailsGridState[name] = !itemDetailsGridState[name];
    let selectedOrderDetailId = null;
    let selectedItemId = null;
    if (itemDetailsGridState[name] && selectedItemDetail) {
      selectedOrderDetailId = selectedItemDetail.OrderDetailId;
      selectedItemId = selectedItemDetail.ProductId;
    }
    this.setState({
      itemDetailsGridState: { ...itemDetailsGridState },
      orderDetailId: selectedOrderDetailId,
      selectedItemId,
    });
  };

  /* ------------------- DeleteOrderProduct -------------------- */
  //  open delete modal in cart delete button

  DeleteCartModal = (productDetails) => {
    const { orderState } = this.props;

    if (
      orderState.currentOrder.selectedAmountItem.length === 0 &&
      orderState.currentOrder.selectedDepositAmount.length > 0
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message:
            "Please choose at least one Item / Add On to delete. Deposits cannot be deleted here, please click the icon next to the deposit amount.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (orderState.currentOrder.selectedAmountItem.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please choose atleast one Item/Addon to delete.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      const { deletecartStatus } = this.state;
      if (deletecartStatus) {
        this.setState({ deletecartStatus: !this.state.deletecartStatus });
      } else {
        const messagetoShowInModal = `Are you sure you want to delete item from cart?`;
        this.setState({
          deletecartStatus: !this.state.deletecartStatus,
          messageToShow: messagetoShowInModal,
        });
      }
    }
  };

  //   delete api on confirm button cart items delete
  DeleteOrderProduct = () => {
    const { orderState } = this.props;

    if (orderState.currentOrder.selectedAmountItem.length === 0) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Please choose atleast one Item/Addon to delete",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      let orderDetail =
        orderCommonFunctions.getJsonOfSelectedCheckboxes(orderState);
      this.props.actions.apiCall({
        urls: ["DELETEORDERDATA"],
        method: "POST",
        data: {
          orderId: this.props.orderState.currentOrder.orderId,
          orderDetails: orderDetail,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        showNotification: true,
        onSuccess: (response) => {
          if (response !== null) {
            this.setState({ deletecartStatus: !this.state.deletecartStatus });
            this.props.actions.getOrderItems(
              this.props.orderState.currentOrder.orderId
            );
            this.child.current.getOrderChecknInCheckOut();
            return response;
          }
        },
      });
    }
  };

  DeleteClosePaidModal = () => {
    this.setState({ deletecartStatusPaid: !this.state.deletecartStatusPaid });
  };

  DeleteConfirmPaidModal = () => {
    this.setState({
      transactionTable: true,
      paymentboxes: !this.state.paymentboxes,
    });
    this.DeleteClosePaidModal();
  };
  onPerformNextAction = () => {
    const { editData, displayData, newOrderActivityEdit, orderState } =
      this.props;
    if (editData) {
      if (newOrderActivityEdit) {
        const selectedItemDetail = {
          OrderDetailId: editData.activityDetails.orderDetailId,
          ProductId: editData.activityDetails.activityId,
        };

        this.onHandleChangeOfCheckbox(
          "e",
          {
            name: "activityItemCheckbox",
            checked: true,
            businessType: "selectedActivityItems",
            businessTypeAddon: "ActivityAddons",
            addOnName: "activityItemAddon",
          },
          selectedItemDetail,
          editData.activityDetails.index,
          editData.activityDetails.totalAmount
        );
        this.onEditIconClick();
        const details = orderState.currentOrder.selectedActivityItems.filter( (item) => {
            return item.ActivityId === editData.activityDetails.activityId && 
            item.OrderDetailId ===  editData.activityDetails.orderDetailId
             
          }
        );
        const detailsOfActivity = details && details.length > 0 && details[0];
        setTimeout(() => {
          this.backToActivityTicketSelect(detailsOfActivity);
        }, 1000);
      } else {
        this.setState({ activeStep: 3 });
      }
    } else if (displayData) {
      this.changeStep(2);
    }
  };

  componentDidMount() {
    const { editData, displayData } = this.props;
    if (editData) {
      this.getOrderItems(editData);
    }
    if (displayData) {
      this.getOrderItems(displayData);
    }
  }
  getOrderItems = (displayData) => {
    this.props.actions.apiCall({
      urls: ["GETORDERITEMS"],
      method: "GET",
      data: { OrderId: displayData.orderId },
      onSuccess: (response) => {
        this.props.actions.AddOrder(response[0]);
        this.onPerformNextAction();
      },
    });
  };
  render() {
    const {
      activeStep,
      messageToShow,
      undoStatus,
      deletecartStatusPaid,
      deletecartStatus,
      isConfirmationModalOpen,
      itemDetailsGridState,
      onDepositDeleteClick,
      isCancelOrderBtnModal,
      isCloseOrderBtnModal,
      loadingApi,
      onOrderDepositDeleteClick,
    } = this.state;
    const { orderState } = this.props;

    return (
      <Fragment>
        <Modal
          closeOnDimmerClick={false}
          open={this.props.openModal}
          closeIcon
          onClose={this.closeModalRemoveOrder}
          size={"large"}
          className="newOrderModal"
        >
          {loadingApi && (
            <Dimmer active inverted>
              {" "}
              <Loader size="small">Loading</Loader>{" "}
            </Dimmer>
          )}
          <Modal.Header>{this.renderModalHeader()}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Grid>
                <Grid.Column tablet="16" computer={11}>
                  <AppStepper
                    data={"Ditstek"}
                    activeStep={activeStep}
                    changeStep={this.changeStep}
                  />
                  <div>{this.getStepContent(activeStep)}</div>
                </Grid.Column>
                <Grid.Column tablet="16" computer={5}>
                  <PrintInvoice
                    stateRef={this}
                    renderTable={this.renderTable}
                    itemDetailsGridState={itemDetailsGridState}
                    handleChangeItemDetailsGrid={
                      this.handleChangeItemDetailsGrid
                    }
                  />
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <UndoModal openModal={undoStatus} closeModal={this.undoModal} />
        {isCancelOrderBtnModal && (
          <CommonEmailUpdateModal
            isModalOpen={isCancelOrderBtnModal}
            onClose={this.onCancelOrderBtnClick}
            header="Are you sure you want to cancel this order ?"
            cancelButtonText={"No"}
            confirmationButtonText={"Yes with email"}
            confirmationText={"Yes without email"}
            onConfirmEmail={this.onConfirmEmail}
            onConfirm={this.onCancelOrder}
          />
        )}

        {isCloseOrderBtnModal && (
          <CommonDeleteUpdateModal
            isModalOpen={isCloseOrderBtnModal}
            onClose={this.onCloseOrderBtnClick}
            header="Are you sure you want to close this order ?"
            subHeader="Once Closed the order can not be opened or edited. Only the Summary will be viewable."
            confirmationButtonText={"Yes"}
            cancelButtonText={"No"}
            onConfirm={this.onCloseOrderUpdate}
          />
        )}

        {deletecartStatus && (
          <CommonDeleteUpdateModal
            isModalOpen={deletecartStatus}
            onClose={this.DeleteCartModal}
            header={messageToShow}
            confirmationButtonText={"Yes"}
            cancelButtonText={"Cancel"}
            onConfirm={this.DeleteOrderProduct}
          />
        )}

        {onDepositDeleteClick && (
          <CommonDeleteUpdateModal
            isModalOpen={onDepositDeleteClick}
            onClose={() =>
              this.setState({
                onDepositDeleteClick: false,
                messageToShow: "",
                rentalDepositOrderDetailId: "",
              })
            }
            header={messageToShow}
            confirmationButtonText={"Yes"}
            cancelButtonText={"Cancel"}
            onConfirm={this.onDepositDelete}
          />
        )}

        {onOrderDepositDeleteClick && (
          <CommonDeleteUpdateModal
            isModalOpen={onOrderDepositDeleteClick}
            onClose={() => this.setState({ onOrderDepositDeleteClick: false })}
            header={`Are you sure you want to delete deposit of "Order" ?`}
            confirmationButtonText={"Yes"}
            cancelButtonText={"Cancel"}
            onConfirm={this.onClickOrderDepositDelete}
          />
        )}

        {orderState.currentOrder.orderId && (
          <AddCommentModal
            openModal={this.state.commentStatus}
            noteType={"Order"}
            noteRefrenceId={orderState.currentOrder.orderId}
            closeModal={this.addCommentModal}
          />
        )}

        {deletecartStatusPaid && (
          <CommonDeleteUpdateModal
            isModalOpen={deletecartStatusPaid}
            onClose={this.DeleteClosePaidModal}
            header={"This order is partially paid. View Transactions?"}
            confirmationButtonText={"Yes"}
            cancelButtonText={"Cancel"}
            onConfirm={this.DeleteConfirmPaidModal}
          />
        )}

        {isConfirmationModalOpen && this.renderConfirmationModal()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    newOrderDetail: state.newOrderDetail,
    orderState: state.orderState,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeCustomerDetail: bindActionCreators(
        actions.storeCustomerDetail,
        dispatch
      ),
      AddOrder: bindActionCreators(createOrder, dispatch),
      removeOrder: bindActionCreators(removeOrder, dispatch),
      addOrderDetails: bindActionCreators(addOrderDetails, dispatch),
      UpdateOrderPaymentStatus: bindActionCreators(
        actions.UpdateOrderPaymentStatus,
        dispatch
      ),
      getOrderItems: bindActionCreators(actions.getOrderItems, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      updateOrderState: bindActionCreators(actions.updateOrderState, dispatch),

      onPriceAndDepositChangeInCart: bindActionCreators(
        actions.onPriceAndDepositChangeInCart,
        dispatch
      ),
    },
  };
};

const PrintInvoice = (props) => {
  const componentRef = useRef();
  const componentRef2 = useRef();
  const printTypes = Object.freeze({
    PRINT_RECEIPT: "printReceipt",
    OPEN_DRAWER: "openDrawer",
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef2.current,
    pageStyle: `
    .receipt{
      display:block !important;
    }
    .openDrawer{
        display:none;
    }
    `,
  });

  const openDrawer = useReactToPrint({
    content: () => componentRef2.current,
    pageStyle: `
    .receipt{
      display:none !important;
    }
    .openDrawer{
        display:block !important;
    }
    `,
  });

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const os = getMobileOS();

  const isMobTab = os === "Android" || os === "iOS";

  var passprnt_uri;

  function createURI(printType) {
    passprnt_uri = "starpassprnt://v1/print/nopreview?";

    const printContent = `<html><head><style>
   
    .receipt{
        display:block;
    }
    .thankyou{
        margin-top: 160px;
      }
      .openDrawer{
        display:none;
    }
   .receipt table {
       width: 100%;
     }
     .receipt table thead {
       font-size: 25px;
       font-weight:bold;
       color: black;
     }
     .receipt table tbody tr td {
       font-size: 20px;
       font-weight:bold;
       color: black;
     }
     .receipt table tbody tr td:first-child {
       color: black;
       font-weight:bold;
       font-size: 20px;
     }
     .receipt table tbody tr td:last-child {
       text-align: right;
       color: black;
       font-weight:bold;
       font-size: 20px;
     }
     hr{
         color: black;
         border:1px solid black;

     }
     
 </style></head><body>${componentRef2.current.outerHTML}</body></html>`;

    passprnt_uri =
      passprnt_uri + "back=" + encodeURIComponent(window.location.href);

    passprnt_uri =
      passprnt_uri +
      "&html=" +
      encodeURIComponent(
        printType === printTypes.PRINT_RECEIPT
          ? printContent
          : `<html><head><style> 
        .openDrawer{
            display:flex;
            width:100%;
            justify-content:center;
            align-items:center;
            margin:0;
        }
        h1{
            margin:0;
            padding:0;
        }
        
        </style></head><body><div class='openDrawer'><h1>Drawer Opened ${moment(
            new Date()
          ).format("MMM DD, YYYY , hh:mm A")} </h1></div></body></html>`
      );

    var target = document.getElementById(
      printType === printTypes.PRINT_RECEIPT ? "print" : "drawer"
    );
    target.href = passprnt_uri;
  }

  const buttonContainerStyles = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  };

  return (
    <div>
      {isMobTab ? (
        <div style={buttonContainerStyles}>
          <a
            href=""
            onClick={() => createURI(printTypes.PRINT_RECEIPT)}
            id="print"
          >
            <i title="Print" class=" large print icon orangeColorPrinter"></i>
          </a>
          <a
            href="s"
            onClick={() => createURI(printTypes.OPEN_DRAWER)}
            id="drawer"
          >
            <i
              title="Open drawer using printer"
              class=" large money icon orangeColorPrinter"
            ></i>
          </a>
        </div>
      ) : (
        <div style={buttonContainerStyles}>
          <a onClick={handlePrint}>
            <i title="Print" class=" large print icon orangeColorPrinter"></i>
          </a>
          <a onClick={openDrawer}>
            <i
              title="Open drawer using printer"
              class=" large money icon orangeColorPrinter"
            ></i>
          </a>
        </div>
      )}

      <PaymentBox ref={{ componentRef, componentRef2 }} {...props} />
    </div>
  );
};

const PaymentBox = React.forwardRef(
  (
    {
      stateRef,
      renderTable,
      itemDetailsGridState,
      handleChangeItemDetailsGrid,
    },
    ref
  ) => {
    const orderState = useSelector((state) => state.orderState);

    return (
      <div id="printableArea">
        <Card fluid className="right-payment-box" ref={ref.componentRef}>
          <Card.Content>
            <Header as="h3"> Payment </Header>
            {/* {/ <Link onClick={this.undoModal}><span class="iconify" data-icon="typcn:arrow-back" data-inline="false"></span></Link> /} */}
            <Divider />
            {orderState.currentOrder.createdTime !== "" && renderTable()}

            {/* {/ *******  cart item's , deposit,  time, checked in  details component ********** /} */}
            {orderState.currentOrder.selectedItemsWithAmount && (
              <ItemDetailsGrid
                stateRef={stateRef}
                itemDetailsGridState={itemDetailsGridState}
                handleChange={handleChangeItemDetailsGrid}
                orderState={orderState}
              />
            )}
            {orderState.currentOrder.pricings !== null ? (
              <PaymentStatusTable orderState={orderState} />
            ) : (
              <EmptyCartGrid />
            )}
          </Card.Content>
        </Card>
        <div ref={ref.componentRef2}>
          <div className="receipt">
            <PrintableComponent orderState={orderState} />
          </div>
          <div className="openDrawer">
            <h4 className="drawerOpen">
              Drawer Opened{" "}
              {moment(new Date()).format("MMM DD, YYYY , hh:mm A")}
            </h4>
          </div>
        </div>
      </div>
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewOrderModal));