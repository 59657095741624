import React, { Component } from "react";
import { Grid, Form, Button, Checkbox, Icon } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../functional/global-import";
import { GlobalCodeSelect, PhoneNumberInput } from "../../../../../components";
import {
  createCustomer,
  createUpdateCustomer,
  loadCustomers,
} from "../../../../../../store/actions/customer.action";
import AddCommentModal from "../../add-comment-modal";
import CommonCropImage from "../../../../../components/atoms/common-crop-image";
import AddWavierModalList from "../../add-WaverModal-list";
import moment from "moment-timezone";
import { DateInput } from "semantic-ui-calendar-react";
import CommonCutomFieldDisplay from "../../../common-custom-field-display";
import CommonCutomFieldButton from "../../../common-custom-field-button";
class NewCustomer extends Component {
  get initialState() {
    return {
      customerForm: {
        businessId: this.props.global.bussinessDetail.businessId,
        customerId: "",
        businessLocationId:
          this.props.global.locationSelected.BusinessLocationId,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailId: "",
        address: "",
        city: "",
        image: [],
        stateId: "",
        zipCode: "",
        IsImageUpdated: false,
        Minor: false,
        Dob: "",
        CustomField: [],
        CustomFieldDisplay: [],
        seasonPassId: "",
        isSeason: false,
        flagProfile: false,
      },
      dataToGetAllCustomers: {
        BusinessId: this.props.global.bussinessDetail.businessId,
        CustomerId: -1,
        PageNo: 1,
        PageSize: 100,
        SearchValue: "",
      },
      fileList: [],
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      commentStatus: false,
      screenShot: "",
      globalState: [],
      updated: true,
      comment: true,
      customerImageId: "",
      firstNameIndivisualUse: this.props.customer?.firstName,
      newCustomerModalOpen: false,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.validatorCustomfield = commonFunctions.initializeSimpleValidator();
    this.validatorGeneral = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount = () => {
    {
      this.state.customerId > 0 && this.editCustomerForm();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.customer &&
      props.customer &&
      props.customer.customerId !== state.customerForm.customerId
    ) {
      const imageData = JSON?.parse(props?.customer.image);
      const imageDetail = {
        uid: "2",
        status: "done",
        url:
          imageData === null
            ? commonFunctions.concatenateImageWithAPIUrl(null)
            : commonFunctions.concatenateImageWithAPIUrl(
                imageData[0].ImageFile
              ),
      };
      return {
        fileList: [imageDetail],
        imageData,
        customerImageId: props.customer.customerId,
        ImageId: imageData === null ? null : imageData[0].ImageId,
        customerForm: {
          ...state.customerForm,
          flagProfile: props.customer.flagProfile,
          firstName: props.customer.firstName,
          lastName: props.customer.lastName,
          phoneNumber: props.customer.phoneNumber,
          stateId: props.customer.stateId,
          emailId: props.customer.emailId,
          city: props.customer.city,
          zipCode: props.customer.zipCode,
          address: props.customer.address,
          customerId: props.customer.customerId,
          Minor: props.customer.minor,
          CustomFieldDisplay:
            props.customer.customField === null
              ? []
              : JSON.parse(props.customer.customField),
          seasonPassId: props.customer.seasonPassId,
          isSeason: props.customer.isSeason,
          Dob:
            props.customer.dob == null
              ? null
              : commonFunctions.formatDate(props.customer.dob),
          image: [
            commonFunctions.concatenateImageWithAPIUrl(props.customer.image),
          ],
        },
        updated: false,
      };
    }
    return null;
  }
  commentModal = () => {
    this.setState({ commentStatus: !this.state.commentStatus });
  };
  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var customerForm = commonFunctions.onHandleChange(
      e,
      {
        name,
        value,
        type,
        checked,
        data,
      },
      this.state.customerForm
    );
    this.setState({ customerForm });
  };
  // This function is used to set Form Submit with POST api
  onHandleSubmit = (e) => {
    const { dispatch, isNewOrderGuestlist } = this.props;
    const { customerForm, dataToGetAllCustomers } = this.state;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      // if (isNewOrderGuestlist && isFormValid) {
      //   customerForm
      // }
      dispatch(createCustomer(customerForm, dataToGetAllCustomers, this));
    } else {
      return false;
    }
  };
  onCustomerCreation = (data) => {
    const firstName = this.state.customerForm.firstName;
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
    this.props.onSaveCustomer(data?.keyId, firstName);
  };
  // This function is used to set Form Submit with POST api
  updateCustomer = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    const { dispatch } = this.props;
    const { customerForm, dataToGetAllCustomers } = this.state;
    const CustomFields =
      customerForm.CustomFieldDisplay == null
        ? null
        : JSON.stringify(customerForm?.CustomFieldDisplay);
    var formdata = commonFunctions.getFormData({
      CustomerId: customerForm.customerId,
      businessLocationId: this.props.global.locationSelected.BusinessLocationId,
      businessId: customerForm.businessId,
      firstName: customerForm.firstName,
      lastName: customerForm.lastName,
      phoneNumber: customerForm.phoneNumber,
      image: customerForm.image,
      stateId: customerForm.stateId,
      emailId: customerForm.emailId,
      city: customerForm.city,
      zipCode: customerForm.zipCode,
      address: customerForm.address,
      actionPerformedBy: this.props.auth.userDetail.emailId,
      IsImageUpdated: customerForm.IsImageUpdated,
      Minor: customerForm.Minor,
      seasonPassId: customerForm.seasonPassId,
      isSeason: customerForm.isSeason,
      Dob: customerForm.Dob == null ? null : customerForm.Dob,
      CustomField: CustomFields,
      flagProfile: customerForm.flagProfile,
    });
    if (isFormValid) {
      dispatch(createUpdateCustomer(formdata, dataToGetAllCustomers, this));
    } else {
      return false;
    }
  };
  newCustomerModal = () => {
    this.setState({
      newCustomerModalOpen: !this.state.newCustomerModalOpen,
    });
  };
  onBackCustomerCreation = (key) => {
    const { dataToGetAllCustomers } = this.state;
    const firstName = this.state.firstNameIndivisualUse;
    const { dispatch } = this.props;
    this.setState(this.initialState);
    this.validator = commonFunctions.initializeSimpleValidator();
    this.props.onSaveCustomer(key, firstName);
    dispatch(loadCustomers(dataToGetAllCustomers, this));
  };

  onClickUpdateBackButton = () => {
    var key = this.state.customerForm.customerId;
    this.onBackCustomerCreation(key);
  };

  // This function is used to set Image
  onImageChange = (imageList) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        image: imageList,
        IsImageUpdated: true,
      },
    }));
  };
  handleDateChange = (event, { value }) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        Dob: value,
      },
    }));
  };

  addInputField = () => {
    const fields = { discription: "" };
    this.setState({
      customerForm: {
        ...this.state.customerForm,
        CustomInputFields: [
          ...this.state.customerForm.CustomInputFields,
          fields,
        ],
      },
    });
  };
  removeInputFields = (index) => {
    const rows = this.state.customerForm.CustomInputFields;
    rows.splice(index, 1);
    this.setState({
      customerForm: {
        ...this.state.customerForm,
        CustomInputFields: rows,
      },
    });
  };
  inputHandleChange = (index, evnt) => {
    const { value } = evnt.target;
    const list = [...this.state.customerForm.CustomInputFields];
    list[index][evnt.target.name] = value;
    this.setState(() => list);
  };
  videoConstraints = {
    width: 10,
    height: 20,
    facingMode: "user",
  };

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    let screenShot = this.webcam.getScreenshot();
    this.setState({ screenShot: screenShot });
  };

  openNotesModal = () => {
    this.setState({ commentStatus: !this.state.commentStatus });
  };

  addCommentModalFun = () => {
    const { customerForm } = this.state;
    if (customerForm && customerForm.customerId === "") {
      this.props.actions.showNotification(
        {
          title: "Error",
          message: "Customer did not exist yet Create customer first",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      this.setState({ commentStatus: !this.state.commentStatus });
    }
  };
  handleFlagChange = (event, { value }) => {
    this.setState(() => ({
      customerForm: {
        ...this.state.customerForm,
        flagProfile: !this.state.customerForm.flagProfile,
      },
    }));
  };

  render() {
    const {
      commentStatus,
      customerForm,
      newCustomerModalOpen } = this.state;
    const { api } = this.props;
    const parsedData = customerForm?.seasonPassId && JSON?.parse(customerForm?.seasonPassId);
    return (
      <>
        <Grid>
          <Grid.Column width={5} className="controlField">
            <div className="flex">
              <CommonCropImage
                ImageType={"CustomerImage"}
                fullState={this.state}
                cropId={this.state.customerImageId}
                StateRef={this}
                isDefaultImage={false}
                stateForm={this.state.customerForm}
              />
              <div className="right-buttons">
                <Button icon onClick={this.newCustomerModal}>
                  <Icon
                    name="file alternate outline"
                    size="big"
                    className="green-color"
                  />
                </Button>
                <Button
                  icon
                  floated="right"
                  onClick={this.addCommentModalFun}
                  className="AddNotes"
                >
                  <span
                    class="iconify maroon-color"
                    data-icon="mdi:comment-plus"
                    data-inline="false"
                    data-flip="horizontal"
                  ></span>
                </Button>
              </div>
            </div>
            <p>&nbsp;</p>
            <div className="flex-align-center">
              <Checkbox
                label={
                  <label>
                    {" "}
                    <b>Minor</b>{" "}
                  </label>
                }
                className="custome-checkbox"
                data="Minor"
                checked={customerForm.Minor}
                onChange={this.onHandleChange}
              />
              <Icon
                className="flag-icon"
                size="big"
                name={customerForm.flagProfile ? "flag" : "flag outline"}
                style={
                  customerForm.flagProfile ? { color: "red" } : { color: "" }
                }
                value={customerForm.flagProfile}
                onClick={this.handleFlagChange}
              />
            </div>
            <p>&nbsp;</p>
            <DateInput
              dateFormat={"MM/DD/YYYY"}
              name="DOB"
              closable
              // disabled={customerForm.Dob ? true : false}
              disable={() => false}
              iconPosition="right"
              placeholder="MM/DD/YYYY"
              value={customerForm.Dob}
              onChange={this.handleDateChange}
              className="bordered-input"
            />
            <p>&nbsp;</p>
            <Grid.Column width={16}>
              <CommonCutomFieldButton
                fullState={this.state}
                allFunction={this}
                stateForm="customerForm"
                variableName="CustomFieldDisplay"
              />
              <div className="spacer" />
            </Grid.Column>
            <p>&nbsp;</p>
            <Checkbox
              label={
                <label>
                  {" "}
                  <b>Passes Account</b>{" "}
                </label>
              }
              className="custome-paid-checkbox "
              data="isSeason"
              checked={customerForm.isSeason}
              onChange={this.onHandleChange}
              disabled={true}
            />
            <p>&nbsp;</p>
            <div
              className={`scrollable-container passesAccount ${
                parsedData && parsedData.length > 5 ? "scrollable" : ""}`}>
              {parsedData && parsedData.length > 0 ?
              
               (parsedData.map((item, index) => (
                  <span>
                    <b style={{ color: "#0b0a0a" }}>{item.ItemName}</b> -{" "}
                    {item.MemberCode}
                  </span>
                ))
              ) : (
                <div className="orange-color">No Member code assigned</div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column width={11}>
            <Form>
              <Grid>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="First Name"
                    className="bordered-input"
                    value={customerForm.firstName}
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "firstName",
                      customerForm.firstName,
                      "required|alpha space|firstName"
                    )}
                    name="txtfirstName"
                    data="firstName"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="Last Name"
                    className="bordered-input"
                    value={customerForm.lastName}
                    name="txtlastName"
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "lastName",
                      customerForm.lastName,
                      "required|alpha"
                    )}
                    data="lastName"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <PhoneNumberInput
                    value={customerForm.phoneNumber}
                    // error={this.validator.message(
                    //     "phoneNumber",
                    //     customerForm.phoneNumber,
                    //     "required|phone"
                    // )}
                    onChange={(value, country, e, formattedValue) =>
                      this.onHandleChange(e, {
                        name: "phoneNumber",
                        value: formattedValue,
                        type: "phoneNumber",
                        data: "phoneNumber",
                      })
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="Email Address"
                    className="bordered-input"
                    value={customerForm.emailId}
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "emailId",
                      customerForm.emailId,
                      "email"
                    )}
                    data="emailId"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="Address"
                    className="bordered-input"
                    value={customerForm.address}
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "address",
                      customerForm.address,
                      ""
                    )}
                    data="address"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="City & Country (If International)"
                    className="bordered-input"
                    value={customerForm.city}
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "city",
                      customerForm.city,
                      ""
                    )}
                    data="city"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <GlobalCodeSelect
                    className="custom-select bordered-input"
                    placeholder="Select State"
                    name="ddpState"
                    data="stateId"
                    value={customerForm.stateId}
                    onChange={this.onHandleChange}
                    categoryType="State"
                    error={this.validator.message(
                      "state",
                      customerForm.stateId,
                      ""
                    )}
                    isRounded={true}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="Zip Code"
                    className="bordered-input"
                    value={customerForm.zipCode}
                    onChange={this.onHandleChange}
                    error={this.validator.message(
                      "zipCode",
                      customerForm.zipCode,
                      "integer|min:5|max:6"
                    )}
                    data="zipCode"
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Grid>
                    {/* <Grid.Column width={8}> */}
                    {/* <div style={{ position: "relative" }}> */}

                    <CommonCutomFieldDisplay
                      fullState={this.state}
                      allFunction={this}
                      stateForm="customerForm"
                      variableName="CustomFieldDisplay"
                    />
                    {/* </div> */}
                    {/* </Grid.Column> */}
                  </Grid>
                </Grid.Column>
                <Grid.Column width={16} textAlign="right">
                  {this.props.customer ? (
                    <Button
                      className="blue-btn"
                      onClick={this.onClickUpdateBackButton}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      className="blue-btn"
                      onClick={this.props.onClickButton}
                    >
                      Back
                    </Button>
                  )}

                  {this.props.customer ? (
                    <Button
                      className="orange-btn"
                      onClick={this.updateCustomer}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      loading={api.isApiLoading}
                      className="orange-btn"
                      onClick={this.onHandleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid>

        {newCustomerModalOpen && (
          <AddWavierModalList
            allFunction={this}
            businessId={this.props.global.bussinessDetail.businessId}
            businessLocationId={
              this.props.global.locationSelected.BusinessLocationId
            }
            Guestcustomerid={customerForm.customerId}
            openModal={newCustomerModalOpen}
            closeModal={this.newCustomerModal}
          />
        )}
        <AddCommentModal
          openModal={commentStatus}
          noteType={"Customer"}
          noteRefrenceId={customerForm && customerForm.customerId}
          closeModal={this.addCommentModalFun}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    customerState: state.customerState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);
