import React, { Component } from "react";
import { Upload, Modal } from "antd";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  env,
  Notifications,
} from "../../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../components";
import ImgCrop from "antd-img-crop";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class CommonCropImage extends Component {
  get initialState() {
    return {
      paramas: {
        Authorization: "Bearer " + this.props.auth.loggedIn.token,
      },
      updateImageObj: {
        Id:"",
        ImageType: this.props.ImageType,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      previewVisible: false,
      previewTitle: "",
      previewImage: "",
      statusOfImage: "done",
      deleteStatus: false,
    };
  }

  componentDidUpdate() {
    const { addBusinessDetail, cropId } = this.props;        
    const imageId = this.props.isDefaultImage
    ? addBusinessDetail.businessLocationCategoryId
     : cropId;
     if (this.state.updateImageObj.Id !== imageId) {
      this.setState({
        updateImageObj: {
          ...this.state.updateImageObj,
          Id: imageId,
        },
      });
    }
  }

  onChange = ({ fileList: newFileList }) => {
    const { StateRef, stateForm } = this.props;     
        if (
      newFileList &&
      newFileList[0] &&
      newFileList[0].name.match(/\.(jpg|png)$/)
    ) {
      newFileList &&
        newFileList[0] &&
        newFileList[0].status === "uploading" &&
        StateRef.setState({ fileList: newFileList });
      newFileList &&
        newFileList[0] &&
        newFileList[0].status === "done" &&
        StateRef.setState({ fileList: newFileList });
      if (newFileList[0] && newFileList[0].status === "done") {
        stateForm.ImageId = newFileList[0].response.keyId;
        StateRef.setState({ ...stateForm });
      }
      if (newFileList[0] && newFileList[0].status === "error") {
        StateRef.setState({ fileList: [] });
      }
    } else if (
      newFileList &&
      newFileList[0] &&
      newFileList[0].name.match(/\.(jpg|png)$/) === null
    ) {
      this.props.actions.showNotification(
        {
          title: "Error",
          message:
            "Please choose a PNG or JPG file only. Images must be smaller than 5MB.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });
  onDeleteModal = () => {
    const { fullState, StateRef } = this.props;                  
    if (fullState.imageData) {
      const message = "Do you want to delete this image ?";
      this.setState({
        deleteStatus: true,
        buttonName: "Delete",
        messageToShow: `${message}`,
      });
    } else if (fullState.imageData === null) {
      StateRef.setState({ fileList: [] });
    }
    else{
      const message = "Do you want to delete this image ?";
      this.setState({
        deleteStatus: true,
        buttonName: "Delete",
        messageToShow: `${message}`,
      });

    }
  };

  closeModal = () => {
    this.setState({ deleteStatus: !this.state.deleteStatus });
  };
  onRemoveImage = () => { 
    const { fullState } = this.props;
    if (fullState.fileList !== null) {
      this.props.actions.apiCall({
        urls: ["DELETECROPIMAGE"],
        method: "DELETE",
        data: {
          ImageId: fullState.ImageId,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: (response) => {
          this.closeModal();
          this.props.StateRef.setState({ fileList: [] });  
        },
        showNotification: true,
        isFormData: true,
      });
    }
  };
  onRemoveMaterImage = () => {
    const { fullState, businessLocationId, businessCategoryId,addBusinessDetail } = this.props;
    const defaultImageId = this.props.isDefaultImage ? addBusinessDetail.businessLocationCategoryId:businessCategoryId
    if (fullState.fileList !== null) {
      this.props.actions.apiCall({
        urls: ["DELETEMASTERCATEGORYIMAGE"],
        method: "DELETE",
        data: {
          businessLocationId: businessLocationId,
          businessCategoryId: defaultImageId,
          actionPerformedBy: this.props.auth.userDetail.emailId,
        },
        onSuccess: (response) => {
          this.closeModal();
          this.props.StateRef.setState({
            fileList: [],
            imageData: [],
            image: [],
          });
          
        },
        showNotification: true,
        isFormData: true,
      });
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  render() {
    const {
      previewVisible,
      deleteStatus,
      messageToShow,
      previewTitle,
      buttonName,
      previewImage,
      updateImageObj,
      paramas,
    } = this.state;
    const { fullState, masterCatergoryImage } = this.props;
             
    return (
      <React.Fragment>
        <div>
          <ImgCrop rotate>
            <Upload
              action={`${env.API_URL}/CropImage/UpdateBusinessTest`}
              data={updateImageObj}
              listType="picture-card"
              fileList={fullState.fileList}
              onChange={this.onChange}
              onPreview={this.handlePreview}
              headers={paramas}
              onRemove={this.onDeleteModal}
            >
              {fullState.fileList.length < 1 && "+ Upload"}
            </Upload>
          </ImgCrop>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>

        <CommonDeleteUpdateModal
          onConfirm={
            masterCatergoryImage === undefined
              ? this.onRemoveImage
              : this.onRemoveMaterImage
          }
          onClose={this.closeModal}
          confirmationButtonText={buttonName}
          header={messageToShow}
          isModalOpen={deleteStatus}
          cancelButtonText={"Cancel"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    auth: state.auth,
    global: state.global,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommonCropImage)
);
