import React, { Component } from "react";
import { Select, Icon, List, Grid, Dropdown, Container, Checkbox, Button, Search, Image } from "semantic-ui-react";
import { Link, env, connect, commonFunctions, bindActionCreators, actions, withRouter, Notifications } from "../../../functional/global-import";
import {
  NewOrderModal, AddItemModal, AddActivityModal,
  NewMemberModal, AddCategoryModal, AddSeasonPassModal
}
  from "../../../../shared/components";
import CommonDeleteUpdateModal from "../modal/common-delete-update-modal";

const trigger = (
  <span>
    <span className="iconify" data-icon="akar-icons:circle-plus" data-inline="false"></span>
  </span>
)
class Header extends Component {
  SearchRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      itemStatus: false,
      itemStatuss: false,
      orderStatus: false,
      memberStatus: false,
      activityStatus: false,
      bundleStatus: false,
      seasonPassStatus: false,
      addcateStatus: false,
      locationOption: [],
      selectedLocation: "",
      SearchValue: "",
      globalSearchData: [],
      showResult: false,
      test: [],
      homePageCheck: false,
      isOpenDiscardChangesModal: false,

    };
    this.props.history.listen((location, action) => {
      this.checkHomePageIsChecked(location.pathname);
    });
  }

  componentDidMount() {

    this.checkHomePageIsChecked(window.location.pathname);
  }

  checkHomePageIsChecked = (pathname) => {
    let matched = false;
    if (pathname.includes(this.props.auth.userDetail.screenUrl)) {
      matched = true
    }
    this.setState({ homePageCheck: matched });
  }

  onNext = (step) => {
    this.setState({ activeStep: step });
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };

  AddItemModal = (inventoryType) => {
    this.setState({
      itemStatus: !this.state.itemStatus,
      openModal: false,
      inventoryType: inventoryType
    });
  };

  // New Order Modal set Status
  orderModal = (e) => {
    let newOrderAccess = this.props.auth.userDetail.roleAndPermission?.filter((item) => { return item.ModuleName === "new-order" && item.Access === 0 })
    if (newOrderAccess.length > 0) {
      this.props.actions.showNotification({ title: "Error", message: 'Screen is not accessible.', position: 'br', autoDismiss: commonFunctions.notificationTime() }, "error");
    }
    else {
      const { warnUserState } = this.props
      if (warnUserState.isFormChanged) {
        this.setState({ isOpenDiscardChangesModal: true })
      }
      else {
        this.setState({
          orderStatus: !this.state.orderStatus,
          ViewCustomerPanel: false,
        });
        this.props.actions.storeCustomerDetail();
      }
    }
    localStorage.removeItem("availableItemDate")
  };

  onConfirmBtnClick = () => {
    commonFunctions.onApiSucessFormChangedFalse(this);
    this.setState({ isOpenDiscardChangesModal: false, orderStatus: !this.state.orderStatus, ViewCustomerPanel: false, });
    this.props.actions.storeCustomerDetail();
  }

  AddActivityModal = (inventoryType) => {

    this.setState({
      activityStatus: !this.state.activityStatus,
      openModal: false,
      inventoryType: inventoryType
    });
  };

  NewMemberModal = () => {
    this.setState({
      memberStatus: !this.state.memberStatus,
    });
  }

  // BundleAddModal = (inventoryType) => {
  //   this.setState({
  //     bundleStatus: !this.state.bundleStatus,
  //     openModal: false,
  //     inventoryType: inventoryType
  //   });
  // };
  SeasonPassAddModal = (inventoryType) => {
    this.setState({
      seasonPassStatus: !this.state.seasonPassStatus,
      openModal: false,
      inventoryType: inventoryType
    });
  };

  AddCategoryModal = (addEdit, type) => {
    this.setState({
      openModal: false,
      addcateStatus: !this.state.addcateStatus,
      addOrEdit: addEdit,
      categoryOrSub: type,
    });
  };

  onHandleDropdown = (e, { name, value, type, checked, data, id }) => {
    let selecteLocation = JSON.parse(this.props.auth.userDetail.businessUserLocations).filter(location => location.BusinessLocationId === parseInt(e.currentTarget.id));
    if (data === "locationForm") {
      this.props.actions.apiCall({
        urls: ["LOGOUT"], method: "Post", data: {
          "userId": this.props.auth.userDetail.keyId,
          "businessLocationId": selecteLocation[0].BusinessLocationId,
          "actionPerformedBy": this.props.auth.userDetail.emailId
        }, onSuccess: (response) => { }
        // this.onLoginSuccess(response), showNotification: true
      });
    }
    if (window.location.pathname.indexOf("inventory") > 0) {
      this.props.history.push(`${env.PUBLIC_URL}/inventory`);
    }
    else {
      this.props.history.push(`${env.PUBLIC_URL}/dashboard`);
      // this.props.history.push(window.location.pathname);
    }

    this.props.actions.storeLocationSelected({
      BusinessLocationId: e.currentTarget.id, value: value,
      BusinessLocationName: value, Zipcode: selecteLocation[0].Zipcode
    });
  }

  onCategoryClick = (add, Category, SubCategory) => {
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    if (lastItem === "category-view") {
      if (this.props.global.inventoryDetail.categoryId === 0 || this.props.global.inventoryDetail.categoryId === null) {
        this.AddCategoryModal(add, Category)
      }
      if (this.props.global.inventoryDetail.categoryId > 0) {
        this.AddCategoryModal(add, SubCategory)
      }
    }
    else { this.props.history.push(`${env.PUBLIC_URL}/add-category/inventory`) }
  }

  onItemClick = () => {
    const globalCodeRental = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Rental")).globalCodeId
    const globalCodeRetail = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Retail")).globalCodeId
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    if (lastItem === "category-view") {
      if (this.props.global.inventoryDetail.businessCategoryId === globalCodeRental || this.props.global.inventoryDetail.businessCategoryId === globalCodeRetail) {
        this.AddItemModal(this.props.global.inventoryDetail.businessCategoryId);
      }
      else {
        this.props.actions.showNotification({
          title: "Warning",
          message: "Please move to inventory root level first",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        }, "error");
      }
    }
    else {
      this.AddItemModal(this.props.global.inventoryDetail.businessCategoryId);
    }
  }

  onActivityClick = () => {
    const globalCodeId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Activity")).globalCodeId
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (lastItem === "category-view" && this.props.global.inventoryDetail.businessCategoryId !== globalCodeId) {
      this.props.actions.showNotification({
        title: "Warning",
        message: "Please move to inventory root level first",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      }, "error");
    }
    else {
      this.AddActivityModal(this.props.global.inventoryDetail.businessCategoryId);
    }
  }

  // onBundleClick = () => {
  //   const globalCodeId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Bundle")).globalCodeId
  //   const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  //   if (lastItem === "category-view" && this.props.global.inventoryDetail.businessCategoryId !== globalCodeId) {
  //     this.props.actions.showNotification({
  //       title: "Warning",
  //       message: "Please move to inventory root level first",
  //       position: "br",
  //       autoDismiss: commonFunctions.notificationTime(),
  //     }, "error");
  //   }
  //   else {
  //     this.BundleAddModal(this.props.global.inventoryDetail.businessCategoryId);
  //   }
  // }
  onSeasonPassClick = () => {
    const globalCodeId = (commonFunctions.getGlobalCodeDetails(this.props.global.codes, "BusinessCategoryType", "Season Pass")).globalCodeId
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (lastItem === "category-view" && this.props.global.inventoryDetail.businessCategoryId !== globalCodeId) {
      this.props.actions.showNotification({
        title: "Warning",
        message: "Please move to inventory root level first",
        position: "br",
        autoDismiss: commonFunctions.notificationTime(),
      }, "error");
    }
    else {
      this.SeasonPassAddModal(this.props.global.inventoryDetail.businessCategoryId);
    }
  }

  moreSettingRoute = () => {
    this.props.history.push({
      pathname: `${env.PUBLIC_URL}/more-setting`
    });
  }

  onHandleChange = (e, { value }) => {
    this.setState({ SearchValue: value });
    this.setState({ showResult: false })
    if (value === "") {
      this.setState({ SearchValue: "", globalSearchData: [] })
    }
  }

  getGlobalSearch = (click) => {
    this.props.actions.apiCall({
      urls: ["GETGLOBALSEARCH"], method: "GET", data: {
        businessId: this.props.global.bussinessDetail.businessId,
        businessLocationId: this.props.global.locationSelected.BusinessLocationId, SearchValue: this.state.SearchValue
      },
      onSuccess: (response) => {
        if (click) {
          var test = response[0].searchResults;
          this.setState({ test });
        }
        var jsonParsedArray = JSON.parse(response[0].searchResults);
        if (response[0].searchResults !== "{}") {

          this.setState({ showResult: true })
        }
        else {
          this.setState({ showResult: false })
        }
        var result = {};

        for (var key in jsonParsedArray) {
          if (jsonParsedArray.hasOwnProperty(key)) {
            result = { ...result, [key]: JSON.parse(jsonParsedArray[key]) };
          }
        }
        this.setState({ globalSearchData: result });
      }
    })
  };

  stopPropagation = (event) => {
    if (event.key === 'Enter') {
      this.getGlobalSearch()
      event.stopPropagation()
    }
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.getGlobalSearch(true)
    }
    else {
      this.setState({ test: "a" })
    }
  }
  onHandleCloseSearch = () => {
    this.setState({ categoryList: { ...this.state.categoryList, searchValue: "" }, searchData: false, breadcrumbData: [] },
      () => this.callCategoryDetail())
  }

  onSearch = () => {
    this.SearchRef.current.open();
    this.getGlobalSearch(true)
  }

  onHandleCloseSearch = () => {
    this.setState({ SearchValue: "", globalSearchData: [] })
  }

  handleResultSelect = (e, { result }) => {
    
    const businessCategoryId = result.BusinessCategoryType
    const BusinessCategoryTypeName = result.BusinessCategoryTypeName
    const ResultTypeName = result.resultTypeName
    if (result.resultType === "O") {
      this.props.history.push({
        pathname: `${env.PUBLIC_URL}/order-summary/${result.keyId}`
      });
    }
    
    if (result.resultType === "C") {
      this.props.history.push({
        pathname: `${env.PUBLIC_URL}/customer-summary/${result.keyId}`
      });
    
      window.location.reload();
    }
    if (result.resultType === "T") {
      this.props.history.push({
        pathname: `${env.PUBLIC_URL}/team-member-profile/${result.keyId}`
      });
    }
    if (result.resultType === "I") {
      const categoryId = result.keyId
      const inventoryBreadcrumb = [{
        level: 0, breadcrumbName: BusinessCategoryTypeName, id: businessCategoryId, type: "BusinessCategoryType",
        parentId: null, productId:0}];
      this.props.actions.storeInventoryDetail({
        categoryId: null, businessCategoryId: businessCategoryId,
        inventoryBreadcrumb, businessCategoryName: BusinessCategoryTypeName
      });
      const pathName = "/inventory/category-view";
      this.props.history.push(`${env.PUBLIC_URL}${pathName}`, { categoryId, businessCategoryId });
      this.setState({ categoryId: "", businessCategoryId: "", parentId: "" });
      if(window.location.pathname === pathName)
        window.location.reload();
    }
    if (result.resultType === "P") {
      const productId = result.keyId
      const categoryId = result.CategoryId
      const searchData = result.searchData
      const inventoryBreadcrumb = [{
        level: 0, breadcrumbName: BusinessCategoryTypeName, id: businessCategoryId,
        type: "BusinessCategoryType", parentId: null, productId: 0
      }];
      this.props.actions.storeInventoryDetail({
        categoryId: null, businessCategoryId: businessCategoryId, inventoryBreadcrumb,
        businessCategoryName: BusinessCategoryTypeName, productId: productId, searchData: searchData
      });
      const pathName = "/inventory/inventory-item-list";
      this.props.history.push(`${env.PUBLIC_URL}${pathName}`, {
        productId, businessCategoryId, ResultTypeName,
        categoryId, searchData
      });
      this.setState({ productId: "", businessCategoryId: "", ResultTypeName: "", categoryId: "", parentId: "" })
    }
    this.setState({ SearchValue: "", globalSearchData: [] });
    const pathNames = "/inventory/inventory-item-list";
    if(window.location.pathname === pathNames)
      window.location.reload();
  }

  onHomePageCheck = (e, { checked, name }) => {
    this.setState({ homePageCheck: checked })
    const homePageUrls = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    const newHomePageUrl = window.location.href.substring(window.location.href).split("/")
    const newUrl = newHomePageUrl[newHomePageUrl.length - 2];
    let userDetail = this.props.auth.userDetail
    if (parseInt(homePageUrls)) {
      userDetail.screenUrl = newUrl
      this.setState({ newUrl }, () => this.onUpdateUserHomePage())
    }
    else {
      const homePageUrl = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
      userDetail.screenUrl = homePageUrl
      this.setState({ homePageUrl }, () => this.onUpdateUserHomePage())
    }
    this.props.actions.storeUserDetail(userDetail);
  }

  onUpdateUserHomePage = () => {

    this.props.actions.apiCall({
      urls: ["UPDATEUSERHOMEPAGE"], method: "PUT", data: {
        "UserId": this.props.auth.userDetail.keyId,
        "ScreenName": this.state.homePageUrl ? this.state.homePageUrl : this.state.newUrl,
        "ActionPerformedBy": this.props.auth.userDetail.emailId
      },
      onSuccess: (response) => {
      },
      showNotification: true
    });
  }



  render() {
    const locationOption = this.props.global.locationOptions;
    const { memberStatus, itemStatus, activityStatus, orderStatus,
      addcateStatus, categoryOrSub, addOrEdit, SearchValue, seasonPassStatus,
      globalSearchData, isOpenDiscardChangesModal } = this.state
    const { global } = this.props;
    return (
      <React.Fragment>
        <div className="header-top">
          <Container fluid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <div className="d-flex">
                    <div className="tabImage">
                      <Image src={commonFunctions.concatenateImageWithAPIUrl(global.bussinessDetail.businessLogo)}
                        title={global.bussinessDetail.businessName} size="mini" inline className="tabshow" />
                      <Link onClick={() => { this.props.onTabToggle() }} className="tabshow orange-color">
                        <Icon name="bars" size='large' className="menubar" />
                        <Icon name="close" size='large' className="close" />
                      </Link>
                    </div>
                    {locationOption &&
                      <Select options={locationOption}
                        onChange={this.onHandleDropdown} data="locationForm"
                        defaultValue={this.props.global.locationSelected.BusinessLocationName}
                        selection value={this.props.global.locationSelected.BusinessLocationName} type="select" className="locationselect desktopShow" />}
                  </div>
                </Grid.Column>
                <Grid.Column textAlign="right" verticalAlign="middle" width={6}>
                  <List horizontal className="top-icon">
                    <List.Item className={`results transition inputSearch visible ${this.state.showResult ? "show" : ""}`}>
                      <Search
                        className={`results transition visible ${this.state.showResult ? "show" : ""}`}
                        noResultsMessage={this.state.test === "{}" ? "No Record Found" : "Press enter to search"}
                        category
                        input={{ icon: 'search', iconPosition: 'left' }}
                        data="SearchValue"
                        results={globalSearchData}
                        onResultSelect={this.handleResultSelect}
                        value={SearchValue}
                        onSearchChange={this.onHandleChange}
                        onKeyPress={this.handleKeyPress}
                        ref={this.SearchRef}
                      />
                      {this.state.SearchValue.length > 0 ? <Button className="iconClose" icon="close" onClick={this.onHandleCloseSearch}></Button> : false}
                      <Button className="iconSearch" icon="search" onClick={this.onSearch}></Button>
                    </List.Item>

                    <List.Item>
                      <Icon name="cart plus" className="main-icon" onClick={(e) => this.orderModal(e)} link />
                    </List.Item>
                    <List.Item>
                      <Dropdown icon={null} trigger={trigger} className="main-icon">
                        <Dropdown.Menu className="left">
                          <Dropdown.Item text="Item" onClick={() => this.onItemClick()} />
                          <Dropdown.Item text="Activity" onClick={() => this.onActivityClick()} />
                          <Dropdown.Item text="Passes" onClick={() => this.onSeasonPassClick()} />
                          {/* <Dropdown.Item text="Bundle" onClick={() => this.onBundleClick()} /> */}
                          <Dropdown.Item as={Link} text="Team Member" onClick={() => this.NewMemberModal()} />
                          <Dropdown.Item as={Link} text="Category" onClick={() => this.onCategoryClick("Add", "Category", "SubCategory")} />
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                    <List.Item>
                      <Dropdown item simple icon="setting" className="main-icon">
                        <Dropdown.Menu className="left setting-dropdown">
                          <Dropdown.Item>
                            <Checkbox label="Home Page" className="custome-checkbox"
                              name="homePageCheck"
                              checked={this.state.homePageCheck}
                              onChange={(e, { value, checked, name }) => this.onHomePageCheck(e, { value, checked, name })} />
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.moreSettingRoute} icon="setting" text="More Settings" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>

        {isOpenDiscardChangesModal && (
          <CommonDeleteUpdateModal
            isModalOpen={isOpenDiscardChangesModal}
            onClose={() => this.setState({ isOpenDiscardChangesModal: false })}
            header="Are you sure you want to discard changes in form ?"
            confirmationButtonText={"Yes"}
            cancelButtonText={"No"}
            onConfirm={this.onConfirmBtnClick}
          />
        )}

        {activityStatus &&
          <AddActivityModal openModal={activityStatus} closeModal={this.AddActivityModal}
            inventoryType={this.state.inventoryType} />
        }

        {memberStatus &&
          <NewMemberModal openModal={memberStatus} closeModal={this.NewMemberModal} />
        }
        {/* {bundleStatus &&
          <AddBundleModal openModal={bundleStatus} closeModal={this.BundleAddModal}
            inventoryType={this.state.inventoryType} />
        } */}
        {orderStatus &&
          <NewOrderModal openModal={orderStatus} closeModal={this.orderModal} />
        }

        {addcateStatus &&
          <AddCategoryModal openModal={addcateStatus} categoryOrSub={categoryOrSub}
            addOrEdit={addOrEdit} closeModal={this.AddCategoryModal} />}

        {itemStatus &&
          <AddItemModal
            openModal={itemStatus}
            closeModal={this.AddItemModal}
            getProductCategoryDetail={this.getProductCategoryDetail}
            inventoryType={this.state.inventoryType} />
        }
        {seasonPassStatus &&
          <AddSeasonPassModal openModal={seasonPassStatus}
            closeModal={this.SeasonPassAddModal} />}


      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch),
      storeLocationSelected: bindActionCreators(actions.storeLocationSelected, dispatch),
      storeCustomerDetail: bindActionCreators(actions.storeCustomerDetail, dispatch),
      storeInventoryDetail: bindActionCreators(actions.storeInventoryDetail, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch)
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));