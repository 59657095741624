import React, { Component } from "react";
import {
  Grid,
  Form,
  Header,
  Divider,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../functional/global-import";
class CommonMinTimeDuration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onHandleMinDurationChange = (e, { name, value, type, checked, data }) => {
    const { fullState, stateForm, allFunction } = this.props;
    fullState[stateForm][data] = value;
    allFunction.setState({ ...fullState }, () =>
      allFunction.MinDurationChange(value)
    );
    commonFunctions.onFormChange(this);
  };
  onHandleDurationChange = (e, { name, value, type, checked, data }) => {
    const { fullState, stateForm, allFunction } = this.props;
    fullState[stateForm][data] = value;
    allFunction.setState({ ...fullState });
    commonFunctions.onFormChange(this);
  };
  onHandleMaxDurationChange = (e, { value, data }) => {
          
    const { fullState, stateForm, allFunction } = this.props;
    let sameTimePeriodType =
      fullState[stateForm].minTimePeriodType ===
      fullState[stateForm].maxTimePeriodType;
    var ex = /^(?:\d*\d{1,2}|\d+)$/;
    if (ex.test(value) === true) {
      if (sameTimePeriodType) {
        if (data === "maxTimePeriod") {
          if (fullState[stateForm].minTimePeriod > parseInt(value)) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: `Max booking item can not less then min booking item .`,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
            fullState[stateForm][data] = fullState[stateForm].minTimePeriod;
            allFunction.setState({ ...fullState });
          } else {
            fullState[stateForm][data] = parseInt(value);
            allFunction.setState({ ...fullState });
          }
        }
      } else {
        fullState[stateForm][data] = parseInt(value);
        allFunction.setState({ ...fullState });
      }
      if (data === "maxItemPerOrder") {
        fullState[stateForm][data] = parseInt(value);
        allFunction.setState({ ...fullState });
      }
    }
    if (ex.test(value) === false) {
      fullState[stateForm][data] = null;
      allFunction.setState({ ...fullState });
    }
    commonFunctions.onFormChange(this);
  };
  onHandleMinDuration = (e, { name, value, type, checked, data }) => {
    commonFunctions.onFormChange(this);
    const { fullState, stateForm, allFunction } = this.props;
    if (data === "minTimePeriod") {
      var ex = /^(?:\d*\d{1,2}|\d+)$/;
      if (ex.test(value) === true) {
        fullState[stateForm][data] = parseInt(value);
        fullState[stateForm].maxTimePeriod = parseInt(value);
        allFunction.setState({ ...fullState });
      }
    }
    if (data === "minItemPerOrder") {
      var ex = /^(?:\d*\d{1,2}|\d+)$/;
      if (ex.test(value) === true) {
        fullState[stateForm][data] = parseInt(value);
        // fullState[stateForm].maxItemPerOrder = value;
        allFunction.setState({ ...fullState });
      }
    }
    if (ex.test(value) === false) {
      fullState[stateForm][data] = null;
      allFunction.setState({ ...fullState });
    }
    commonFunctions.onFormChange(this);
  };
  render() {
    const {validator,fullState, stateForm } = this.props;

    return (
      <React.Fragment>
        {stateForm === "addItemGeneral" ? (
          <>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
            <Grid.Column width={16}>
              <Header as="h4">
                <b>Minimum & Maximum</b>
              </Header>
            </Grid.Column>
            <Grid.Column width={16}>
              <Header style={{ color: "red" }} as="h4">
                Booking Time
              </Header>
            </Grid.Column>
            <Grid.Column computer={4} tablet={10}>
              <label>
                <b>Min.</b>
              </label>
              <div className="durationwithnumber">
                <Form.Input
                  placeholder="Qty."
                  data="minTimePeriod"
                  value={fullState[stateForm].minTimePeriod}
                  onChange={this.onHandleMinDuration}
                  fluid
                  className="qty"
                  error={validator.message(
                    "max PerOrder",
                    fullState[stateForm].minTimePeriod,
                    `integer|numeric|min:1,num`
                  )}

                />

                <Form.Select
                  placeholder="Min. Time Period"
                  data="minTimePeriodType"
                  value={fullState[stateForm].minTimePeriodType}
                  search
                  selection
                  options={fullState.TimeDurationMin}
                  onChange={this.onHandleMinDurationChange}

                />
              </div>
            </Grid.Column>
            <Grid.Column computer={4} tablet={10}>
              <label>
                <b>Max.</b>
              </label>
              <div className="durationwithnumber">
                <Form.Input
                  placeholder="Qty."
                  data="maxTimePeriod"
                  type="number"
                  value={fullState[stateForm].maxTimePeriod}
                  onChange={this.onHandleMaxDurationChange}
                  fluid
                  className="qty"
                  error={validator.message(
                    "max PerOrder",
                    fullState[stateForm].maxTimePeriod,
                    `integer|numeric|min:1,num`)}
                />
                <Form.Select
                  placeholder="Max. Time Period"
                  data="maxTimePeriodType"
                  value={fullState[stateForm].maxTimePeriodType}
                  search
                  selection
                  options={fullState.rentalItemTimeSelectedValue}
                  onChange={this.onHandleDurationChange}

                />
              </div>
            </Grid.Column>{" "}
          </>
        ) : null}
        <Grid.Column width={16}>
          <Header style={{ color: "red" }} as="h4">
            Per Order
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <label>
            {" "}
            <b>Min.</b>
          </label>
          <div className="durationMaxwithnumber">
            <Form.Input
              placeholder="Min."
              data="minItemPerOrder"
              value={fullState[stateForm].minItemPerOrder}
              onChange={this.onHandleMinDuration}
              className="qty"
              error={validator.message(
                "min PerOrder",
                fullState[stateForm].minItemPerOrder,
                `integer|numeric|min:1,num`
              )}
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <label>
            <b>Max.</b>
          </label>
          <div className="durationMaxwithnumber">
            <Form.Input
              placeholder="Max."
              data="maxItemPerOrder"
              value={fullState[stateForm].maxItemPerOrder}
              onChange={this.onHandleMaxDurationChange}
              type="number"
              className="qty"
              error={validator.message(
                "max PerOrder",
                fullState[stateForm].maxItemPerOrder,
                `integer|numeric|min:1,num`)}
            />
          </div>
        </Grid.Column>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
    warnUserState: state.warnUserState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      storeWarnUser: bindActionCreators(actions.storeWarnUser, dispatch),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonMinTimeDuration);
